import { useHover } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import "./CharacterCard.scss";
interface ICharacterCard {
	img: string;
	name: string;
	description: string;
	id: string;
}

export const CharacterCard: FC<ICharacterCard> = ({ img, name, id }) => {
	const params = useParams();
	const navigate = useNavigate();
	const ref = useRef(null);
	const isHover = useHover(ref);

	const onRedirect = () => {
		navigate(`/storyboard/${params.id}/character/${id}/character-info`);
	};

	return (
		<Flex ref={ref} onClick={onRedirect} vertical className="character-card pointer gap-xxs">
			<Typography.Paragraph ellipsis={{ rows: 1 }} className={TEXT_CLASSNAMES.HeadlineH3}>"{name}"</Typography.Paragraph>
			{/* <Typography.Paragraph ellipsis={{ rows: 3 }} className={TEXT_CLASSNAMES.XsRegular}>{description}</Typography.Paragraph> */}
			<div className="character-card__img">
				<img src={img} alt={`character ${name}`} />
			</div>
			{isHover && <div className="character-card__hover"><Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}>Change character</Typography.Text></div>}
		</Flex>
	);
};

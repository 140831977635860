import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { CardTeam } from "./card-team";

import annaPlaceholder from "@/assets/landing/anna.png";
import antonPlaceholder from "@/assets/landing/anton.png";
import arseniyPlaceholder from "@/assets/landing/arseniy.png";
import dimaPlaceholder from "@/assets/landing/dima.png";
import ivanPlaceholder from "@/assets/landing/ivan.png";
import line from "@/assets/landing/line-team.svg";
import michelPlaceholder from "@/assets/landing/michel.png";

import "./LandingTeam.scss";

export const LandingTeam: FC = () => {
	const { isLaptopS, isTablet } = useMediaQueries();

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex vertical justify="center" className="team-page">
			{/* <Flex vertical className="text-center gap-xs"> */}
			{/*	<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, { */}
			{/*		[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS, */}
			{/*		[TEXT_CLASSNAMES.LPHeadlineH3]: isTablet, */}
			{/*		[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileXL */}
			{/*	})}>Team</Typography.Text> */}
			{/* </Flex> */}
			<Flex style={{ position: "relative", maxWidth: 700, width: "100%" }} vertical className="product-new__title gap-xs text-center">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet
				})}>Our <span className="product-new__title--italic">Team</span>
				</Typography.Text>
				<img style={{ top: "20%", marginLeft: 0 }} className="product-new__action--line" src={line} alt="line"/>
			</Flex>
			<Flex className="team-page__content" vertical gap="large">
				<Flex gap="large" vertical={isLaptopS}>
					<CardTeam
						fullName="Arseniy Seroka"
						positionName="CHIEF EXECUTIVE OFFICER"
						description="Arseniy is an experienced entrepreneur and learned film director. His love for technology and film is what motivates him to digitalize the creative industry."
						image={arseniyPlaceholder}
						placeholderImage={arseniyPlaceholder}
						href="https://www.linkedin.com/in/seroka/"
					/>
					<CardTeam
						fullName="Michel Perez"
						positionName="CHIEF PRODUCT OFFICER"
						description="Michel is an engineer that is obsessed with machine learning and loves building products that bring this powerful technology in the hand of everyday people."
						image={michelPlaceholder}
						placeholderImage={michelPlaceholder}
						href="https://www.linkedin.com/in/perezmichel"
					/>
				</Flex>
				<Flex gap="large" vertical={isLaptopS}>
					<CardTeam
						fullName="Ivan Markov"
						positionName="CHIEF TECHNOLOGY OFFICER"
						description="As a serial entrepreneur, Ivan is no stranger to building cool things with emerging tech. He cares about making a positive impact by making technology more accessible."
						image={ivanPlaceholder}
						placeholderImage={ivanPlaceholder}
						href="https://linkedin.com/in/windstalker"
					/>
					<CardTeam
						fullName="Anton Voryhalov"
						positionName="HEAD OF BUSINESS DEVELOPMENT"
						description="With leadership roles at Disney and Paramount, Anton brings 15+ years of experience in media and telecom. He excels in AI-driven storytelling and crafting strategies that merge creativity with technology to drive innovation and business growth."
						image={antonPlaceholder}
						placeholderImage={antonPlaceholder}
						href="https://www.linkedin.com/in/antonvoryhalov"
					/>
				</Flex>
				<Flex gap="large" vertical={isLaptopS}>
					<CardTeam
						fullName="Anna Zima"
						positionName="HEAD OF DESIGN"
						description="Anna embodies the epitome of creativity. Reminiscent of a character from the silver screen, she seamlessly transitions between personas, deftly altering the ambiance and aesthetic of design styles at will."
						image={annaPlaceholder}
						placeholderImage={annaPlaceholder}
						href="https://www.linkedin.com/in/anna-zima/"
					/>
					<CardTeam
						fullName="Dmitriy Grosh"
						positionName="FRONTEND ENGINEER"
						description="Professionally makes the world's buttons brighter. Professor of memology. Happy to talk about React, web application performance, digital accessibility, architecture, and more. Regular speaker at developer meetups."
						image={dimaPlaceholder}
						placeholderImage={dimaPlaceholder}
						href="https://www.linkedin.com/in/dmitriy-grosh-83642a229/"
					/>
				</Flex>
			</Flex>
		</Flex>
	);
};

import { useSize } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import ReactMarkdown from "react-markdown";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

const text = "\n" +
	"\n" +
	"\n" +
	"\n" +
	"Most sites on the internet use cookies because they are essential for their normal operation. This policy describes how we use cookies in Blooper, what cookies do we use and why do we need them. \n" +
	"\n" +
	"\n" +
	"# **What are cookies?**\n" +
	"\n" +
	"Cookies are files that are downloaded to your device when you visit a website. \n" +
	"\n" +
	"You can learn more about how we use cookies and similar technologies [here](https://en.wikipedia.org/wiki/HTTP_cookie).\n" +
	"\n" +
	"\n" +
	"# **What types of cookies do we use?**\n" +
	"\n" +
	"First of all, we use **_strictly necessary cookies_**. These cookies ensure the performance of our service and without them it won’t work. Strictly necessary cookies do not gather information about you that could be used for marketing or track you on the internet.\n" +
	"\n" +
	"We also do use **_performance cookies_** to collect data for statistical purposes. These cookies don’t contain personal information, and are used to improve your experience of our service.\n" +
	"\n" +
	"\n" +
	"# **How are cookies used for marketing?**\n" +
	"\n" +
	"We may use cookies to promote Blooper to users who may like it. We may receive information about the displayed materials about Blooper. \n" +
	"\n" +
	"\n" +
	"# **Cookies Expiry**\n" +
	"\n" +
	"_Session cookie_s will be deleted when you close your browser. \n" +
	"\n" +
	"_Persistent cookies_ stored until they expire or until your browser deletes them. \n" +
	"\n" +
	"Cookies also may be set up to expiry after a certain time period (e. g., 1 year).\n" +
	"\n" +
	"\n" +
	"# **Cookies**\n" +
	"\n" +
	"We use the following cookies:\n" +
	"\n" +
	"\n" +
	"## **_First Party Cookies_**\n" +
	"\n" +
	"|                      |                 |            |          |\n" +
	"|      **Cookie**      | **Description** | **Expiry** | **Type** |\n" +
	"| \\_tt\\_enable\\_cookie |                 |            |          |\n" +
	"|         \\_ttp        |                 |            |          |\n" +
	"|  poll\\_session\\_seen |                 |            |          |\n" +
	"|    howuku\\_session   |                 |            |          |\n" +
	"|       pageview       |                 |            |          |\n" +
	"|    unique\\_session   |                 |            |          |\n" +
	"|    first\\_session    |                 |            |          |\n" +
	"|      created\\_at     |                 |            |          |\n" +
	"\n" +
	"\n" +
	"## **_Third Party Cookies_**\n" +
	"\n" +
	"Cookies in this section are third party cookies, which means that they are provided not by us, but from some third party providers. \n" +
	"\n" +
	"We use the following third party cookies:\n" +
	"\n" +
	"|            |                                                                                                                                                                                                                                                                                         |            |           |\n" +
	"| **Cookie** |                                                                                                                                     **Description**                                                                                                                                     | **Expiry** |  **Type** |\n" +
	"|  \\_\\_hstc  |                    This is the main cookie set by Hubspot, for tracking visitors. It contains the domain, initial timestamp (first visit), last timestamp (last visit), current timestamp (this visit), and session number (increments for each subsequent session).                    |            | Analytics |\n" +
	"| hubspotutk |                                                             HubSpot sets this cookie to keep track of the visitors to the website. This cookie is passed to HubSpot on form submission and used when deduplicating contacts.                                                            |            | Analytics |\n" +
	"|  \\_\\_hssrc |                                                   HubSpot cookie sets this cookie to determine if the visitor has restarted their browser. If this cookie does not exist when HubSpot manages cookies, it is considered a new session.                                                  |            | Necessary |\n" +
	"|  \\_\\_hssc  | HubSpot sets this cookie to keep track of sessions. This is used to determine if HubSpot should increment the session number and timestamps in the \\_\\_hstc cookie. It contains the domain, viewCount (which  increments with each pageview in a session), and session start timestamp. |            | Necessary |\n" +
	"\n" +
	"\n" +
	"# **How to disable cookies in Blooper in your browser?**\n" +
	"\n" +
	"If you don’t want to use cookies, you may disable them in your browser settings. \n" +
	"\n" +
	"[Google Chrome](https://support.google.com/chrome/answer/95647?hl=en-GB)\n" +
	"\n" +
	"[Mozilla Firefox (Desktop)](https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies)\n" +
	"\n" +
	"[Mozilla Firefox (Android)](https://support.mozilla.org/en-US/kb/enable-or-disable-cookies-firefox-android)\n" +
	"\n" +
	"[Mozilla Firefox (iOS)](https://support.mozilla.org/en-US/kb/enable-or-disable-cookies-firefox-android)\n" +
	"\n" +
	"[Safari (Desktop)](https://support.apple.com/en-us/guide/safari/manage-cookies-and-website-data-sfri11471/mac)\n" +
	"\n" +
	"[Safari (Mobile)](https://support.apple.com/en-us/HT201265)\n" +
	"\n" +
	"[Internet Explorer](https://support.microsoft.com/en-us/kb/260971)\n" +
	"\n" +
	"[Microsoft Edge](https://www.microsoft.com/en-us/edge)\n" +
	"\n" +
	"[Opera](https://www.opera.com/help)\n" +
	"\n" +
	"[Samsung Internet Browser](https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser\\&hl=en)\n" +
	"\n" +
	"If you haven't found your browser in the list above, please consult the documentation that your particular browser manufacturer provides. You may also consult your mobile device documentation for information on how to disable cookies on your mobile device.\n" +
	"\n" +
	"Please note that disabling cookies may worsen your user experience and depersonalize your service settings. Our service won’t work without strictly necessary cookies.\n" +
	"\n" +
	"\n" +
	"# **Terms and Updates**\n" +
	"\n" +
	"Here we use the same definitions as in the Privacy Policy (https\\://blooper.ai/privacy-policy).\n" +
	"\n" +
	"We may update this policy by posting the updated version here: https\\://blooper.ai/cookie-policy. You should check this page from time to time. The effective date is stated at the top.\n";

export const CookiePolicy = () => {
	const size = useSize(() => document.querySelector(".login-wrapper"));

	return (
		<BackgroundPreLogin>
			<LoginWrapper isChildrenFullHeight title="" questionText="" actionText="" action={() => {
			}}>
				<Flex vertical gap={8}>
					<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4)}>Cookie Policy - Blooper</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>Last Updated: 28.10.2024</Typography.Text>
				</Flex>
				<div style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					overflowY: "scroll",
					width: "100%",
					height: "100%",
					maxHeight: (size?.height ?? 0) - 120,
					marginTop: 24
				}}>
					<ReactMarkdown children={text}/>
				</div>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";

import deleteModalImage from "@/assets/moodboard/delete-modal.svg";

import "./MoodboardDeleteModal.scss";

interface IMoodboardDeleteModal extends IOpenModal {
	deleteMoodboard: () => void;
}

export const MoodboardDeleteModal: FC<IMoodboardDeleteModal> = ({ isOpen, toggleModal, deleteMoodboard }) => {
	const handleCancel = () => {
		toggleModal();
	};
	const handleOk = () => {
		deleteMoodboard();
		toggleModal();
	};

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			footer={[
				<Flex className="full-width" gap={8}>
					<Button className="flex-1" key="back" onClick={handleCancel}>
						No
					</Button>
					<Button type="primary" className="flex-1" key="back" onClick={handleOk}>
						Yes, clean it
					</Button>
				</Flex>
			]}
		>
			<Flex className="moodboard-delete-modal" vertical align="center">
				<img className="moodboard-delete-modal__image" src={deleteModalImage} alt="delete modal" />
				<Typography.Text
					className={cn("moodboard-delete-modal__title", TEXT_CLASSNAMES.HeadlineH3)}
				>
					Are you sure you want to delete all images on the moodboard?
				</Typography.Text>
				<Typography.Text
					className={cn("moodboard-delete-modal__description", TEXT_CLASSNAMES.SmRegular)}
				>
					All images will be moved to the archive
				</Typography.Text>
			</Flex>
		</Modal>
	);
};

import { useAction, useAtom } from "@reatom/npm-react";
import { notification } from "antd";
import { FC, PropsWithChildren, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { getAccountAction } from "@/entities/account";
import { clearErrorAction, errorAtom } from "@/entities/notification";
import { PLAN_EMAIL, PlanEmail, viewerAtom, viewerRegisterAtom } from "@/entities/viewer";
import { useLocalStorage } from "@/shared/hooks";

export const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => {
	const [api, contextHolder] = notification.useNotification();
	const [account] = useAtom(getAccountAction.dataAtom);
	const [viewer] = useAtom(viewerAtom);
	const [_, setViewerRegister] = useAtom(viewerRegisterAtom);
	const [__, setPlanEmail] = useLocalStorage<PlanEmail | null>(PLAN_EMAIL, null);
	const isAuth = !!viewer;
	const navigate = useNavigate();
	const clearError = useAction(clearErrorAction);
	const [error] = useAtom(errorAtom);

	useEffect(() => {
		if (error?.type === "axios-error") {
			if (error.error.response?.status === 502 || error.error.response?.status === 500) {
				if (isAuth) {
					navigate("/main/500");
				} else {
					navigate("/500");
				}
			} else if (error.error.response?.status === 403 && error.error.response?.data?.message === "User has no subscription") {
				setViewerRegister(viewer);
				setPlanEmail({ email: account?.data?.email as string });
				navigate("/plan");
			} else {
				api.error({
					message: `Error status ${error.error.response?.status}`,
					description: error.error.response?.data?.message
				});
			}
		}

		if (error?.type === "stock-error") {
			api.error({
				message: "Error status 500",
				description: error.error?.message ?? "Oops, something went wrong. Please try again later"
			});
		}

		return () => {
			clearError();
		};
	}, [error]);

	return (
		<>
			{contextHolder}
			{children}
		</>
	);
};

import { AxiosResponse } from "axios";
import { api } from "@/shared/api";

import { THighlightTheShotsResponse, TSceneDTO, TShotDto } from "./types";

export const getHighlightTheShotsResource = (projectId: string, controller: AbortController): Promise<AxiosResponse<THighlightTheShotsResponse>> =>
	api.get(`/storyboards/${projectId}/hts/`, { signal: controller.signal });

export const createSceneResource = (projectId: string, params: TSceneDTO): Promise<AxiosResponse<unknown>> =>
	api.post(`/storyboards/${projectId}/scenes/`, params);

export const updateSceneResource = (projectId: string, params: TSceneDTO): Promise<AxiosResponse<unknown>> =>
	api({
		method: "PUT",
		url: `/storyboards/${projectId}/scenes/`,
		data: params
	});

export const createShotResource = (projectId: string, params: TShotDto): Promise<AxiosResponse<unknown>> =>
	api.post(`/storyboards/${projectId}/scenes/shots/`, params);

export const updateShotResource = (projectId: string, params: TShotDto): Promise<AxiosResponse<unknown>> =>
	api({
		method: "PATCH",
		url: `/storyboards/${projectId}/scenes/shots/`,
		data: {
			...params,
			shot_info: {
				...params.shot_info,
				shot_settings: [],
				shot_characteristics: []
			}
		}
	});

export const deleteShotResource = (projectId: string, params: TShotDto): Promise<AxiosResponse<unknown>> =>
	api({
		method: "DELETE",
		url: `/storyboards/${projectId}/scenes/shots/`,
		data: params
	});

export const deleteSceneResource = (projectId: string, params: TSceneDTO): Promise<unknown> => api({
	method: "DELETE",
	url: `/storyboards/${projectId}/scenes/`,
	data: params
});

export const uploadScriptResource = (name: string, formData: FormData, fileType: string): Promise<AxiosResponse<THighlightTheShotsResponse>> => api({
	method: "POST",
	url: `/storyboards/${name}/generate_hts`,
	data: formData,
	headers: {
		"Content-Type": "multipart/form-data",
		"File-Type": fileType
	}
});

import { Form, Input, Typography } from "antd";
import { AxiosError } from "axios";
import cn from "classnames";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BackgroundPreLogin, LoginWrapper, RecoverPasswordWrapper } from "@/entities/viewer";
import { initResetPasswordResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { validateEmail } from "@/shared/validation";

export const RecoverPassword: FC = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const email = Form.useWatch("email", { form, preserve: true });
	const [error, setError] = useState<null | string>(null);

	const onFinish = async () => {
		try {
			await initResetPasswordResource(email);
			navigate(`/enter-the-code/${email}`);
		} catch (e) {
			const err = e as AxiosError;
			// @ts-ignore
			setError(err.response?.data?.message ?? "Something went wrong. Please try again");
		}
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper title={""} questionText={""} actionText={""} action={() => {}}>
				<Form form={form} onFinish={onFinish}>
					<RecoverPasswordWrapper
						form={form}
						title="Recover Password"
						description="Enter the email address associated with your account and we’ll send an email with instructions"
						buttonText="Send"
					>
						<Form.Item
							name="email"
							rules={[
								{ required: true, message: "Please input your email!" },
								{ type: "email", message: "The input is not valid E-mail!" }
							]}
							hasFeedback
							validateStatus={validateEmail(email)}
						>
							<Input
								onChange={(event) => form.setFieldValue("email", event.target.value)}
								placeholder="Enter your email"
							/>
						</Form.Item>
						{error && <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.HeartRed400)}>{error}</Typography.Text>}
					</RecoverPasswordWrapper>
				</Form>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

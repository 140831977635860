import { reatomAsync } from "@reatom/async";
import { atom } from "@reatom/core";

import TagManager from "react-gtm-module";
import { sessionLoginAction, sessionPreLoginAction } from "@/entities/viewer";
import { axiosErrorHandler } from "@/shared/api";
import {
	createUserGoogleResource,
	loginResource,
	TAuthCode,
	TCredentials
} from "@/shared/api/auth";
import { IMessageError } from "@/vite-env";
export const errorAtom = atom<null | string>(null, "error atom");

export const loginAction = reatomAsync(
	(_ctx, credentials: TCredentials) => loginResource(credentials),
	{
		name: "loginAction",
		onFulfill (ctx, result) {
			TagManager.dataLayer({
				dataLayer: {
					event: "login",
					loginMethod: "email"
				}
			});
			sessionLoginAction(ctx, result.data);
		},
		onReject (ctx, err: unknown) {
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				if (error.response?.status === 403) {
					errorAtom(ctx, "Incorrect email or password");
				} else {
					errorAtom(ctx, "Oops, something went wrong");
				}
			} else {
				errorAtom(ctx, "Oops, something went wrong");
			}
		}
	}
);

export const loginGoogleAction = reatomAsync(
	(_ctx, credentials: TAuthCode) => createUserGoogleResource(credentials),
	{
		name: "loginAction",
		onFulfill (ctx, result) {
			TagManager.dataLayer({
				dataLayer: {
					event: "login",
					loginMethod: "google"
				}
			});
			sessionPreLoginAction(ctx, result.data);
			sessionLoginAction(ctx, result.data);
		},
		onReject (ctx, err: unknown) {
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				if (error?.status === 409) {
					errorAtom(ctx, "Incorrect email or password");
				} else {
					errorAtom(ctx, "Oops, something went wrong");
				}
			}
		}
	}
);

import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { callErrorAction } from "@/entities/notification";
import { getSettingsProfileResource } from "@/shared/api/settings";

export const getAccountAction = reatomAsync((_ctx, isAvatar: boolean) => {
	return getSettingsProfileResource(isAvatar);
}).pipe(
	withDataAtom(),
	withStatusesAtom(),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { SettingsWrapper } from "@/entities/settings";
import { deleteAccountAction } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";

export const DeleteAccountForm: FC = () => {
	const deleteAccount = useAction(deleteAccountAction);
	const [isOpen, setIsOpenModal] = useOpenModal();

	const onCancel = () => {
		setIsOpenModal(false);
	};

	const onDelete = () => {
		deleteAccount();
	};

	return (
		<SettingsWrapper
			title="Delete account"
			description="Delete your account and all of your source data. This is irreversible"
			className="gap-s full-width"
		>
			<Button className="full-width" onClick={() => setIsOpenModal(true)}>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Delete</Typography.Text>
			</Button>
			<Modal open={isOpen} onCancel={onCancel} footer={
				<Flex style={{ paddingTop: 12 }} className="full-width gap-xs">
					<Button onClick={onCancel} className="full-width">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>No, cancel</Typography.Text>
					</Button>
					<Button onClick={onDelete} type="primary" className="full-width">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Yes, delete it</Typography.Text>
					</Button>
				</Flex>
			}>
				<Flex vertical className="gap-s text-center">
					<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>Deleting your account</Typography.Text>
					<Flex vertical className="gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.MdRegular}>Are you sure you want to delete account?</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>We will not store any of your data</Typography.Text>
					</Flex>
				</Flex>
			</Modal>
		</SettingsWrapper>
	);
};

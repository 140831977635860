import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { useParams } from "react-router-dom";
import { ShotDetails } from "@/features/shot-details";
import { ShotEditSidebar } from "@/features/shot-edit-sidebar";
import { ShotSelectionEditor } from "@/features/shot-selection-editor";
import { ShotVersions } from "@/features/shot-versions";
import {
	getGeneratedShotsOrderAction,
	getShotsInSceneAction,
	RightSidebarShot,
	shotSidebarViewAtom
} from "@/entities/shot-edit";
import { getShotAction } from "@/entities/storyboard";
import { Spinner } from "@/shared/ui";

export const ShotEdit: FC = () => {
	const { id, shotId, sceneId } = useParams();

	const [sidebarView] = useAtom(shotSidebarViewAtom);
	const [status] = useAtom(getShotAction.statusesAtom);
	const [generatedShotsOrderStatus] = useAtom(getGeneratedShotsOrderAction.statusesAtom);

	const initShot = useAction(getShotAction);
	const initShots = useAction(getShotsInSceneAction);
	const initGeneratedShotsOrder = useAction(getGeneratedShotsOrderAction);

	useEffect(() => {
		if (id && shotId) {
			initShot({ projectId: id, shotId });
		}
	}, [id, shotId]);

	useEffect(() => {
		if (id) {
			initGeneratedShotsOrder(id);
		}

		if (sceneId && id) {
			initShots({ sceneId, projectId: id });
		}
	}, []);

	if (status.isPending || generatedShotsOrderStatus.isPending) {
		return (
			<Flex className="full-width full-height">
				<Spinner isOverlay />
			</Flex>
		);
	}

	return (
		<Flex className="full-width full-height">
			<ShotSelectionEditor />
			<RightSidebarShot>
				{sidebarView === "details" && <ShotDetails />}
				{sidebarView === "edit" && <ShotEditSidebar />}
				{sidebarView === "versions" && <ShotVersions />}
			</RightSidebarShot>
		</Flex>
	);
};

import { TRegisterKey } from "@/shared/api/auth";
import { CREDENTIAL__REGISTER_KEY, CREDENTIAL_KEY } from "./const";

export const getCredential = () => {
	if (localStorage.getItem(CREDENTIAL_KEY)) {
		return JSON.parse(localStorage.getItem(CREDENTIAL_KEY) || "") as { data: TRegisterKey | null };
	}

	return null;
};

export const getRegisterCredential = () => {
	if (localStorage.getItem(CREDENTIAL__REGISTER_KEY)) {
		return JSON.parse(localStorage.getItem(CREDENTIAL__REGISTER_KEY) || "") as { data: TRegisterKey | null };
	}

	return null;
};

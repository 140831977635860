import { Flex, FormInstance, Typography } from "antd";
import cn from "classnames";
import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import arrow from "@/assets/shared/arrow_left_24.svg";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { DisableSubmitButton } from "@/shared/ui";

import "./RecoverPasswordWrapper.scss";

interface IRecoverPasswordWrapper {
	title: string;
	description: string;
	form: FormInstance;
	buttonText: string;
}

export const RecoverPasswordWrapper: FC<PropsWithChildren<IRecoverPasswordWrapper>> = ({
	children,
	title,
	description,
	form,
	buttonText
}) => {
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<Flex vertical className="recover-password-wrapper gap-s">
			<Flex onClick={handleBack} className="recover-password-wrapper__back pointer">
				<img src={arrow} alt="back"/>
			</Flex>
			<Flex vertical>
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4)}>
					{title}
				</Typography.Text>
				<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
					{description}
				</Typography.Text>
			</Flex>
			{children}
			<DisableSubmitButton type="primary" form={form}>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					{buttonText}
				</Typography.Text>
			</DisableSubmitButton>
		</Flex>
	);
};

import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import { TShotColor } from "@/shared/api/script";

import "./ShotColor.scss";

interface IShotColor extends HTMLAttributes<HTMLDivElement> {
	color: TShotColor;
}

export const ShotColor: FC<IShotColor> = ({ color, className, ...rest }) => (
	<div {...rest} className={cn("shot-color cubic-animation", `shot-color__${color}`, className)} />
);

import kid1_1 from "@/assets/character/kid/1.1.png";
import kid1_2 from "@/assets/character/kid/1.2.png";
import kid1_3 from "@/assets/character/kid/1.3.png";
import kid1_4 from "@/assets/character/kid/1.4.png";

import kid10_1 from "@/assets/character/kid/10.1.png";
import kid10_2 from "@/assets/character/kid/10.2.png";
import kid10_3 from "@/assets/character/kid/10.3.png";
import kid10_4 from "@/assets/character/kid/10.4.png";

import kid2_1 from "@/assets/character/kid/2.1.png";
import kid2_2 from "@/assets/character/kid/2.2.png";
import kid2_3 from "@/assets/character/kid/2.3.png";
import kid2_4 from "@/assets/character/kid/2.4.png";

import kid3_1 from "@/assets/character/kid/3.1.png";
import kid3_2 from "@/assets/character/kid/3.2.png";
import kid3_3 from "@/assets/character/kid/3.3.png";
import kid3_4 from "@/assets/character/kid/3.4.png";

import kid4_1 from "@/assets/character/kid/4.1.png";
import kid4_2 from "@/assets/character/kid/4.2.png";
import kid4_3 from "@/assets/character/kid/4.3.png";
import kid4_4 from "@/assets/character/kid/4.4.png";

import kid5_1 from "@/assets/character/kid/5.1.png";
import kid5_2 from "@/assets/character/kid/5.2.png";
import kid5_3 from "@/assets/character/kid/5.3.png";
import kid5_4 from "@/assets/character/kid/5.4.png";

import kid6_1 from "@/assets/character/kid/6.1.png";
import kid6_2 from "@/assets/character/kid/6.2.png";
import kid6_3 from "@/assets/character/kid/6.3.png";
import kid6_4 from "@/assets/character/kid/6.4.png";

import kid7_1 from "@/assets/character/kid/7.1.png";
import kid7_2 from "@/assets/character/kid/7.2.png";
import kid7_3 from "@/assets/character/kid/7.3.png";
import kid7_4 from "@/assets/character/kid/7.4.png";

import kid8_1 from "@/assets/character/kid/8.1.png";
import kid8_2 from "@/assets/character/kid/8.2.png";
import kid8_3 from "@/assets/character/kid/8.3.png";
import kid8_4 from "@/assets/character/kid/8.4.png";

import kid9_1 from "@/assets/character/kid/9.1.png";
import kid9_2 from "@/assets/character/kid/9.2.png";
import kid9_3 from "@/assets/character/kid/9.3.png";
import kid9_4 from "@/assets/character/kid/9.4.png";

import men1_1 from "@/assets/character/men/1.1.png";
import men1_2 from "@/assets/character/men/1.2.png";
import men1_3 from "@/assets/character/men/1.3.png";
import men1_4 from "@/assets/character/men/1.4.png";

import men10_1 from "@/assets/character/men/10.1.png";
import men10_2 from "@/assets/character/men/10.2.png";
import men10_3 from "@/assets/character/men/10.3.png";
import men10_4 from "@/assets/character/men/10.4.png";

import men2_1 from "@/assets/character/men/2.1.png";
import men2_2 from "@/assets/character/men/2.2.png";
import men2_3 from "@/assets/character/men/2.3.png";
import men2_4 from "@/assets/character/men/2.4.png";

import men3_1 from "@/assets/character/men/3.1.png";
import men3_2 from "@/assets/character/men/3.2.png";
import men3_3 from "@/assets/character/men/3.3.png";
import men3_4 from "@/assets/character/men/3.4.png";

import men4_1 from "@/assets/character/men/4.1.png";
import men4_2 from "@/assets/character/men/4.2.png";
import men4_3 from "@/assets/character/men/4.3.png";
import men4_4 from "@/assets/character/men/4.4.png";

import men5_1 from "@/assets/character/men/5.1.png";
import men5_2 from "@/assets/character/men/5.2.png";
import men5_3 from "@/assets/character/men/5.3.png";
import men5_4 from "@/assets/character/men/5.4.png";

import men6_1 from "@/assets/character/men/6.1.png";
import men6_2 from "@/assets/character/men/6.2.png";
import men6_3 from "@/assets/character/men/6.3.png";
import men6_4 from "@/assets/character/men/6.4.png";

import men7_1 from "@/assets/character/men/7.1.png";
import men7_2 from "@/assets/character/men/7.2.png";
import men7_3 from "@/assets/character/men/7.3.png";
import men7_4 from "@/assets/character/men/7.4.png";

import men8_1 from "@/assets/character/men/8.1.png";
import men8_2 from "@/assets/character/men/8.2.png";
import men8_3 from "@/assets/character/men/8.3.png";
import men8_4 from "@/assets/character/men/8.4.png";

import men9_1 from "@/assets/character/men/9.1.png";
import men9_2 from "@/assets/character/men/9.2.png";
import men9_3 from "@/assets/character/men/9.3.png";
import men9_4 from "@/assets/character/men/9.4.png";

import transgender1_1 from "@/assets/character/transgender/1.1.png";
import transgender1_2 from "@/assets/character/transgender/1.2.png";
import transgender1_3 from "@/assets/character/transgender/1.3.png";
import transgender1_4 from "@/assets/character/transgender/1.4.png";

import transgender10_1 from "@/assets/character/transgender/10.1.png";
import transgender10_2 from "@/assets/character/transgender/10.2.png";
import transgender10_3 from "@/assets/character/transgender/10.3.png";
import transgender10_4 from "@/assets/character/transgender/10.4.png";

import transgender2_1 from "@/assets/character/transgender/2.1.png";
import transgender2_2 from "@/assets/character/transgender/2.2.png";
import transgender2_3 from "@/assets/character/transgender/2.3.png";
import transgender2_4 from "@/assets/character/transgender/2.4.png";

import transgender3_1 from "@/assets/character/transgender/3.1.png";
import transgender3_2 from "@/assets/character/transgender/3.2.png";
import transgender3_3 from "@/assets/character/transgender/3.3.png";
import transgender3_4 from "@/assets/character/transgender/3.4.png";

import transgender4_1 from "@/assets/character/transgender/4.1.png";
import transgender4_2 from "@/assets/character/transgender/4.2.png";
import transgender4_3 from "@/assets/character/transgender/4.3.png";
import transgender4_4 from "@/assets/character/transgender/4.4.png";

import transgender5_1 from "@/assets/character/transgender/5.1.png";
import transgender5_2 from "@/assets/character/transgender/5.2.png";
import transgender5_3 from "@/assets/character/transgender/5.3.png";
import transgender5_4 from "@/assets/character/transgender/5.4.png";

import transgender6_1 from "@/assets/character/transgender/6.1.png";
import transgender6_2 from "@/assets/character/transgender/6.2.png";
import transgender6_3 from "@/assets/character/transgender/6.3.png";
import transgender6_4 from "@/assets/character/transgender/6.4.png";

import transgender7_1 from "@/assets/character/transgender/7.1.png";
import transgender7_2 from "@/assets/character/transgender/7.2.png";
import transgender7_3 from "@/assets/character/transgender/7.3.png";
import transgender7_4 from "@/assets/character/transgender/7.4.png";

import transgender8_1 from "@/assets/character/transgender/8.1.png";
import transgender8_2 from "@/assets/character/transgender/8.2.png";
import transgender8_3 from "@/assets/character/transgender/8.3.png";
import transgender8_4 from "@/assets/character/transgender/8.4.png";

import transgender9_1 from "@/assets/character/transgender/9.1.png";
import transgender9_2 from "@/assets/character/transgender/9.2.png";
import transgender9_3 from "@/assets/character/transgender/9.3.png";
import transgender9_4 from "@/assets/character/transgender/9.4.png";

import women1_1 from "@/assets/character/women/1.1.png";
import women1_2 from "@/assets/character/women/1.2.png";
import women1_3 from "@/assets/character/women/1.3.png";
import women1_4 from "@/assets/character/women/1.4.png";

import women10_1 from "@/assets/character/women/10.1.png";
import women10_2 from "@/assets/character/women/10.2.png";
import women10_3 from "@/assets/character/women/10.3.png";
import women10_4 from "@/assets/character/women/10.4.png";

import women2_1 from "@/assets/character/women/2.1.png";
import women2_2 from "@/assets/character/women/2.2.png";
import women2_3 from "@/assets/character/women/2.3.png";
import women2_4 from "@/assets/character/women/2.4.png";

import women3_1 from "@/assets/character/women/3.1.png";
import women3_2 from "@/assets/character/women/3.2.png";
import women3_3 from "@/assets/character/women/3.3.png";
import women3_4 from "@/assets/character/women/3.4.png";

import women4_1 from "@/assets/character/women/4.1.png";
import women4_2 from "@/assets/character/women/4.2.png";
import women4_3 from "@/assets/character/women/4.3.png";
import women4_4 from "@/assets/character/women/4.4.png";

import women5_1 from "@/assets/character/women/5.1.png";
import women5_2 from "@/assets/character/women/5.2.png";
import women5_3 from "@/assets/character/women/5.3.png";
import women5_4 from "@/assets/character/women/5.4.png";

import women6_1 from "@/assets/character/women/6.1.png";
import women6_2 from "@/assets/character/women/6.2.png";
import women6_3 from "@/assets/character/women/6.3.png";
import women6_4 from "@/assets/character/women/6.4.png";

import women7_1 from "@/assets/character/women/7.1.png";
import women7_2 from "@/assets/character/women/7.2.png";
import women7_3 from "@/assets/character/women/7.3.png";
import women7_4 from "@/assets/character/women/7.4.png";

import women8_1 from "@/assets/character/women/8.1.png";
import women8_2 from "@/assets/character/women/8.2.png";
import women8_3 from "@/assets/character/women/8.3.png";
import women8_4 from "@/assets/character/women/8.4.png";

import women9_1 from "@/assets/character/women/9.1.png";
import women9_2 from "@/assets/character/women/9.2.png";
import women9_3 from "@/assets/character/women/9.3.png";
import women9_4 from "@/assets/character/women/9.4.png";

export type Character = "man" | "woman" | "transgender" | "kid";
export type CharacterNumber = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10";
export type CharacterImageNumber = "1" | "2" | "3" | "4";

// type CharacterKeys = "images" | "description" | "name"

type Characters = Record<Character, Record<CharacterNumber, Record<CharacterImageNumber | "description", string>>>

// {
// 	"man_1": "A 35-year-old Asian-American man with a lean build, short black hair, sharp features, and deep-set brown eyes.",
// 	"man_2": "A 28-year-old Hispanic man with a muscular physique, short curly black hair, warm brown eyes, and a friendly smile.",
// 	"man_3": "A 40-year-old Indian man with a medium build, dark wavy hair, a neatly trimmed beard, and expressive dark brown eyes.",
// 	"man_4": "A 50-year-old Irish man with a stocky build, graying hair, a rugged face with a few scars, and piercing blue eyes.",
// 	"man_5": "A 22-year-old Ghanaian man with an athletic build, short-cropped hair, smooth dark skin, and bright, enthusiastic eyes.",
// 	"man_6": "A 60-year-old African-American man with a tall, slender build, graying hair, a salt-and-pepper beard, and wise, kind eyes.",
// 	"man_7": "A 30-year-old Russian man with a muscular build, short blonde hair, a clean-shaven face, and intense blue eyes.",
// 	"man_8": "An 18-year-old Japanese man with a slim build, shaggy black hair, sharp facial features, and dark eyes.",
// 	"man_9": "A 45-year-old African-American man with a medium build, close-cropped hair, a goatee, and calm, thoughtful eyes.",
// 	"man_10": "A 55-year-old Hispanic man with a slightly overweight build, thinning gray hair, a mustache, and warm, approachable eyes.",
// 	"woman_1": "A 25-year-old Hispanic woman with a slim build, long wavy brown hair, bright hazel eyes, and a radiant smile.",
// 	"woman_2": "A 40-year-old Chinese woman with a petite frame, straight shoulder-length black hair, almond-shaped brown eyes, and a serene expression.",
// 	"woman_3": "A 30-year-old Egyptian woman with a curvy build, long dark curly hair, expressive dark brown eyes, and a confident demeanor.",
// 	"woman_4": "A 35-year-old Caucasian woman with an athletic build, short blonde hair, blue eyes, and a friendly face.",
// 	"woman_5": "A 22-year-old Indian woman with a slender build, long straight black hair, big brown eyes, and a shy smile.",
// 	"woman_6": "A 50-year-old African-American woman with a medium build, graying hair styled in locs, warm brown eyes, and a nurturing presence.",
// 	"woman_7": "A 45-year-old Russian woman with a robust build, short auburn hair, green eyes, and a stern yet caring expression.",
// 	"woman_8": "A 60-year-old Japanese woman with a small, delicate frame, silver hair pulled back into a bun, kind dark eyes, and a gentle smile.",
// 	"woman_9": "An 18-year-old Middle Eastern woman with a slim build, long dark brown hair, expressive brown eyes, and a lively personality.",
// 	"woman_10": "A 55-year-old Brazilian woman with a curvy build, dark curly hair, vibrant green eyes, and an energetic presence.,
// 	"kid_1": "A 6-year-old Caucasian boy with a small, energetic build, short messy blonde hair, bright blue eyes, and a face full of freckles.",
// 	"kid_2": "An 8-year-old Middle Eastern girl with a petite frame, long dark brown hair usually tied in a braid, large brown eyes, and a shy smile.,
// 	"kid_3": "A 10-year-old African-American boy with a lean build, short curly black hair, dark brown eyes, and an infectious grin.",
// 	"kid_4": "A 7-year-old Asian-American girl with a small, delicate frame, shoulder-length black hair with bangs, almond-shaped brown eyes, and a curious expression.",
// 	"kid_5": "A 9-year-old Hispanic boy with a sturdy build, short dark hair, expressive brown eyes, and a mischievous smile. Resembles Rico Rodriguez.",
// 	"kid_6": "An 11-year-old Indian girl with a slender build, long straight black hair, big brown eyes, and a kind smile.",
// 	"kid_7": "A 5-year-old Caucasian boy with a small, round build, curly red hair, green eyes, and a face covered with freckles.",
// 	"kid_8": "A 12-year-old African-American girl with a tall, athletic build, long braids, bright brown eyes, and a confident demeanor.",
// 	"kid_9": "A 7-year-old Chinese boy with a slim build, short straight black hair, dark eyes, and a thoughtful expression.",
// 	"kid_10": "A 9-year-old African-American girl with a medium build, curly black hair styled in two puffs, sparkling brown eyes, and a wide smile.",
// }

export const CHARACTERS: Characters = {
	man: {
		1: {
			1: men1_1,
			2: men1_2,
			3: men1_3,
			4: men1_4,
			description: "A 35-year-old Asian-American man with a lean build, short black hair, sharp features, and deep-set brown eyes."
		},
		2: {
			1: men2_1,
			2: men2_2,
			3: men2_3,
			4: men2_4,
			description: "A 28-year-old Hispanic man with a muscular physique, short curly black hair, warm brown eyes, and a friendly smile."
		},
		3: {
			1: men3_1,
			2: men3_2,
			3: men3_3,
			4: men3_4,
			description: "A 40-year-old Indian man with a medium build, dark wavy hair, a neatly trimmed beard, and expressive dark brown eyes."
		},
		4: {
			1: men4_1,
			2: men4_2,
			3: men4_3,
			4: men4_4,
			description: "A 50-year-old Irish man with a stocky build, graying hair, a rugged face with a few scars, and piercing blue eyes."
		},
		5: {
			1: men5_1,
			2: men5_2,
			3: men5_3,
			4: men5_4,
			description: "A 22-year-old Ghanaian man with an athletic build, short-cropped hair, smooth dark skin, and bright, enthusiastic eyes."
		},
		6: {
			1: men6_1,
			2: men6_2,
			3: men6_3,
			4: men6_4,
			description: "A 60-year-old African-American man with a tall, slender build, graying hair, a salt-and-pepper beard, and wise, kind eyes."
		},
		7: {
			1: men7_1,
			2: men7_2,
			3: men7_3,
			4: men7_4,
			description: "A 30-year-old Russian man with a muscular build, short blonde hair, a clean-shaven face, and intense blue eyes."
		},
		8: {
			1: men8_1,
			2: men8_2,
			3: men8_3,
			4: men8_4,
			description: "An 18-year-old Japanese man with a slim build, shaggy black hair, sharp facial features, and dark eyes."
		},
		9: {
			1: men9_1,
			2: men9_2,
			3: men9_3,
			4: men9_4,
			description: "A 45-year-old African-American man with a medium build, close-cropped hair, a goatee, and calm, thoughtful eyes."
		},
		10: {
			1: men10_1,
			2: men10_2,
			3: men10_3,
			4: men10_4,
			description: "A 55-year-old Hispanic man with a slightly overweight build, thinning gray hair, a mustache, and warm, approachable eyes."
		}
	},
	kid: {
		1: {
			1: kid1_1,
			2: kid1_2,
			3: kid1_3,
			4: kid1_4,
			description: "A 6-year-old Caucasian boy with a small, energetic build, short messy blonde hair, bright blue eyes, and a face full of freckles."
		},
		2: {
			1: kid2_1,
			2: kid2_2,
			3: kid2_3,
			4: kid2_4,
			description: "An 8-year-old Middle Eastern girl with a petite frame, long dark brown hair usually tied in a braid, large brown eyes, and a shy smile."
		},
		3: {
			1: kid3_1,
			2: kid3_2,
			3: kid3_3,
			4: kid3_4,
			description: "A 10-year-old African-American boy with a lean build, short curly black hair, dark brown eyes, and an infectious grin."
		},
		4: {
			1: kid4_1,
			2: kid4_2,
			3: kid4_3,
			4: kid4_4,
			description: "A 7-year-old Asian-American girl with a small, delicate frame, shoulder-length black hair with bangs, almond-shaped brown eyes, and a curious expression."
		},
		5: {
			1: kid5_1,
			2: kid5_2,
			3: kid5_3,
			4: kid5_4,
			description: "A 9-year-old Hispanic boy with a sturdy build, short dark hair, expressive brown eyes, and a mischievous smile. Resembles Rico Rodriguez."
		},
		6: {
			1: kid6_1,
			2: kid6_2,
			3: kid6_3,
			4: kid6_4,
			description: "An 11-year-old Indian girl with a slender build, long straight black hair, big brown eyes, and a kind smile."
		},
		7: {
			1: kid7_1,
			2: kid7_2,
			3: kid7_3,
			4: kid7_4,
			description: "A 5-year-old Caucasian boy with a small, round build, curly red hair, green eyes, and a face covered with freckles."
		},
		8: {
			1: kid8_1,
			2: kid8_2,
			3: kid8_3,
			4: kid8_4,
			description: "A 12-year-old African-American girl with a tall, athletic build, long braids, bright brown eyes, and a confident demeanor."
		},
		9: {
			1: kid9_1,
			2: kid9_2,
			3: kid9_3,
			4: kid9_4,
			description: "A 7-year-old Chinese boy with a slim build, short straight black hair, dark eyes, and a thoughtful expression."
		},
		10: {
			1: kid10_1,
			2: kid10_2,
			3: kid10_3,
			4: kid10_4,
			description: "A 9-year-old African-American girl with a medium build, curly black hair styled in two puffs, sparkling brown eyes, and a wide smile."
		}
	},
	woman: {
		1: {
			1: women1_1,
			2: women1_2,
			3: women1_3,
			4: women1_4,
			description: "A 25-year-old Hispanic woman with a slim build, long wavy brown hair, bright hazel eyes, and a radiant smile."
		},
		2: {
			1: women2_1,
			2: women2_2,
			3: women2_3,
			4: women2_4,
			description: "A 40-year-old Chinese woman with a petite frame, straight shoulder-length black hair, almond-shaped brown eyes, and a serene expression."
		},
		3: {
			1: women3_1,
			2: women3_2,
			3: women3_3,
			4: women3_4,
			description: "A 30-year-old Egyptian woman with a curvy build, long dark curly hair, expressive dark brown eyes, and a confident demeanor."
		},
		4: {
			1: women4_1,
			2: women4_2,
			3: women4_3,
			4: women4_4,
			description: "A 35-year-old Caucasian woman with an athletic build, short blonde hair, blue eyes, and a friendly face."
		},
		5: {
			1: women5_1,
			2: women5_2,
			3: women5_3,
			4: women5_4,
			description: "A 22-year-old Indian woman with a slender build, long straight black hair, big brown eyes, and a shy smile."
		},
		6: {
			1: women6_1,
			2: women6_2,
			3: women6_3,
			4: women6_4,
			description: "A 50-year-old African-American woman with a medium build, graying hair styled in locs, warm brown eyes, and a nurturing presence."
		},
		7: {
			1: women7_1,
			2: women7_2,
			3: women7_3,
			4: women7_4,
			description: "A 45-year-old Russian woman with a robust build, short auburn hair, green eyes, and a stern yet caring expression."
		},
		8: {
			1: women8_1,
			2: women8_2,
			3: women8_3,
			4: women8_4,
			description: "A 60-year-old Japanese woman with a small, delicate frame, silver hair pulled back into a bun, kind dark eyes, and a gentle smile."
		},
		9: {
			1: women9_1,
			2: women9_2,
			3: women9_3,
			4: women9_4,
			description: "An 18-year-old Middle Eastern woman with a slim build, long dark brown hair, expressive brown eyes, and a lively personality."
		},
		10: {
			1: women10_1,
			2: women10_2,
			3: women10_3,
			4: women10_4,
			description: "A 55-year-old Brazilian woman with a curvy build, dark curly hair, vibrant green eyes, and an energetic presence."
		}
	},
	transgender: {
		1: {
			1: transgender1_1,
			2: transgender1_2,
			3: transgender1_3,
			4: transgender1_4,
			description: "A 30-year-old Japanese transgender man with a lean build. He has short, spiky black hair, sharp facial features, and thoughtful brown eyes. "
		},
		2: {
			1: transgender2_1,
			2: transgender2_2,
			3: transgender2_3,
			4: transgender2_4,
			description: "A 25-year-old Hispanic transgender woman with a curvy build. She has long, straight black hair, striking green eyes, and a warm smile. "
		},
		3: {
			1: transgender3_1,
			2: transgender3_2,
			3: transgender3_3,
			4: transgender3_4,
			description: "A 45-year-old Indian transgender man with a medium build. He has short, graying hair, a well-groomed beard, and kind brown eyes."
		},
		4: {
			1: transgender4_1,
			2: transgender4_2,
			3: transgender4_3,
			4: transgender4_4,
			description: "A 35-year-old Middle Eastern transgender woman with a slender build. She has long, wavy dark brown hair, expressive dark eyes, and a serene demeanor. "
		},
		5: {
			1: transgender5_1,
			2: transgender5_2,
			3: transgender5_3,
			4: transgender5_4,
			description: "A 22-year-old African-American non-binary person with a slim build. They have short, curly black hair, bright brown eyes, and a friendly smile. "
		},
		6: {
			1: transgender6_1,
			2: transgender6_2,
			3: transgender6_3,
			4: transgender6_4,
			description: "A 28-year-old Asian-American transgender woman with a petite frame. She has shoulder-length, sleek black hair, almond-shaped brown eyes, and a vibrant smile. "
		},
		7: {
			1: transgender7_1,
			2: transgender7_2,
			3: transgender7_3,
			4: transgender7_4,
			description: "A 50-year-old Italian transgender man with a stocky build. He has short, salt-and-pepper hair, a rugged face, and piercing blue eyes."
		},
		8: {
			1: transgender8_1,
			2: transgender8_2,
			3: transgender8_3,
			4: transgender8_4,
			description: "A 40-year-old African-American transgender woman with a curvy build. She has long, braided hair, deep brown eyes, and a radiant presence. "
		},
		9: {
			1: transgender9_1,
			2: transgender9_2,
			3: transgender9_3,
			4: transgender9_4,
			description: "A 18-year-old Chinese transgender man with a slim build. He has short, tousled black hair, a clean-shaven face, and bright eyes full of enthusiasm. "
		},
		10: {
			1: transgender10_1,
			2: transgender10_2,
			3: transgender10_3,
			4: transgender10_4,
			description: "A 60-year-old Hispanic transgender woman with a medium build. She has graying hair styled in loose waves, warm hazel eyes, and a gentle smile. "
		}
	}
};

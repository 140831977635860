import cn from "classnames";
import { FC } from "react";

import "./DraggableOverlay.scss";

export const DraggableOverlay: FC = () => {
	return (
		<div
			className="draggable-image full-width full-height"
		>
			<div className={cn("draggable-image__corner", "draggable-image__corner__left-top", "draggable-image__corner__view")} />
			<div className={cn("draggable-image__corner", "draggable-image__corner__right-top", "draggable-image__corner__view")} />
			<div className={cn("draggable-image__corner", "draggable-image__corner__left-bottom", "draggable-image__corner__view")} />
			<div className={cn("draggable-image__corner", "draggable-image__corner__right-bottom", "draggable-image__corner__view")} />
		</div>
	);
};

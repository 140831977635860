import { reatomAsync, reatomResource, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { action } from "@reatom/core";
import { AxiosError } from "axios";

import TagManager from "react-gtm-module";
import { callErrorAction } from "@/entities/notification";
import { THUMBNAIL_IMAGE_ID } from "@/entities/projects/lib/const";
import { addScriptToProjectAction } from "@/entities/script";
import { viewerPermission } from "@/entities/viewer";
import {
	addThumbnailResource,
	createProjectResource,
	deleteProjectResource,
	getAllProjectsResource, getProjectResource,
	getThumbnailResource,
	updateProjectNameResource
} from "@/shared/api/project";
import { getProjectsStoryboardImagesResource } from "@/shared/api/storyboard";
import { TNewProject } from "../lib";

export const getProjectByNameAction = reatomAsync((_ctx, name: string) => getProjectResource(name))
	.pipe(
		withDataAtom(null, (_ctx, res) => res.data),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err as AxiosError))
	);

export const createProjectAction = action(async (ctx, project: TNewProject) => {
	// eslint-disable-next-line no-useless-catch
	try {
		const projectList = ctx.get(initProjectsAction.dataAtom);

		await createProjectResource({
			name: project.name ?? "",
			brief: project.briefing ?? "",
			location_details: {
				country: project.country ?? "",
				city: project.city ?? ""
			}
		});
		await addScriptToProjectAction(ctx, project.script, project.name);
		initProjectsAction.dataAtom(ctx, [...projectList, {
			name: project.name ?? "",
			date: "",
			img: ""
		}]);
		TagManager.dataLayer({
			dataLayer: {
				event: "create_project",
				projectName: project.name ?? ""
			}
		});
	} catch (e) {
		throw e;
	}
});

export const initProjectsAction = reatomResource(async (ctx) => {
	const permission = ctx.spy(viewerPermission);

	return await ctx.schedule(async () => {
		const response = await getAllProjectsResource();
		let names: string[] = [];

		if (permission === "admin") {
			names = response.data.names;
		}

		if (permission === "collaborator") {
			names = [...response.data.names, ...response.data.collab_names];
		}

		const projects = await Promise.allSettled(names.map(async (name) => {
			try {
				return {
					name,
					thumbnail: await getThumbnailResource(name).then((res) => res.data.thumbnail_url)
				};
			} catch (e) {
				return {
					name,
					thumbnail: ""
				};
			}
		}));

		return projects.map((promise) => {
			if (promise.status === "fulfilled") {
				return {
					name: promise.value.name,
					date: "",
					img: promise.value.thumbnail
				};
			}

			return {
				name: "",
				date: "",
				img: ""
			};
		});
	});
}).pipe(
	withDataAtom([]),
	withErrorAtom((ctx, error) => callErrorAction(ctx, error as AxiosError)),
	withStatusesAtom()
);

export const renameProjectByIdAction = action(async (ctx, oldName: string, newName: string) => {
	try {
		const projectList = ctx.get(initProjectsAction.dataAtom);

		await updateProjectNameResource(oldName, newName);

		initProjectsAction.dataAtom(ctx, projectList.map((project) => ({
			...project,
			name: oldName === project.name ? newName : project.name
		})));
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const deleteProjectByIdAction = action(async (ctx, name: string) => {
	try {
		const projectList = ctx.get(initProjectsAction.dataAtom);

		await deleteProjectResource(name);

		initProjectsAction.dataAtom(ctx, projectList.filter((project) => project.name !== name));
	} catch (e) {
		callErrorAction(ctx, e as AxiosError);
	}
});

export const uploadThumbnailAction = reatomAsync((ctx, name: string, formData: FormData) => {
	localStorage.setItem(THUMBNAIL_IMAGE_ID, name);
	return addThumbnailResource(name, formData, ctx.controller);
}, {
	name: "uploadThumbnailAction",
	onFulfill: (ctx) => {
		initProjectsAction(ctx);
	},
	onReject: (ctx, err) => {
		callErrorAction(ctx, err);
	}
}).pipe(withStatusesAtom());

export const getProjectStoryboardImagesActions = reatomAsync((_ctx, name: string) => getProjectsStoryboardImagesResource(name))
	.pipe(
		withDataAtom([], (_ctx, res) => {
			const images = Object.entries(res.data).map(([id, img]) => ({
				img,
				id
			}));

			return images;
		}),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err as AxiosError)),
		withStatusesAtom()
	);

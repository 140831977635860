import { AxiosResponse } from "axios";
import { api } from "@/shared/api";

import {
	GeneratedShotsOrderResult,
	GenerateImageResult,
	GenerateShotParams,
	GenerateShotResult,
	GetShotParams,
	GetShotResult,
	RegenerateImageParams,
	ShotImage,
	TStoryboardResponse,
	UpdateSceneParams,
	UpdateShotParams
} from "./types";
import { TResponse } from "@/vite-env";

export const getStoryboardResource = (projectId: string, controller: AbortController): Promise<AxiosResponse<TStoryboardResponse>> =>
	api.get(`/storyboards/${projectId}/full/`, { signal: controller.signal });

export const getStoryboardShotResource = (params: GetShotParams, controller: AbortController): TResponse<GetShotResult> =>
	api.get(`/storyboards/${params.projectId}/shots/${params.shotId}`, { signal: controller.signal });

export const generateStoryboardShotResource = (params: GenerateShotParams): TResponse<GenerateShotResult> =>
	api.get(`/storyboards/${params.projectId}/shots/${params.shotId}`);

export const regenerateImageResource = (params: RegenerateImageParams, controller: AbortController): TResponse<GenerateImageResult> =>
	api.patch(`/storyboards/${params.projectId}/shots/${params.shotId}`, {}, { signal: controller.signal });

export const getProjectsStoryboardImagesResource = (name: string): Promise<AxiosResponse<{ [id: string]: ShotImage }>> =>
	api.get(`/storyboards/${name}/scenes/shots/images`);

export const updateStoryboardShotResource = (projectId: string, params: UpdateShotParams) =>
	api.patch(`/storyboards/${projectId}/scenes/shots/`, params);

export const updateStoryboardSceneResource = (projectId: string, params: UpdateSceneParams) =>
	api.put(`/storyboards/${projectId}/scenes`, params);

export const getGeneratedOrderShotsResource = (projectId: string): TResponse<GeneratedShotsOrderResult> => {
	return api.get(`/storyboards/${projectId}/ordered-shots/`);
};

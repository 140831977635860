import { reatomAsync } from "@reatom/async";
import { atom } from "@reatom/core";

import TagManager from "react-gtm-module";
import { sessionLoginAction, sessionPreLoginAction } from "@/entities/viewer";
import { axiosErrorHandler } from "@/shared/api";
import { createUserGoogleResource, createUserResource, TAuthCode, TRegisterCredentials } from "@/shared/api/auth";
import { IMessageError } from "@/vite-env";

export const errorAtom = atom<null | string>(null, "error atom");

export const isSuccessEmailSendAtom = atom<boolean>(false, "isSuccessEmailSendAtom");

export const createUserAction = reatomAsync(
	(_ctx, cred: TRegisterCredentials) => createUserResource(cred),
	{
		name: "create user action",
		onFulfill (ctx) {
			isSuccessEmailSendAtom(ctx, true);
			TagManager.dataLayer({
				dataLayer: {
					event: "sign_up",
					signupMethod: "email"
				}
			});
		},
		onReject (ctx, err) {
			isSuccessEmailSendAtom(ctx, false);
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				if (error.response?.status === 403) {
					errorAtom(ctx, "Incorrect email or password");
				} else if (error.response?.status === 409) {
					errorAtom(ctx, "Incorrect email or password");
				} else {
					errorAtom(ctx, "Oops, something went wrong");
				}
			} else {
				errorAtom(ctx, "Oops, something went wrong");
			}
		}
	});

export const createUserGoogleAction = reatomAsync(
	(_ctx, cred: TAuthCode) => createUserGoogleResource(cred),
	{
		name: "create user action",
		onFulfill (ctx, result) {
			TagManager.dataLayer({
				dataLayer: {
					event: "sign_up",
					signupMethod: "google"
				}
			});
			sessionPreLoginAction(ctx, result.data);
			sessionLoginAction(ctx, result.data);
		},
		onReject (ctx, err) {
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				if (error?.status === 409) {
					errorAtom(ctx, "Incorrect email or password");
				} else {
					errorAtom(ctx, "Oops, something went wrong");
				}
			}
		}
	});

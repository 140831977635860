import { ConfigProvider } from "antd";
import { FC, PropsWithChildren } from "react";
import { COLOR_NAMES } from "@/shared/const/color-names";

export const AntDProvider: FC<PropsWithChildren> = ({ children }) => {
	return (
		<ConfigProvider theme={{
			components: {
				Typography: {
					fontFamily: "Avenir",
					fontSize: 20,
					colorText: COLOR_NAMES.TextPrimary,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					lineHeight: "26px"
				},
				Button: {
					fontFamily: "Avenir",
					colorText: COLOR_NAMES.TextPrimary,

					colorPrimary: COLOR_NAMES.Green500,
					primaryColor: COLOR_NAMES.TextPrimary,
					controlHeight: 40,
					defaultBg: "transparent",
					defaultBorderColor: COLOR_NAMES.MetalGray300,

					algorithm: true
				},
				Input: {
					paddingBlock: 10,
					activeBorderColor: COLOR_NAMES.Green500,
					hoverBorderColor: COLOR_NAMES.Green400,
					borderRadius: 8,
					colorBorder: COLOR_NAMES.TextTertiary,
					activeBg: "transparent",
					colorBgContainer: "transparent"
				},
				Tabs: {
					itemSelectedColor: COLOR_NAMES.TextPrimary,
					itemColor: COLOR_NAMES.TextSecondary,
					itemHoverColor: COLOR_NAMES.Green500,
					inkBarColor: COLOR_NAMES.Green500
				},
				Form: {
					itemMarginBottom: 0
				},
				Upload: {
					colorPrimaryHover: COLOR_NAMES.Green600
				},
				Steps: {
					// finishIconBorderColor: COLOR_NAMES.TextBrand,
					colorPrimary: COLOR_NAMES.Green500
				},
				Progress: {
					defaultColor: COLOR_NAMES.TextBrand,
					colorSuccess: COLOR_NAMES.TextBrand,
					marginXS: 0
				},
				Select: {
					colorPrimary: COLOR_NAMES.Green500,
					optionSelectedBg: COLOR_NAMES.Green100,
					colorPrimaryHover: COLOR_NAMES.Green400,
					colorText: COLOR_NAMES.TextPrimary,
					colorTextPlaceholder: COLOR_NAMES.TextTertiary,
					fontFamily: "Avenir",
					colorBgContainer: "transparent"
				},
				Radio: {
					colorPrimary: COLOR_NAMES.Green500,
					algorithm: true,
					controlHeight: 36
				},
				Modal: {
					contentBg: COLOR_NAMES.MetalGray50
				},
				Popover: {
					colorBgElevated: COLOR_NAMES.MetalGray50
				},
				Tag: {
					defaultBg: COLOR_NAMES.MetalGray100,
					borderRadiusSM: 16,
					marginXS: 0,
					colorText: COLOR_NAMES.MetalGray700,
					fontFamily: "Avenir"
				},
				Table: {
					borderColor: COLOR_NAMES.MetalGray300,
					headerBg: COLOR_NAMES.BackgroundWhite,
					headerColor: COLOR_NAMES.TextSecondary,
					fontSize: 13,
					fontFamily: "Avenir",
					fontWeightStrong: 500
				},
				Menu: {
					colorSplit: COLOR_NAMES.MetalGray400,
					horizontalItemSelectedColor: COLOR_NAMES.Green600
				},
				Divider: {
					colorSplit: COLOR_NAMES.MetalGray300
				},
				Switch: {
					colorPrimary: COLOR_NAMES.Green500,
					colorPrimaryHover: COLOR_NAMES.Green400
				},
				Segmented: {
					itemSelectedBg: COLOR_NAMES.MetalGray300,
					trackPadding: 4,
					trackBg: COLOR_NAMES.MetalGray50
				}
			}
		}}>
			{children}
		</ConfigProvider>
	);
};

import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";

import { callErrorAction } from "@/entities/notification";
import { getHighlightTheShotsResource } from "@/shared/api/script";
import { getGeneratedOrderShotsResource, GetShotsParams } from "@/shared/api/storyboard";
import { ShotOrder, TShotSidebarView } from "../lib";

export const shotSidebarViewAtom = atom<TShotSidebarView>("details");

export const shotsOrderAtom = atom<ShotOrder[]>([], "shotsOrderAtom");

export const getShotsInSceneAction = action(async (ctx, params: GetShotsParams) => {
	try {
		const controller = new AbortController();
		const script = await getHighlightTheShotsResource(params.projectId, controller);

		const scenesInfo = script.data.scenes_info[params.sceneId];
		const scenesOrder = script.data.scenes_order;
		const shotsOrder = scenesInfo.shots_order;

		shotsOrderAtom(ctx, shotsOrder.map((shotId) => ({
			sceneIdx: scenesOrder.findIndex((id) => id === params.sceneId),
			shotIdx: scenesInfo.shots_order.findIndex((id) => id === shotId),
			id: shotId
		})));
	} catch (err) {
		callErrorAction(ctx, err);
	}
});

export const getGeneratedShotsOrderAction = reatomAsync((_ctx, projectId: string) => getGeneratedOrderShotsResource(projectId))
	.pipe(
		withDataAtom([], (_ctx, res) => res.data.shots),
		withStatusesAtom(),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err))
	);

// laptops
export const WIDESCREEN = 2560;
export const FULL_HD = 1920;
export const LAPTOP_LARGE = 1440;
export const LAPTOP_MEDIUM = 1280;
export const LAPTOP_SMALL = 1024;

// tablets
export const TABLET = 770;

// mobile phones

export const MOBILE_EXTRA_LARGE = 550;
export const MOBILE_LARGE = 425;
export const MOBILE_MEDIUM = 375;
export const MOBILE_SMALL = 320;

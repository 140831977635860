import { Button, Flex, Form, Input, message, Typography } from "antd";
import axios from "axios";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { trackGoogleAnalyticsEvent } from "@/shared/methods";
import line from "@/assets/landing/contact-line.svg";

import "./Contacts.scss";

interface IFeedbackForm {
	email: string;
	message: string;
	name: string;
}

export const Contacts: FC = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [isLoading, setIsLoading] = useState(false);
	const [isEmailSendError, setIsEmailSendError] = useState(false);
	const { isLaptopS, isTablet, isMobileXL } = useMediaQueries();

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid!"
		}
	};
	/* eslint-enable no-template-curly-in-string */

	const onFinish = async (values: IFeedbackForm) => {
		try {
			setIsLoading(true);

			const data = {
				service_id: "service_fftdtow",
				template_id: "template_lae8dge",
				user_id: "AQK3iUSsogwlK2dMZ",
				template_params: {
					email: values.email,
					message: values.message
				}
			};

			TagManager.dataLayer({
				dataLayer: {
					event: "contact_us",
					contactUserName: values.name,
					contactUserMessage: values.message
				}
			});
			trackGoogleAnalyticsEvent("white_list", "contacts", "submit", {
				...values,
				email: values.email.replace("@", "_")
			});

			await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
				...data
			}, {
				headers: {
					"Content-Type": "application/json"
				}
			});

			messageApi.open({
				type: "success",
				content: "Your application has been successfully accepted"
			});
			setIsEmailSendError(false);
		} catch (e) {
			setIsEmailSendError(true);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex align="center" justify="center" vertical className="contacts">
			{contextHolder}
			<Flex style={{ position: "relative", maxWidth: 700, width: "100%" }} vertical className="product-new__title gap-xs text-center">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet
				})}>Let’s get <span className="product-new__title--italic">in TOUCH</span>
				</Typography.Text>
				<img className="product-new__action--line contact-line" src={line} alt="line"/>
				<Typography.Text style={{ textTransform: "none" }} className={cn(TEXT_CLASSNAMES.LgRegular, {
					[TEXT_CLASSNAMES.MdRegular]: isLaptopS,
					[TEXT_CLASSNAMES.SmRegular]: isTablet,
					[TEXT_CLASSNAMES.XsRegular]: isMobileXL
				})}>
					We're eager to hear from you! Share your inquiries, feedback, or requests with us by filling out the form below. Our team will respond promptly to assist you.
				</Typography.Text>
			</Flex>
			<Form onFinish={onFinish} validateMessages={validateMessages} layout="vertical" className="contacts__content">
				<Flex vertical className="gap-s text-center">
					<Flex vertical gap="middle">
						<Form.Item id="name" name="name" noStyle rules={[{ required: true }]}>
							<Input placeholder="Name" />
						</Form.Item>
						<Form.Item id="email" name="email" noStyle rules={[{ type: "email", required: true }]}>
							<Input placeholder="Email" />
						</Form.Item>
						<Form.Item id="message" name="message" noStyle rules={[{ required: true }]}>
							<Input.TextArea placeholder="Message" rows={3} />
						</Form.Item>
					</Flex>
					{isEmailSendError && <Typography.Text className="error">Oops something went wrong</Typography.Text>}
					<Form.Item>
						<Button loading={isLoading} className="full-width" htmlType="submit" type="primary">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								Send
							</Typography.Text>
						</Button>
					</Form.Item>
				</Flex>
			</Form>
		</Flex>
	);
};

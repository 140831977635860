import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import { FC, useEffect } from "react";

import { ComingSoonTeam } from "@/pages/team-manage/ui/ComingSoonTeam.tsx";
import { initTeamListAction, teamListAtom, teamListLoadingAtom } from "@/features/team-settings";
import { NOT_RELEASE } from "@/shared/const/release.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { Spinner } from "@/shared/ui";
import { EmptyTeamManage } from "./EmptyTeamManage";
import { FullTeamManage } from "./FullTeamManage";

import "./TeamManage.scss";

export const TeamManage: FC = () => {
	const initTeams = useAction(initTeamListAction);
	const [teamList] = useAtom(teamListAtom);
	const [isLoading] = useAtom(teamListLoadingAtom);

	useEffect(() => {
		initTeams();
	}, []);

	return (
		<Flex vertical className="team-manage gap-s full-width full-height">
			<Flex vertical>
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>Team</Typography.Text>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Project team members </Typography.Text>
			</Flex>
			{NOT_RELEASE
				? (
					<>
						{isLoading
							? (
								<Spinner isOverlay />
							)
							: (
								<>
									{!teamList.length && 	<EmptyTeamManage />}
									{!!teamList.length && <FullTeamManage />}
								</>
							)}
					</>
				)
				: (
					<ComingSoonTeam />
				)}

		</Flex>
	);
};

import { getImageFromBlob, getNotMaxSize } from "@/shared/methods";

export const getImageSizeFromBlob = async (blob: Blob) => {
	const size = await getImageFromBlob(blob);
	const { width, height, aspectWidth, aspectHeight } = getNotMaxSize(size.width, size.height);

	const isMinSize = aspectWidth < 10 || aspectHeight < 10;

	const withMinSize = {
		w: isMinSize ? width * 100 : width,
		h: isMinSize ? height * 100 : height,
		minW: isMinSize ? aspectWidth * 100 : aspectWidth,
		minH: isMinSize ? aspectHeight * 100 : aspectHeight
	};

	return { w: Math.round(withMinSize.w), h: Math.round(withMinSize.h), minW: Math.round(withMinSize.minW), minH: Math.round(withMinSize.minH) };
};

import { Button, Flex, notification, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { PLAN_EMAIL, PlanEmail } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useLocalStorage } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { trackGoogleAnalyticsEvent } from "@/shared/methods";
import info from "@/assets/plan/info.svg";

import "./PlanType.scss";

const KEY = "KEY";

export const EnterprisePlanType: FC = () => {
	const { isMobileL } = useMediaQueries();
	const [userData] = useLocalStorage<PlanEmail | null>(PLAN_EMAIL, null);
	const [api, contextHolder] = notification.useNotification();

	const onTextClick = () => {
		trackGoogleAnalyticsEvent("enterprise", "enterprise", "enterprise", {
			email: userData?.email?.replace("@", "_")
		});

		api.open({
			key: KEY,
			message: (
				<Flex vertical gap={16}>
					<Flex gap={8}>
						<img src={info} alt="info" style={{ width: 24, height: 24 }} />
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							Thank you for reaching out! Currently, only the Single User subscription plan is available for use. However, we’d be happy to discuss the Enterprise plan details with you. Our team will contact you shortly to explore your needs and provide more information about the Enterprise option.
						</Typography.Text>
					</Flex>
					<Flex gap={32}>
						<div />
						<Button onClick={() => api.destroy(KEY)}>Okay</Button>
					</Flex>
				</Flex>
			),
			placement: "top",
			pauseOnHover: false,
			style: {
				width: 500
			},
			duration: 8
		});
	};

	return (
		<Flex gap={8} vertical className={cn("plan-type cubic-animation", {
			"plan-type--disabled": true
		})}>
			{contextHolder}
			<Flex align={isMobileL ? "center" : "initial"}>
				<span className={cn("plan-type__radio cubic-animation")} />
				<Flex vertical className="plan-type__text">
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdSemibold, {
						[TEXT_CLASSNAMES.XsSemibold]: isMobileL
					})}>
						Enterprise
					</Typography.Text>
					{!isMobileL && (
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
							Unlimited users, Unlimited projects, No watermarks
						</Typography.Text>
					)}
				</Flex>
				{isMobileL && <div style={{ flex: 1 }} />}
				<Flex vertical>
					<Flex className="gap-xxs" align="center">
						<Typography.Text onClick={onTextClick} style={{
							minWidth: "max-content",
							textDecoration: "none",
							cursor: "pointer",
							zIndex: 10
						}} className={cn(TEXT_CLASSNAMES.MdSemibold, {
							[TEXT_CLASSNAMES.SmSemibold]: isMobileL
						})}>
							Contact us
						</Typography.Text>
					</Flex>
				</Flex>
			</Flex>
			{isMobileL && (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
					Unlimited users, Unlimited projects, No watermarks
				</Typography.Text>
			)}
		</Flex>
	);
};

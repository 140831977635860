import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CREDENTIAL__REGISTER_KEY, CREDENTIAL_KEY, PLAN_EMAIL } from "@/entities/viewer";
import { getCredential } from "@/entities/viewer/lib/utils.ts";
import error404 from "@/assets/shared/error_404.png";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

export const DesktopOnly: FC = () => {
	const navigate = useNavigate();

	const viewer = getCredential()?.data;
	const isAuth = !!viewer?.key;

	const signOut = () => {
		localStorage.removeItem(PLAN_EMAIL);
		localStorage.removeItem(CREDENTIAL__REGISTER_KEY);
		localStorage.removeItem(CREDENTIAL_KEY);
		window.location.href = "/";
	};

	return (
		<Flex style={{
			height: "100vh"
		}} vertical align="center" justify="center" className="gap-xs text-center">
			<img style={{
				maxWidth: "690px",
				maxHeight: "364px"
			}} className="image-contain" src={error404} alt="error 404"/>
			<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH3}>
				Desktop only
			</Typography.Text>
			<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
				You can access the Blooper app on a desktop browser only.
			</Typography.Text>
			<Button onClick={() => navigate("/")} type="primary">
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Back Home
				</Typography.Text>
			</Button>
			{isAuth && (
				<Button onClick={signOut}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						Sign out
					</Typography.Text>
				</Button>
			)}
		</Flex>
	);
};

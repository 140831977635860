import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, MenuProps, Typography } from "antd";
import cn from "classnames";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import {
	deleteProjectByIdAction,
	DeleteProjectModal,
	renameProjectByIdAction,
	RenameProjectModal, ThumbnailProjectModal, uploadThumbnailAction
} from "@/entities/projects";
import { THUMBNAIL_IMAGE_ID } from "@/entities/projects/lib/const";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { DropdownDots, Spinner } from "@/shared/ui";
import deleteStory from "@/assets/projects/delete.svg";
import edit from "@/assets/projects/edit.svg";
import emptyStoryboard from "@/assets/projects/empty-storyboard.svg";
import thumbnail from "@/assets/projects/thumbnail.svg";

import "./YourStoryboard.scss";

interface IYourStoryboard {
	img?: string;
	name: string;
	date: string;
}

export const YourStoryboard: FC<IYourStoryboard> = ({ img, name, date }) => {
	const navigate = useNavigate();
	const [editOpen, toggleEditOpen] = useOpenModal();
	const [deleteOpen, toggleDeleteOpen] = useOpenModal();
	const [thumbnailOpen, toggleThumbnail] = useOpenModal();
	const [statuses] = useAtom(uploadThumbnailAction.statusesAtom);

	const handleRedirect = () => {
		navigate(`/storyboard/${name}/script`);
	};

	const deleteProject = useAction(deleteProjectByIdAction);
	const renameProject = useAction(renameProjectByIdAction);

	const onRename = (newName: string) => {
		renameProject(name, newName);
		toggleEditOpen(false);
	};

	const onDelete = async () => {
		await deleteProject(name);
		toggleEditOpen(false);
	};

	const onLabelClick = (callback: () => void) => (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		callback();
	};

	const items: MenuProps["items"] = [
		{
			key: `${name}_rename`,
			label: (
				<Flex onClick={onLabelClick(() => { toggleEditOpen(true); })} align="center" className="gap-xxs full-width">
					<img src={edit} alt="rename" />
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Rename</Typography.Text>
				</Flex>
			)
		},
		{
			key: `${name}_thumbnail`,
			label: (
				<Flex onClick={onLabelClick(() => { toggleThumbnail(true); })} align="center" className="gap-xxs full-width">
					<img src={thumbnail} alt="thumbnail" />
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Set a thumbnail</Typography.Text>
				</Flex>
			)
		},
		{
			key: `${name}_delete`,
			label: (
				<Flex style={{ zIndex: 100 }} onClick={onLabelClick(() => toggleDeleteOpen(true))} align="center" className="gap-xxs full-width">
					<img src={deleteStory} alt="delete" />
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.CrazyOrange600)}>Delete</Typography.Text>
				</Flex>
			)
		}
	];

	const isPending = statuses.isPending && localStorage.getItem(THUMBNAIL_IMAGE_ID) === name;

	return (
		<>
			<Flex
				vertical
				align="center"
				justify="center"
				className="your-storyboard gap-xs pointer"
				onClick={handleRedirect}
			>
				<Flex justify="center" align="center" className="your-storyboard__background full-width full-height">
					{isPending
						? <Spinner />
						: <img className={cn({
							"your-storyboard__img": !!img
						})} src={img ? `${img}?no-cache=${Date.now()}` : emptyStoryboard} alt="storyboard"/>}
				</Flex>
				<Flex align="center" justify="space-between" className="full-width your-storyboard__actions">
					<Flex vertical>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextPrimary)}>{name}</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}>{date}</Typography.Text>
					</Flex>
					<DropdownDots items={items} />
				</Flex>
			</Flex>
			{editOpen && <RenameProjectModal onRename={onRename} currentName={name} isOpen={editOpen} toggleModal={toggleEditOpen} />}
			{deleteOpen && <DeleteProjectModal onDelete={onDelete} isOpen={deleteOpen} toggleModal={toggleDeleteOpen} />}
			{thumbnailOpen && <ThumbnailProjectModal isOpen={thumbnailOpen} toggleModal={toggleThumbnail} id={name} />}
		</>
	);
};

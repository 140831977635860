import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

const IS_PROD = import.meta.env.VITE_PRODUCTION_BRANCH === "refs/heads/main";
const PROD_DOMAIN = IS_PROD ? [/^https:\/\/blooper\.ai\/api/] : [/^https:\/\/dev.blooper\.ai\/api/];

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		Sentry.replayIntegration(),
		Sentry.replayCanvasIntegration({
			enableManualSnapshot: true
		})
	],
	tracesSampleRate: 1.0,

	tracePropagationTargets: PROD_DOMAIN,

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});

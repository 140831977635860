import { RegeneratedImage, TStoryboardScene, TStoryboardShot } from "@/entities/storyboard";
import { SBScene, SBShot, UpdateShotParams } from "@/shared/api/storyboard";

export const toShot = (
	shotsInfo: SBShot,
	shotsOrder: string[],
	regeneratedImages: RegeneratedImage
): TStoryboardShot[] => {
	const shots: TStoryboardShot[] = Object.entries(shotsInfo).map(([shotId, shot]) => ({
		id: shotId, // guid
		idx: shotsOrder.findIndex((order) => order === shotId),
		time: shot.time ?? 0,
		location: shot.location ?? "",
		shotCharacteristics: shot.characteristics,
		description: shot.description ?? "",
		props: shot.props, // max length 8
		cameraMovement: shot.camera_movement ?? "",
		shotSettings: shot.settings, // close-up | f/2.8
		dialogue: shot.dialog ?? "",
		characters: shot.characters,
		image: regeneratedImages[shotId] ? `${shot.image_urls.bw_image_url_compressed}?no-cache=${Date.now()}` : (shot.image_urls.bw_image_url_compressed ?? ""),
		title: shot.title ?? "",
		cameraAngle: shot.camera_angle ?? "",
		prompt: shot.prompt ?? [],
		color: shot.color ?? "red"
	}));

	return shots.sort((shotA, shotB) => shotA.idx - shotB.idx);
};

export const toScenes = (
	scenesInfo: SBScene,
	scenesOrder: string[],
	regeneratedImages: RegeneratedImage,
	city: string,
	country: string
) => {
	const updatedList: TStoryboardScene[] = Object.entries(scenesInfo).map(([id, scene]) => ({
		id,
		tableView: "grid",
		idx: scenesOrder.findIndex((order) => order === id),
		title: scene.title,
		shots: toShot(scene.shots_info, scene.shots_order, regeneratedImages),
		locations: scene.scene_locations || [],
		selectedLocation: scene.selected_location ?? "",
		projectLocation: {
			city: city ?? "",
			country: country ?? ""
		},
		scenesOrder
	}));

	return updatedList.sort((sceneA, sceneB) => sceneA.idx - sceneB.idx);
};

export const convertShot = (sceneId: string, shotsOrder: string[], shot: TStoryboardShot): UpdateShotParams => {
	return {
		shot_info: {
			id: shot.id,
			scene_id: sceneId,
			title: shot.title,
			description: shot.description,
			location: shot.location,
			dialog: shot.dialogue,
			time: shot.time,
			camera_movement: shot.cameraMovement,
			camera_angle: shot.cameraAngle,
			shot_settings: [],
			shot_characteristics: [],
			props: shot.props,
			prompt: shot.prompt,
			color: shot.color

			// characters: shot.characters
			// location_type: shot.locationType,
		} as unknown as UpdateShotParams["shot_info"],
		shots_order: shotsOrder
	};
};

import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import logo from "@/assets/shared/black_blooper.svg";
import { INFO_LIST, SOCIAL_MEDIA_LIST } from "@/shared/const/footer";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import blur from "@/assets/landing/footer-green.svg";

import "./Footer.scss";

export const Footer: FC = () => {
	const { isTablet, isLaptopS, isMobileXL } = useMediaQueries();
	const navigate = useNavigate();
	const handleRedirect = (path: string) => {
		navigate(path);
	};

	return (
		<footer className="footer">
			<img className="footer__blur" src={blur} alt="blur" />
			<Flex vertical={isTablet} className={cn("footer__content gap-l")} justify="space-between" align="start">
				<Flex style={{ zIndex: 10 }} vertical className="gap-s">
					<img className="footer__logo" src={logo} alt="blooper" />
					<Flex className="gap-xs">
						{SOCIAL_MEDIA_LIST.map((social, index) => (
							<a href={social.link} target="_blank" key={index.toString(36)} className="footer__social-media flex-ctr-ctr">
								<img className="footer__img" src={social.icon} alt={social.alt}/>
							</a>
						))}
					</Flex>
					<Typography.Text>© Blooper 2024. All rights reserved.</Typography.Text>
				</Flex>
				<Flex wrap="wrap" gap={isMobileXL ? 60 : isTablet ? 80 : isLaptopS ? 100 : 120}>
					{INFO_LIST.map((info, index) => (
						<Flex style={{ maxWidth: "143px" }} className="gap-s" vertical key={index.toString(36)}>
							<Typography.Text className="footer__content__title">
								{info.title}
							</Typography.Text>
							<Flex className="gap-xs" vertical>
								{info.links.map((link, idx) => (
									<Flex onClick={() => handleRedirect(link.link)} className="gap-xxs pointer" align="center" key={idx.toString(36)}>
										{link.icon && (
											<img src={link.icon} alt={link.text} className="footer__img" />
										)}
										<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
											{link.text}
										</Typography.Text>
									</Flex>
								))}
							</Flex>
						</Flex>
					))}
				</Flex>
			</Flex>
		</footer>
	);
};

import bottomAngle from "@/assets/shot-selection/bottom-angle.svg";
import closeUp from "@/assets/shot-selection/close-up.svg";
import eyeLevel from "@/assets/shot-selection/eye-level.svg";
import cameraF8 from "@/assets/shot-selection/f-8.svg";
import highAngle from "@/assets/shot-selection/high-angle.svg";
import lowAngle from "@/assets/shot-selection/low-angle.svg";
import topAngle from "@/assets/shot-selection/top-angle.svg";

export const cameraData: Record<string, {
	img: string;
	title: string;
	key: string;
}> = {
	"eye level": {
		img: eyeLevel,
		title: "Eye level",
		key: "eye level"
	},
	"close up": {
		img: closeUp,
		title: "Close up",
		key: "close up"
	},
	"f/8": {
		img: cameraF8,
		title: "F/8",
		key: "f/8"
	},
	"low angle": {
		img: lowAngle,
		title: "Low angle",
		key: "low angle"
	},
	"top angle": {
		img: topAngle,
		title: "Top angle",
		key: "top angle"
	},
	"bottom angle": {
		img: bottomAngle,
		title: "Bottom angle",
		key: "bottom angle"
	},
	"high angle": {
		img: highAngle,
		title: "High angle",
		key: "high angle"
	}
};

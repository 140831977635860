export function * circularIterator<T> (arr: T[]) {
	let index = -1;
	const elements = Array.isArray(arr) ? arr.slice() : [];
	const length = elements.length;

	// eslint-disable-next-line no-unmodified-loop-condition
	while (length) {
		index = (index + 1) % length;
		yield elements[index];
	}
}

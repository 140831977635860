import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";

import { deleteSceneAction, deleteShotAction } from "@/entities/script";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { IOpenModal } from "@/shared/hooks";
import info from "@/assets/description/info.svg";

interface IDeleteSceneModal extends IOpenModal{
	sceneId: string;
	shotId: string;
	isLast: boolean;
}

export const DeleteShotModal: FC<IDeleteSceneModal> = ({ sceneId, shotId, toggleModal, isOpen, isLast }) => {
	const { id } = useParams();
	const deleteShot = useAction(deleteShotAction);
	const deleteScene = useAction(deleteSceneAction);

	const onClose = () => {
		toggleModal(false);
	};

	const handleDeleteShot = async () => {
		if (id) {
			await deleteShot(id, { sceneId, shotId });
			onClose();
		}
	};

	const handleDeleteScene = async () => {
		if (id) {
			await deleteScene(id, sceneId);
			onClose();
		}
	};

	const title = isLast
		? "Are you sure you want to delete the last shot with scene?"
		: "Are you sure you want to delete the shot?";

	const description = isLast
		? "All content from this scene will be deleted"
		: "All content from this shot will be deleted";

	return (
		<>
			<Modal
				open={isOpen}
				width={590}
				onCancel={onClose}
				footer={
					<Flex gap={8} justify="flex-end">
						<Button onClick={onClose}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
						</Button>
						<Button type="primary" onClick={isLast ? handleDeleteScene : handleDeleteShot}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Yes, delete it</Typography.Text>
						</Button>
					</Flex>
				}
			>
				<Flex vertical className="gap-xxs">
					<Flex style={{ background: "transparent" }} align="center" className="gap-xxs">
						<img src={info} alt="info" />
						<Typography.Text>{title}</Typography.Text>
					</Flex>
					<Typography.Text style={{ paddingLeft: 40 }} className={TEXT_CLASSNAMES.XsRegular}>
						{description}
					</Typography.Text>
				</Flex>
			</Modal>
		</>
	);
};

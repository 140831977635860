import { useAction } from "@reatom/npm-react";
import { Flex, Popover, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import { updateShotCameraAngleActions } from "@/features/shot-details/model";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

import { cameraData } from "./const.ts";
import { Content } from "./Content.tsx";
import settings from "@/assets/shot-selection/settings.svg";

type Props = {
	cameraAngle?: string;
}

export const ShotCameraAngleEdit: FC<Props> = ({ cameraAngle }) => {
	const updateCameraAngle = useAction(updateShotCameraAngleActions);
	const [activeCameraAngle, setActiveCameraAngle] = useState(cameraAngle ?? "");
	const [open, setOpen] = useState(false);

	const hide = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	const onChange = (value: string) => {
		setActiveCameraAngle(value);
		updateCameraAngle(value);
		hide();
	};

	if (!activeCameraAngle) {
		return null;
	}

	if (cameraData[activeCameraAngle]?.title) {
		return (
			<>
				<div className="shot-details-info__divider" />
				<Flex wrap="wrap" className="shot-details-info__row gap-xs">
					<Flex vertical className="gap-xxs full-width">
						<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Shot Settings</Typography.Text>
						<Popover
							open={open}
							onOpenChange={handleOpenChange}
							content={<Content hide={hide} onChange={onChange} active={activeCameraAngle} />}
							trigger="contextMenu"
							placement="left"
						>
							<Flex align="center" justify="space-between" className="shot-details-info__camera gap-xxs full-width">
								<Flex align="center" className="gap-xs">
									<img
										className="shot-details-info__camera__img image-contain"
										src={cameraData[activeCameraAngle].img}
										alt={cameraData[activeCameraAngle].key}
									/>
									<Flex vertical gap={2}>
										<Typography.Text style={{ width: "max-content" }} className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Сamera angle</Typography.Text>
										<Typography.Text
											style={{ width: "max-content" }}
											className={TEXT_CLASSNAMES.XsRegular}>{cameraData[activeCameraAngle].title}</Typography.Text>
									</Flex>
								</Flex>
								<img className="pointer" src={settings} alt="settings" onClick={() => handleOpenChange(true)} />
							</Flex>
						</Popover>
					</Flex>
				</Flex>
			</>
		);
	}

	return (
		<>
			<div className="shot-details-info__divider"/>
			<Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
				<Flex vertical className="gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Camera
						angle</Typography.Text>
					<Popover
						open={open}
						onOpenChange={handleOpenChange}
						content={<Content hide={hide} onChange={onChange} active={activeCameraAngle} />}
						trigger="contextMenu"
						placement="left"
					>
						<Flex align="center" justify="space-between" className="shot-details-info__camera gap-xxs full-width">
							<Flex align="center" className="gap-xs">
								<Flex vertical gap={2}>
									<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Сamera angle</Typography.Text>
									<Typography.Text
										className={TEXT_CLASSNAMES.XsRegular}>{activeCameraAngle}</Typography.Text>
								</Flex>
							</Flex>
							<img className="pointer" src={settings} alt="settings" onClick={() => handleOpenChange(true)} />
						</Flex>
					</Popover>
				</Flex>
			</Flex>
		</>
	);
};

import { useAction } from "@reatom/npm-react";
import { useHover, useSize } from "ahooks";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { updateGenerateImageAction } from "@/entities/storyboard";
import { generateStoryboardShotResource } from "@/shared/api/storyboard";
import { useOpenModal } from "@/shared/hooks";

export const useShotStoryboard = () => {
	const navigate = useNavigate();
	const params = useParams();
	const imageRef = useRef(null);
	const descriptionRef = useRef<null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const descriptionSize = useSize(descriptionRef);
	const isHoverImage = useHover(imageRef);
	const updateGenerateImage = useAction(updateGenerateImageAction);
	const [isOpenModal, toggleOpenModal] = useOpenModal();
	const onCharacterRedirect = (key: string) => {
		navigate(`/storyboard/${params.id}/character/${key}/character-info`);
	};
	const onRedirect = (shotId: string, sceneId: string) => {
		navigate(`/storyboard/${params.id}/shot-selection/${sceneId}/${shotId}`);
	};

	const onGenerate = async (shotId: string) => {
		updateGenerateImage({ shotId, loading: true, image: null, error: false });
		let image = null;
		let error = false;

		try {
			if (params.id) {
				toggleOpenModal(false);
				const shotInfo = await generateStoryboardShotResource({ shotId, projectId: params.id });

				image = shotInfo.data.image_url_compressed ?? "";
			}
		} catch (err) {
			error = true;
			updateGenerateImage({ shotId, loading: false, image, error });
		} finally {
			updateGenerateImage({ shotId, loading: false, image, error });
		}
	};

	const onScroll = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({ left: 0, top: containerRef.current.scrollHeight, behavior: "smooth" });
		}
	};

	return {
		isOpenModal,
		toggleOpenModal,
		onRedirect,
		onGenerate,
		imageRef,
		isHoverImage,
		onCharacterRedirect,
		descriptionRef,
		containerRef,
		descriptionSize,
		onScroll
	};
};

import { useAtom } from "@reatom/npm-react";
import { useClickAway } from "ahooks";
import { Avatar, Button, Flex, Popover, Typography } from "antd";
import cn from "classnames";
import { FC, useRef, useState } from "react";

import {
	EditButton,
	generateImageAtom, regenerateImageAtom,
	// GenerateImageModal,
	TStoryboardShot,
	useShotStoryboard
} from "@/entities/storyboard";
import { ImageHoverAction } from "@/entities/storyboard/ui/image-hover-action";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

// import { urlToBase64 } from "@/shared/methods";
import { toCharacter } from "@/shared/methods/toCharacter";
import { Spinner } from "@/shared/ui";
import { PopoverContent } from "./PopoverContent";

import bottom from "@/assets/storyboard/bottom.svg";
import closeEye from "@/assets/storyboard/close-eye.svg";
import emptyShot from "@/assets/storyboard/empty-shot.svg";
import eye from "@/assets/storyboard/eye.svg";
import refresh from "@/assets/storyboard/refresh.svg";
import timeImg from "@/assets/storyboard/time.svg";

import "./SceneShotGrid.scss";

export const SceneShotGrid: FC<TStoryboardShot & { sceneId: string }> = ({
	id,
	// idx,
	time,
	location,
	description,
	props,
	dialogue,
	characters,
	image,
	// title,
	shotCharacteristics,
	cameraMovement,
	shotSettings,
	sceneId,
	cameraAngle
}) => {
	// const [imageLoading, setImageLoading] = useState(false);
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [generateImage] = useAtom(generateImageAtom);
	const [regenerateImage] = useAtom(regenerateImageAtom);

	const {
		onGenerate,
		// toggleOpenModal,
		// isOpenModal,
		onRedirect,
		imageRef,
		isHoverImage,
		onCharacterRedirect,
		descriptionSize,
		descriptionRef,
		containerRef,
		onScroll
	} = useShotStoryboard();
	const ref = useRef(null);
	const pending = regenerateImage[id]?.pending ?? generateImage[id]?.pending;
	const error = regenerateImage[id]?.error ?? generateImage[id]?.error;
	const imageGenerated = regenerateImage[id]?.image ?? generateImage[id]?.image;
	const currentImage = (imageGenerated ?? image);
	// const [base64Image, setBase64Image] = useState<null | string>(null);

	useClickAway(() => setIsShowDetails(false), ref, ["click", "contextmenu"]);

	// useEffect(() => {
	// 	const init = async () => {
	// 		setImageLoading(true);
	//
	// 		try {
	// 			if (currentImage) {
	// 				const data = await urlToBase64(currentImage);
	// 				setBase64Image(data as string);
	// 			}
	// 		} catch (err) {
	// 			setBase64Image(currentImage);
	// 		} finally {
	// 			setImageLoading(false);
	// 		}
	// 	};
	//
	// 	init();
	// }, []);

	// useEffect(() => {
	// 	if (imageGenerated) {
	// 		setBase64Image(imageGenerated);
	// 	}
	// }, [imageGenerated]);

	return (
		<>
			<Flex className="scene-shot-grid" vertical ref={ref}>
				{isShowDetails && <div className="scene-shot-grid__overlay" />}
				{/* <Flex className="scene-shot-grid__header" align="center" justify="space-between"> */}
				{/*	<Typography className={TEXT_CLASSNAMES.MdSemibold}>Shot {idx + 1} {!!title.length && `"${title}"`}</Typography> */}
				{/*	<EditButton isActive={!!image} onClick={() => onRedirect(id, sceneId)} /> */}
				{/* </Flex> */}
				<Flex ref={imageRef} align="center" justify="center" className="scene-shot-grid__img">
					{pending && <Flex align="center" justify="center" className="scene-shot-grid__overlay"><Spinner /></Flex>}
					{/* <Flex className={cn("scene-shot-grid__tag scene-shot-grid__title flex-ctr-ctr gap-4")}> */}
					{/*	<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.MetalGray700)}> */}
					{/*		{idx + 1} {!!title.length && `"${title}"`} */}
					{/*	</Typography.Text> */}
					{/* </Flex> */}
					{error
						? (
							<Flex vertical className="gap-xxs error-card">
								<Flex vertical align="center" className="gap-4">
									<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Something went wrong</Typography.Text>
									<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>Please try again</Typography.Text>
								</Flex>
								<Button
									icon={<img src={refresh} alt="refresh" />}
									className="scene-shot-grid__error-regenerate flex-bt-ctr"
									onClick={() => onGenerate(id)}
								>
									<Typography className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}>
										Generate
									</Typography>
								</Button>
							</Flex>
						)
						: (
							<>
								<img className={cn({
									"scene-shot-grid__img__full": currentImage,
									"scene-shot-grid__img__default": !currentImage
								})} src={currentImage || emptyShot} alt="shot image" />
								<ImageHoverAction
									image={currentImage}
									onGenerate={() => onGenerate(id)}
									isHoverImage={isHoverImage}
								/>
							</>
						)}
				</Flex>
				<Flex vertical className="scene-shot-grid__line gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Location</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{location}</Typography.Text>
					<Flex className="gap-xxs" wrap="wrap">
						<Flex align="center" className="gap-4 scene-shot-grid__tag">
							<img style={{ width: 16, height: 16 }} src={timeImg} className="image-contain" alt="time" />
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.MetalGray700)}>
								{`${time} sec`}
							</Typography.Text>
						</Flex>
						{shotCharacteristics.map((tag, index) => (
							<Flex className="scene-shot-grid__tag" key={index.toString(36)}>{tag}</Flex>
						))}
					</Flex>
				</Flex>
				<Flex style={{ position: "relative" }}>
					<Flex
						ref={containerRef}
						vertical
						className="scene-shot-grid__line scene-shot-grid__line__height scene-shot-grid__line__description gap-xxs"
					>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Description</Typography.Text>
						{!description && <Typography.Text>-</Typography.Text>}
						<Typography.Text ref={descriptionRef} className={TEXT_CLASSNAMES.XsRegular}>
							{description}
						</Typography.Text>
					</Flex>
					{((descriptionSize?.height || 0) > 60) && (
						<Flex
							align="center"
							justify="center"
							className="scene-shot-grid__line__description--scroll"
							role="button"
							onClick={onScroll}
						>
							<img style={{ width: 20, height: 20 }} src={bottom} alt="bottom"/>
						</Flex>
					)}
				</Flex>
				<Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Dialogue</Typography.Text>
					{!dialogue.length && <Typography.Text>-</Typography.Text>}
					{!!dialogue.length && <Typography.Text className={cn("scene-shot-grid__line--dialogue", TEXT_CLASSNAMES.XsRegular)}>{dialogue}</Typography.Text>}
					{/* {dialogue.map(({ text, characterName }, index) => ( */}
					{/*	<Flex key={index.toString(36)}> */}
					{/*		<Typography className={TEXT_CLASSNAMES.XsSemibold}>{characterName}: </Typography> */}
					{/*		<Typography className={TEXT_CLASSNAMES.XsRegular}>{text}</Typography> */}
					{/*	</Flex> */}
					{/* ))} */}
				</Flex>
				<Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Characters</Typography.Text>
					{!characters.length && <Typography.Text>-</Typography.Text>}
					<Flex className="gap-xxs">
						{[...new Map(characters.map(item => [item.key, item])).values()].map((character, index) => (
							<Avatar
								style={{ borderColor: COLOR_NAMES.MetalGray300, backgroundColor: COLOR_NAMES.MetalGray50 }}
								key={index.toString(36)}
								size={46}
								icon={<img src={toCharacter(character.key, "4")} alt="character" />}
								onClick={() => onCharacterRedirect(character.key)}
							/>
						))}
					</Flex>
				</Flex>
				<div className="scene-shot-grid__line scene-shot-grid__line__actions">
					<Flex className="gap-xxs">
						<Popover
							placement="topLeft"
							content={<PopoverContent cameraAngle={cameraAngle} props={props} cameraMovement={cameraMovement} shotSettings={shotSettings} />}
							trigger="click"
							open={isShowDetails}
							onOpenChange={(open) => setIsShowDetails(open)}
						>
							<Flex onClick={() => setIsShowDetails(!isShowDetails)} role="button" justify="space-between" align="center" className="gap-4 scene-shot-grid__details pointer">
								<img src={isShowDetails ? closeEye : eye} alt={isShowDetails ? "Hide details" : "Show details"}/>
								<span className={TEXT_CLASSNAMES.XsRegular}>
									{isShowDetails ? "Hide details" : "Show details"}
								</span>
							</Flex>
						</Popover>
						<EditButton isActive={!!currentImage} onClick={() => onRedirect(id, sceneId)}>
							<Typography className={TEXT_CLASSNAMES.XsRegular}>
								Edit shot
							</Typography>
						</EditButton>
					</Flex>
				</div>
			</Flex>
			{/* <GenerateImageModal */}
			{/*	scene={title} */}
			{/*	tags={props} */}
			{/*	onGenerate={() => onGenerate(id, sceneId)} */}
			{/*	isOpen={isOpenModal} */}
			{/*	toggleModal={toggleOpenModal} */}
			{/* /> */}
		</>
	);
};

import { Descendant } from "slate";
import { HTShot, HTSScene, TShotColor } from "@/shared/api/script";
import { circularIterator, insert } from "@/shared/methods";
import { SceneHistoryRequest, TScene, TShot } from "../lib";

export const sortShots = (shotsInfo: HTShot, shotsOrder: string[]): TShot[] => {
	const colors: TShotColor[] = ["violet", "green", "blue", "red", "orange"];
	const shots = Object.entries(shotsInfo).map(([shotId, shot]) => ({
		id: shotId,
		idx: shotsOrder.findIndex((order) => order === shotId),
		title: shot.title,
		prompt: typeof shot.prompt === "string" ? [{ type: "paragraph", children: [{ text: shot.prompt }] }] as Descendant[] : Array.isArray(shot.prompt) ? shot.prompt : [],
		color: colors.includes(shot.color) ? shot.color : randomColor.next().value as TShotColor,
		description: shot.description
	}));

	return shots.sort((shotA, shotB) => shotA.idx - shotB.idx);
};

export const sortScenes = (scenes: HTSScene, orders: string[]): TScene[] => {
	const sceneList = Object.entries(scenes);

	const sceneListUpdated: TScene[] = sceneList.map(([id, scene]) => ({
		id,
		title: scene.title,
		idx: orders.findIndex((order) => order === id),
		shots: sortShots(scene.shots_info, scene.shots_order),
		locations: scene.location_options,
		selectedLocation: scene.location_options?.[0] || ""
	}));

	return sceneListUpdated.sort((sceneA, sceneB) => sceneA.idx - sceneB.idx);
};

type TInsertShotsParams = {
	shot: TShot
	shots: TShot[]
	lastShotIndex: number
};

export const insertShots = (params: TInsertShotsParams, orders: string[]) => {
	const insertedShots = insert<TShot>(params.lastShotIndex + 1, [params.shot], params.shots);
	const updatedShots = insertedShots.map((shot) => ({
		...shot,
		idx: orders.findIndex((order) => order === shot.id)
	}));

	return updatedShots.sort((shotA, shotB) => shotA.idx - shotB.idx);
};

const colors: TShotColor[] = ["violet", "green", "blue", "red", "orange"];
const colorIterator = (elements: TShotColor[]) => {
	return ((items) => {
		const randomColor = circularIterator<TShotColor>(items);

		return randomColor;
	})(elements);
};

export const randomColor = colorIterator(colors);

export const dumpIndex = (step: number, arr: Array<SceneHistoryRequest>) => {
	let index =
		step > 0
			? step - 1 // move forward
			: arr.length + step; // move backward
	if (index >= arr.length - 1) {
		index = arr.length - 1;
	}
	if (index < 0) {
		index = 0;
	}
	return index;
};

export const split = (step: number, targetArr: Array<SceneHistoryRequest>) => {
	const index = dumpIndex(step, targetArr);
	return {
		_current: targetArr[index],
		_before: targetArr.slice(0, index),
		_after: targetArr.slice(index + 1)
	};
};

export const getScriptLength = (list: TScene[]) => {
	let length = 0;

	list.forEach((scene) => {
		scene.shots.forEach((shot) => {
			length += shot.description.length;
		});
	});

	return length;
};

import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Input, Typography } from "antd";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PaymentSchedule } from "@/pages/payment/ui/payment-schedule";
import {
	BackgroundPreLogin,
	LoginWrapper,
	sessionPreLoginAction,
	viewerAtom
} from "@/entities/viewer";
import { TRegisterKey } from "@/shared/api/auth";
import {
	applyPromoCodeResource,
	checkPromoCodeResource,
	getPaymentCredential,
	PaymentCredentials
} from "@/shared/api/payment";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { PAYMENT_ERRORS_PLAN_REDIRECT } from "@/shared/const/payment.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { centsToDollars } from "@/shared/methods";

export const UpgradePlan = () => {
	const navigate = useNavigate();
	const { isMobileL } = useMediaQueries();

	const [promoCode, setPromoCode] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [paymentCredentials, setPaymentCredentials] = useState<PaymentCredentials | null>(null);

	const preLoginSession = useAction(sessionPreLoginAction);
	const [viewer] = useAtom(viewerAtom);

	const updatePaymentCredentials = async () => {
		try {
			setLoading(true);
			const { data: credentials } = await getPaymentCredential();
			setPaymentCredentials({
				...credentials,
				upcoming_invoice: {
					...credentials.upcoming_invoice,
					amount: centsToDollars(credentials.upcoming_invoice.amount)
				},
				current_invoice: {
					...credentials.current_invoice,
					amount: centsToDollars(credentials.current_invoice.amount)
				}
			});
		} catch (e) {
			setError("Ooops, something went wrong");
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const { data: credentials } = await getPaymentCredential();

				if (credentials === null || PAYMENT_ERRORS_PLAN_REDIRECT.includes(credentials?.message ?? "")) {
					preLoginSession(viewer as TRegisterKey);
					navigate("/plan");
				} else {
					setPaymentCredentials({
						...credentials,
						upcoming_invoice: {
							...credentials.upcoming_invoice,
							amount: centsToDollars(credentials.upcoming_invoice.amount)
						},
						current_invoice: {
							...credentials.current_invoice,
							amount: centsToDollars(credentials.current_invoice.amount)
						}
					});
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};

		init();
	}, []);

	const onApply = async () => {
		try {
			setLoading(true);
			const { data: checked } = await checkPromoCodeResource(viewer?.key ?? "", promoCode, "1");

			if (checked.code) {
				await applyPromoCodeResource(viewer?.key ?? "", checked.code);
				await updatePaymentCredentials();
			} else {
				setError("The promo code you entered is invalid. Please check the code and try again.");
			}
		} catch (e) {
			setError("The promo code you entered is invalid. Please check the code and try again.");
		} finally {
			setLoading(false);
		}
	};

	const onBack = () => {
		navigate("/main/projects");
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper title="" questionText="" actionText="" action={() => {}}>
				<Flex align="center" className="full-width full-height">
					<Flex vertical gap={16}>
						{paymentCredentials !== null && (
							<PaymentSchedule isToday={false} sub={paymentCredentials} />
						)}
						<Flex vertical className="full-width gap-xs">
							<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH4, {
								[TEXT_CLASSNAMES.XsSemibold]: isMobileL
							})}>
								Make sure to apply your promo code now!
							</Typography.Text>
							<Flex className="gap-xs">
								<Input
									onChange={(event) => setPromoCode(event.target.value)}
									placeholder="Enter code here"
									disabled={loading}
								/>
							</Flex>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.CrazyOrange500)}>{error}</Typography.Text>
						</Flex>
						<Flex className="full-width" gap={8}>
							<Button
								loading={loading}
								className="full-width"
								onClick={onApply}
								type="primary"
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Apply</Typography.Text>
							</Button>
						</Flex>
						<Flex className="full-width" gap={8}>
							<Button
								loading={loading}
								className="full-width"
								onClick={onBack}
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Back</Typography.Text>
							</Button>
						</Flex>
					</Flex>
				</Flex>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

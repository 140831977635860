import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Form, Input, Typography, Upload, message, UploadFile } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import cn from "classnames";
import { FC, useMemo, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import {
	newProjectAtom,
	resetDataAction
} from "@/features/add-new-project/model";
import { callErrorAction } from "@/entities/notification";
import { createProjectAction, TNewProject } from "@/entities/projects";
import { addScriptToProjectAction, getSceneListAction } from "@/entities/script";
import uploadImage from "@/assets/shared/plus_load.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { isObjectEmpty } from "@/shared/methods/isObjectEmpty";
import { DisableSubmitButton } from "@/shared/ui";

type TFiled = Pick<TNewProject, "script" | "briefing" | "country" | "city">;

interface IThirdStepForm {
	closeModal: () => void;
	isBriefOpen: boolean;
}

export const ThirdStepForm: FC<IThirdStepForm> = ({ closeModal, isBriefOpen }) => {
	const [loading, setLoading] = useState(false);
	const [newProject, setNewProject] = useAtom(newProjectAtom);
	const { id } = useParams();
	const navigate = useNavigate();

	const catchError = useAction(callErrorAction);
	const createProject = useAction(createProjectAction);
	const addScriptToProject = useAction(addScriptToProjectAction);
	const resetData = useAction(resetDataAction);
	const initScript = useAction(getSceneListAction);

	const [form] = Form.useForm();
	const script: UploadChangeParam | null = Form.useWatch("script", { form });
	const briefing = Form.useWatch("briefing", { form });
	const getScriptIsEmpty = () => {
		return !script || isObjectEmpty(script) || !script.fileList?.length;
	};

	const isEmptyFields = !briefing && getScriptIsEmpty();
	const onFinish = async (data: TFiled) => {
		setLoading(true);
		try {
			if (isBriefOpen) {
				await createProject({
					...newProject,
					...data
				});
			} else if (id) {
				await addScriptToProject(data.script, id);
				await initScript(id);
			}

			if (newProject.name) {
				navigate(`/storyboard/${newProject.name}/script`);
			}
		} catch (e) {
			catchError(e);
			setLoading(false);
		} finally {
			resetData();
			closeModal();
			setLoading(false);
		}
	};

	const fields = useMemo(() => {
		const result = [];
		result[0] = { name: "script", value: newProject.script };
		result[1] = { name: "briefing", value: newProject.briefing };

		return result;
	}, []);

	const onChange = () => {
		if (isEmptyFields) {
			form.validateFields();
		}
	};

	const onRemove = () => {
		form.setFieldValue("script", null);
		setNewProject({
			...newProject,
			script: null
		});
	};

	const beforeUpload = (file: UploadFile) => {
		const isLt16M = (file?.size ?? 0) / 1024 / 1024 < import.meta.env.VITE_IMAGE_LOAD_LIMIT_MB;

		if (isLt16M) {
			setNewProject({
				...newProject,
				// it's magic from antd
				script: file as unknown as UploadChangeParam
			});
		} else {
			message.error(`File must be smaller than ${import.meta.env.VITE_IMAGE_LOAD_LIMIT_MB}MB!`);
			return Upload.LIST_IGNORE;
		}

		return false;
	};

	return (
		<Form fields={fields} onFinish={onFinish} form={form}>
			<Flex vertical className="gap-s">
				<Flex vertical>
					<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
						Adding a script
					</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
						Add your script to create a full-fledged storyboard
					</Typography.Text>
				</Flex>
				<Flex vertical className="gap-xs">
					<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
						Upload the script (10000 characters max)
					</Typography.Text>
					<Form.Item<TFiled> name="script" rules={[{ required: isEmptyFields }]}>
						<Upload.Dragger
							defaultFileList={newProject.script ? [newProject.script as unknown as UploadFile] : []}
							maxCount={1}
							onRemove={onRemove}
							beforeUpload={beforeUpload}
							accept=".pdf,.txt,.doc,.docx"
							multiple={false}
							className={cn("add-image-moodboard__upload upload-list-inline")}
							onChange={onChange}
							listType="picture"
							disabled={loading}
						>
							<p className="ant-upload-drag-icon">
								<img src={uploadImage} alt="uplaod image" />
							</p>
							<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
								Drag and drop to upload, or <Typography.Text className={COLOR_CLASSNAMES.Green600}>browse</Typography.Text> <br />
								Supports: PDF, TXT, DOC
							</Typography.Text>
						</Upload.Dragger>
					</Form.Item>
				</Flex>
				{NOT_RELEASE && (
					<>
						{isBriefOpen && (
							<>
								<Flex justify="center" align="center">
									<Typography.Text className={cn(TEXT_CLASSNAMES.LgBold, COLOR_CLASSNAMES.TextSecondary)}>or</Typography.Text>
								</Flex>
								<Flex vertical className="gap-xs">
									<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
										Describe your project brief
									</Typography.Text>
									<Form.Item<TFiled> rules={[{ required: isEmptyFields, message: "Please enter briefing or upload file" }]} name="briefing">
										<Input.TextArea
											disabled={loading}
											rows={7}
											placeholder="Example: Set in a cozy vintage café, 'Brewing Love' is a short film that celebrates the romance between a coffee enthusiast and their favorite brew.
With a warm color palette and soft lighting, the film captures close-ups of swirling coffee and the satisfying clink of porcelain cups"
										/>
									</Form.Item>
								</Flex>
							</>
						)}
					</>
				)}
				<DisableSubmitButton
					type="primary"
					form={form}
					disabled={loading}
					loading={loading}
				>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						Start creating
					</Typography.Text>
				</DisableSubmitButton>
			</Flex>
		</Form>
	);
};

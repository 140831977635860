import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex } from "antd";
import { CSSProperties, memo } from "react";
import { useParams } from "react-router-dom";
import { Attrs, removeFromMoodBoardAction, shadowMoodboardButtonsAtom } from "@/entities/moodboard";
import { COLOR_NAMES } from "@/shared/const/color-names";

import down from "@/assets/moodboard/down.svg";
// import lock from "@/assets/moodboard/lock.svg";
import trash from "@/assets/moodboard/trash.svg";
import up from "@/assets/moodboard/up.svg";

export const ShadowButtons = memo(
	() => {
		const { id } = useParams();
		const [buttons] = useAtom(shadowMoodboardButtonsAtom);
		const remove = useAction(removeFromMoodBoardAction);

		const onRemove = (imageId: string) => {
			if (id) {
				remove(id, imageId);
			}
		};

		const toStyle = (button: Attrs): CSSProperties => {
			const ACTIONS_WIDTH = 55;
			if (button.x > ACTIONS_WIDTH) {
				return {
					width: ACTIONS_WIDTH,
					zIndex: 100,
					position: "absolute",
					top: button.y,
					paddingRight: 15,
					left: button.x - ACTIONS_WIDTH
				};
			}

			return {
				width: ACTIONS_WIDTH,
				zIndex: 100,
				position: "absolute",
				top: button.y,
				paddingLeft: 15,
				left: button.width + button.x
			};
		};

		return (
			<>

				{buttons.map((button) => button.isActive && (
					<Flex
						style={toStyle(button)}
						vertical
						className="gap-xs"
						key={button.name}
					>
						<Flex vertical className="gap-xs">
							{/* <Button icon={<img src={lock} alt="lock" />} style={{ background: COLOR_NAMES.BackgroundWhite }} /> */}
							<Button onClick={() => onRemove(button.name)} icon={<img src={trash} alt="trash" />} style={{ background: COLOR_NAMES.BackgroundWhite }} />
						</Flex>
						<Flex vertical className="gap-xs">
							<Button
								icon={<img src={up} alt="up" />}
								style={{ background: COLOR_NAMES.BackgroundWhite }}
								onClick={() => button.group?.moveUp()}
							/>
							<Button
								icon={<img src={down} alt="down" />}
								style={{ background: COLOR_NAMES.BackgroundWhite }}
								onClick={() => button.group?.moveDown()}
							/>
						</Flex>
					</Flex>
				))}
			</>
		);
	}
);

import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, PropsWithChildren } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./BillingWrapper.scss";

export const BillingWrapper: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Flex className="gap-s billing-wrapper">
			<Flex className="billing-wrapper__content" vertical>
				<Flex vertical align="center" className="gap-xxs">
					<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
						You’re almost there
					</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
						Below is your billing summary and schedule. Future payments will be charged automatically on the dates shown.
					</Typography.Text>
				</Flex>
				{children}
			</Flex>
		</Flex>
	);
};

import { Flex, Form, Input, InputRef, Typography } from "antd";
import { AxiosError } from "axios";
import cn from "classnames";
import { KeyboardEvent, RefObject, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BackgroundPreLogin, LoginWrapper, RecoverPasswordWrapper, RESET_PASSWORD_KEY } from "@/entities/viewer";
import { TRegisterKey, validateResetPasswordResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useLocalStorage } from "@/shared/hooks";
import { CountDownResendCode } from "./CountDownResendCode";

import "./EnterTheCode.scss";

export const EnterTheCode = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { email } = useParams();
	const [error, setError] = useState<null | string>(null);
	const [_, setResetPasswordKey] = useLocalStorage<TRegisterKey | null>(RESET_PASSWORD_KEY, null);

	const firstRef = useRef<InputRef>(null);
	const secondRef = useRef<InputRef>(null);
	const thirdRef = useRef<InputRef>(null);
	const fourthRef = useRef<InputRef>(null);
	const fifthRef = useRef<InputRef>(null);
	const sixRef = useRef<InputRef>(null);

	const focusRefs: {[key: number]: RefObject<InputRef>} = {
		1: firstRef,
		2: secondRef,
		3: thirdRef,
		4: fourthRef,
		5: fifthRef,
		6: sixRef
	};

	const onKeyDown = (name: number) => (event: KeyboardEvent<HTMLInputElement>) => {
		const { key } = event;
		const numberKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

		if (key === "Delete" || key === "Backspace") {
			const ref = focusRefs[name - 1];

			setTimeout(() => {
				if (ref && ref.current) {
					ref.current.focus();
				}
			}, 0);
		}

		if (numberKeys.includes(key)) {
			const ref = focusRefs[name + 1];

			setTimeout(() => {
				if (ref && ref.current) {
					ref.current.focus();
				}
			}, 0);
		}
	};

	const onFinish = async (code: Record<string, string>) => {
		try {
			if (email) {
				const { data } = await validateResetPasswordResource(email, Object.values(code).join(""));
				setResetPasswordKey(data);
				navigate(`/create-new-password/${email}`);
			}
		} catch (e) {
			const err = e as AxiosError;

			// @ts-ignore
			setError(err.response?.data?.message ?? "Something went wrong. Please try again");
		}
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper title={""} questionText={""} actionText={""} action={() => {}}>
				<Form
					form={form}
					onFinish={onFinish}
				>
					<RecoverPasswordWrapper
						form={form}
						title="Enter the code"
						description={`We sent a 6-digit password recovery code to your email ${email}.`}
						buttonText="Reset password"
					>
						<Flex align="center" className="enter-the-code" gap={12}>
							<Form.Item name="1" rules={[{ required: true, message: " " }]}>
								<Input
									onKeyDown={onKeyDown(1)}
									placeholder="0"
									className="enter-the-code__input"
									ref={firstRef}
									autoFocus
								/>
							</Form.Item>
							<Form.Item name="2" rules={[{ required: true, message: " " }]}>
								<Input
									onKeyDown={onKeyDown(2)}
									placeholder="0"
									className="enter-the-code__input"
									ref={secondRef}
								/>
							</Form.Item>
							<Form.Item name="3" rules={[{ required: true, message: " " }]}>
								<Input
									onKeyDown={onKeyDown(3)}
									placeholder="0"
									className="enter-the-code__input"
									ref={thirdRef}
								/>
							</Form.Item>
							<Form.Item name="4" rules={[{ required: true, message: " " }]}>
								<Input
									onKeyDown={onKeyDown(4)}
									placeholder="0"
									className="enter-the-code__input"
									ref={fourthRef}
								/>
							</Form.Item>
							<Form.Item name="5" rules={[{ required: true, message: " " }]}>
								<Input
									onKeyDown={onKeyDown(5)}
									placeholder="0"
									className="enter-the-code__input"
									ref={fifthRef}
								/>
							</Form.Item>
							<Form.Item name="6" rules={[{ required: true, message: " " }]}>
								<Input
									onKeyDown={onKeyDown(6)}
									placeholder="0"
									className="enter-the-code__input"
									ref={sixRef}
								/>
							</Form.Item>
						</Flex>
						<CountDownResendCode />
						{error && <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.HeartRed400)}>{error}</Typography.Text>}
					</RecoverPasswordWrapper>
				</Form>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";

import { Gallery } from "react-grid-gallery";
import { useParams } from "react-router-dom";
import { AddBriefMoodboard } from "@/features/add-brief-moodboard";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { DraggableBoard, DraggableImage, DraggableOverlay } from "@/shared/ui";

import { DRAGGABLE_IMAGE_ID } from "../lib";
import {
	getInspirationPromptAction,
	inspirationBoardListAtom,
	inspirationBoardLoadingAtom,
	inspirationPromptLoadingAtom
} from "../model";
import emptyInspirationBoard from "@/assets/inspiration-board/empty_inspiration_board.svg";

import "./InspirationBoard.scss";

export const InspirationBoard: FC = () => {
	const { id } = useParams();
	const [isOpenAddBriefnModal, toggleAddBriefModal] = useOpenModal();
	const [isLoadingBoard] = useAtom(inspirationBoardLoadingAtom);
	const [isLoadingPrompt] = useAtom(inspirationPromptLoadingAtom);
	const [inspirationBoardList] = useAtom(inspirationBoardListAtom);
	const getInspirationPromt = useAction(getInspirationPromptAction);

	const images = inspirationBoardList.map((image) => ({
		src: image.url,
		width: image.coordinates.w,
		height: image.coordinates.h,
		alt: image.id,
		customOverlay: <DraggableOverlay />
	}));

	useEffect(() => {
		if (id) {
			getInspirationPromt(id);
		}
	}, [id]);

	return (
		<Flex vertical className="inspiration-board gap-s">
			<DraggableBoard isLoading={isLoadingBoard || isLoadingPrompt}>
				{inspirationBoardList.length === 0
					? (
						<Flex style={{ paddingBottom: 24 }} vertical className="gap-xs full-height full-width" align="center">
							<img
								className="inspiration-board__image"
								src={emptyInspirationBoard}
								alt="inspiration board empty"
							/>
							<Flex vertical className="text-center gap-xxs">
								<Typography.Text className={cn(TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextSecondary)}>
									Inspiration board is empty
								</Typography.Text>
								<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
									Start to fill the brief and we will display images
								</Typography.Text>
							</Flex>
							<Button onClick={() => toggleAddBriefModal(true)}>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add Brief</Typography.Text>
							</Button>
						</Flex>
					)
					: (
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>The images were generated based on your script. Drag the pictures you like onto the moodboard.</Typography.Text>
					)}
				<Gallery
					margin={3}
					rowHeight={165}
					images={images}
					// @ts-ignore
					thumbnailImageComponent={(props) => <DraggableImage draggableImageId={DRAGGABLE_IMAGE_ID} {...props} />}
					enableImageSelection={false}
				/>
			</DraggableBoard>
			<AddBriefMoodboard isOpen={isOpenAddBriefnModal} toggleModal={toggleAddBriefModal} />
		</Flex>
	);
};

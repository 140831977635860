import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import { FC, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { EmptyDescription } from "@/features/empty-description";
import { HighlightScript } from "@/features/highlight-script";
import { RightSidebarScript } from "@/features/right-sidebar-script";
import {
	DescriptionBottomActions,
	descriptionStatusAtom,
	getSceneListAction,
	NextChoiceModal
} from "@/entities/script";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { Spinner } from "@/shared/ui";
// import border from "@/assets/description/border.svg";

import "./Script.scss";

export const Script: FC = () => {
	const [isOpen, toggleModal] = useOpenModal();
	const [descriptionStatus] = useAtom(descriptionStatusAtom);
	const initScript = useAction(getSceneListAction);
	const [status] = useAtom(getSceneListAction.statusesAtom);

	const { id } = useParams();
	const navigate = useNavigate();

	const onMoodBoard = () => {
		navigate(`/storyboard/${id}/moodboard`);
	};

	const onStoryBoard = () => {
		navigate(`/storyboard/${id}/storyboard`);
	};

	useEffect(() => {
		if (id) {
			initScript(id);
		}
	}, []);

	return (
		<Flex className="full-height">
			<Flex vertical className="gap-m full-height description flex-1">
				<Flex vertical className="gap-xxs">
					<Flex align="center" className="gap-4">
						{/* <img src={border} alt="highlight" /> */}
						<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>Script</Typography.Text>
					</Flex>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Select scenes and shots, and write descriptions and dialogue directly within the interface.</Typography.Text>
				</Flex>
				{status?.isPending
					? (
						<Flex className="full-height full-height" align="center" justify="center">
							<Spinner />
						</Flex>
					)
					: (
						<div className="full-height description__content">
							{descriptionStatus === "full" && <HighlightScript />}
							{descriptionStatus === "empty" && <EmptyDescription />}
						</div>
					)}
				<DescriptionBottomActions onNext={() => toggleModal(true)} />
			</Flex>
			{descriptionStatus === "full" && (
				<>
					<RightSidebarScript />
					<NextChoiceModal
						isOpen={isOpen}
						toggleModal={toggleModal}
						onMoodBoard={onMoodBoard}
						onStoryBoard={onStoryBoard}
					/>
				</>
			)}
		</Flex>
	);
};

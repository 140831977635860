import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import passwordChanged from "@/assets/plan/password-changed.svg";

import "./SuccessPasswordChanged.scss";

export const SuccessPasswordChanged: FC = () => {
	const navigate = useNavigate();

	const redirectToLogin = () => {
		navigate("/login");
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper title={""} questionText={""} actionText={""} action={() => {}}>
				<Flex
					vertical
					align="center"
					justify="center"
					className="success-password-changed gap-s"
				>
					<img src={passwordChanged} alt="success password changed" className="success-password-changed__img" />
					<Flex vertical align="center" justify="center" className="gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>Password was changed</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>Your password was successful changed. You can now proceed to login to your account.</Typography.Text>
					</Flex>
					<Button className="full-width" onClick={redirectToLogin} type="primary">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Go to Log in</Typography.Text>
					</Button>
				</Flex>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import linkedin from "@/assets/shared/linkedin.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import "./CardTeam.scss";

interface ICard {
	fullName: string;
	positionName: string;
	description: string;
	image: string;
	placeholderImage: string;
	href: string;
}

export const CardTeam: FC<ICard> = ({ fullName, positionName, description, image, placeholderImage, href }) => {
	const { isTablet, isMobileS, isMobileL, isLaptopS } = useMediaQueries();

	return (
		<Flex gap="large" vertical className="card-team">
			<Flex gap="middle">
				<LazyLoadImage
					src={image}
					placeholderSrc={placeholderImage}
					className="card-team__image"
					alt={fullName}
					width={isLaptopS ? 122 : 185}
					height={isLaptopS ? 166 : 252}
				/>
				<Flex className="card-team__content" vertical justify={isMobileL ? "flex-end" : "space-between"}>
					<Flex gap="small" vertical>
						<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH2, {
							[TEXT_CLASSNAMES.HeadlineH3]: isTablet,
							[TEXT_CLASSNAMES.HeadlineH4]: isMobileS
						})}>
							{fullName}
						</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.SmRegular]: isTablet,
							[TEXT_CLASSNAMES.XsRegular]: isMobileS
						})}>
							{positionName}
						</Typography.Text>
						<a
							className="card-team__link flex-ctr-ctr"
							target="_blank"
							href={href}
						>
							<img src={linkedin} alt="Linkedin" />
						</a>
					</Flex>
					{!isMobileL && (
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.XsRegular]: isTablet
						})}>
							{description}
						</Typography.Text>
					)}
				</Flex>
			</Flex>
			{isMobileL && !isMobileS && (
				<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
					[TEXT_CLASSNAMES.XsRegular]: isTablet
				})}>
					{description}
				</Typography.Text>
			)}
		</Flex>
	);
};

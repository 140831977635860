import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getNextShotId, getPrevShotId, getGeneratedShotsOrderAction } from "@/entities/shot-edit";
import { getShotAction, regenerateImageAction, updateRegenerateImageAction } from "@/entities/storyboard";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { SidebarNavigation } from "@/shared/ui";
import { ShotDetailsInfo } from "./shot-details-info";

export const ShotDetails: FC = () => {
	const regenerateImage = useAction(regenerateImageAction);
	const updateRegenerateImage = useAction(updateRegenerateImageAction);

	const [shot] = useAtom(getShotAction.dataAtom);
	const [regenerateImageStatus] = useAtom(regenerateImageAction.statusesAtom);
	const [generatedShotsOrder] = useAtom(getGeneratedShotsOrderAction.dataAtom);
	const { id, sceneId, shotId } = useParams();
	const navigate = useNavigate();
	const currentShot = generatedShotsOrder.find((shot) => shot.shot_id === shotId);
	const onNext = () => {
		const list = generatedShotsOrder.map((shot) => ({ id: shot.shot_id }));
		const nextId = getNextShotId(list, shotId);

		navigate(`/storyboard/${id}/shot-selection/${sceneId}/${nextId}`);
	};

	const onPrev = () => {
		const list = generatedShotsOrder.map((shot) => ({ id: shot.shot_id }));
		const prevId = getPrevShotId(list, shotId);

		navigate(`/storyboard/${id}/shot-selection/${sceneId}/${prevId}`);
	};

	if (!currentShot) {
		return null;
	}

	const onRegenerate = async () => {
		if (shotId && id && sceneId) {
			updateRegenerateImage({ shotId, loading: true, image: null, error: false });
			await regenerateImage({ shotId, projectId: id });
			updateRegenerateImage({ shotId, loading: false, image: shot?.image_url_compressed ?? null, error: false });
		}
	};

	return (
		<Flex vertical justify="space-between" className="full-height full-width">
			<Flex style={{ overflowY: "scroll" }} vertical justify="space-between" className="full-width gap-xs">
				<Flex vertical className="gap-xs">
					<SidebarNavigation
						title={`Scene ${currentShot.scene_ix + 1}. Shot ${currentShot.shot_ix + 1}`}
						onNext={onNext}
						onPrev={onPrev}
					/>
					<ShotDetailsInfo />
				</Flex>
			</Flex>
			<Flex style={{ paddingTop: 8 }} vertical className="gap-xs">
				<Button disabled={regenerateImageStatus.isPending} type="primary" className="flex-ctr-ctr" onClick={onRegenerate}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Regenerate</Typography.Text>
				</Button>
				{/* <Button> */}
				{/*	<Typography.Text className={TEXT_CLASSNAMES.XsRegular}> */}
				{/*		Show in the script */}
				{/*	</Typography.Text> */}
				{/* </Button> */}
			</Flex>
		</Flex>
	);
};

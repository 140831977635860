import { base64FileHeaderMapper } from "./getImageExtFromBase64.ts";

export const blobToBase64 = function (blob: Blob, callback: (base64: string, blob: Blob) => void) {
	const reader = new FileReader();
	reader.onload = function () {
		const dataUrl = reader.result;
		// @ts-ignore
		const base64 = dataUrl?.split(",")[1];
		callback(base64, blob);
	};
	reader.readAsDataURL(blob);
};

export const downloadImage = (imageUrl: string, imageName: string) =>
	fetch(imageUrl)
		.then(res => res.blob())
		.then((res) => blobToBase64(res, (base64, blob) => {
			const fileType = base64FileHeaderMapper(base64 ?? "");
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = `${imageName}.${fileType.toLowerCase()}`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}));

import { action, atom } from "@reatom/core";
import { withReset } from "@reatom/lens";
import { TNewProject } from "@/entities/projects";

export type TStep = "1" | "2" | "3";
export const stepAtom = atom<TStep>("1", "stepAtom").pipe(withReset());
export const newProjectAtom = atom<TNewProject>({
	name: null,
	briefing: null,
	script: null,
	country: null,
	city: null
}, "newProjectAtom").pipe(withReset());

export const resetDataAction = action((ctx) => {
	stepAtom.reset(ctx);
	newProjectAtom.reset(ctx);
});

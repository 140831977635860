import { useAction, useAtom } from "@reatom/npm-react";
import { FC, useEffect } from "react";

import { getAccountAction } from "@/entities/account";
import { SettingsForm } from "./SettingsForm";
import { SettingsProfileSkeleton } from "./SettingsProfileSkeleton";

export const SettingsProfile: FC = () => {
	const [status] = useAtom(getAccountAction.statusesAtom);
	const getSettingsProfile = useAction(getAccountAction);

	useEffect(() => {
		getSettingsProfile(true);
	}, []);

	if (status.isPending) {
		return <SettingsProfileSkeleton />;
	}

	return <SettingsForm />;
};

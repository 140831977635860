import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import error404 from "@/assets/shared/error_404.png";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

export const Error404: FC = () => {
	const navigate = useNavigate();

	return (
		<Flex style={{
			height: "100vh"
		}} vertical align="center" justify="center" className="gap-xs">
			<img style={{
				maxWidth: "690px",
				maxHeight: "364px"
			}} className="image-contain" src={error404} alt="error 404"/>
			<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH1}>
				New shot?
			</Typography.Text>
			<Typography.Text className={TEXT_CLASSNAMES.LgRegular}>
				Sorry, the page you visited does not exist.
			</Typography.Text>
			<Button onClick={() => navigate("/")} type="primary">
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Back Home
				</Typography.Text>
			</Button>
		</Flex>
	);
};

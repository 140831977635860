import { useAtom } from "@reatom/npm-react";
import { Avatar, Flex, Tag, Typography } from "antd";

import cn from "classnames";
import { FC } from "react";

import { getShotAction } from "@/entities/storyboard";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { toCharacter } from "@/shared/methods/toCharacter";

import { ShotCameraAngleEdit } from "./ShotCameraAngleEdit";
import { ShotCameraMovementEdit } from "./ShotCameraMovementEdit.tsx";
import { ShotDescriptionEdit } from "./ShotDescriptionEdit.tsx";
import { ShotDialogueEdit } from "./ShotDialogueEdit.tsx";
import { ShotPropsEdit } from "./ShotPropsEdit.tsx";
import timeImg from "@/assets/storyboard/time.svg";

import "./ShotDetailsInfo.scss";

export const ShotDetailsInfo: FC = () => {
	const [shot] = useAtom(getShotAction.dataAtom);

	return (
		<Flex vertical className="shot-details-info">
			<Flex align="center" justify="space-between" className="shot-details-info__row gap-xxs">
				<Flex vertical className="gap-xxs">
					<Typography.Text
						className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Location</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						{shot?.location}
					</Typography.Text>
				</Flex>
				<Flex>
					<Tag className="shot-details-info__tag flex-ctr-ctr gap-4">
						<img src={timeImg} className="image-contain" alt="time"/>
						{shot?.time} sec
					</Tag>
				</Flex>
			</Flex>

			<Flex wrap="wrap" className="shot-details-info__row gap-xxs">
				{shot?.characteristics.map((tag) => (
					<Tag className="shot-details-info__tag" key={tag}>{tag}</Tag>
				))}
			</Flex>
			<ShotDescriptionEdit prompt={shot?.prompt} description={shot?.description} />
			<ShotDialogueEdit dialogue={shot?.dialog}/>
			<ShotPropsEdit props={shot?.props} />
			<ShotCameraAngleEdit cameraAngle={shot?.camera_angle} />
			<ShotCameraMovementEdit cameraMovement={shot?.camera_movement} />
			{!!shot?.characters.length && (
				<>
					<div className="shot-details-info__divider"/>
					<Flex wrap="wrap" className="shot-details-info__row gap-xs">
						{shot.characters.map((character, index) => (
							<Avatar key={index.toString(36)} size={46} icon={<img src={toCharacter(character.character_key, "2")} alt="character"/>}/>
						))}
					</Flex>
				</>
			)}
		</Flex>
	);
};

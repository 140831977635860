import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { Plan } from "@/pages/plan/lib";
import { callErrorAction } from "@/entities/notification";
import {
	getPossibleSubscriptionsResource
	// NewSubscription,
	// OldSubscription,
	// SubscribeCustomer,
	// subscribeCustomerResource
} from "@/shared/api/payment";
import { centsToDollars } from "@/shared/methods";

export const getPossibleSubscriptionsAction = reatomAsync(() => getPossibleSubscriptionsResource())
	.pipe(
		withStatusesAtom(),
		withDataAtom([], (_ctx, res) => {
			const subs = Object.keys(res.data);
			const plans: Plan[] = subs.reduce((acc, sub) => {
				const el = res.data[sub];
				const prices = el.prices.map((price) => ({
					...price,
					unit_amount: centsToDollars(price.unit_amount),
					users: el.num_users,
					watermark: el.watermarks,
					projects: el.num_projects,
					id: sub
				}));

				return [...acc, ...prices];
			}, [] as Plan[]);

			return plans;
		}),
		withErrorAtom((ctx, err) => {
			callErrorAction(ctx, err, true);
		})
	);

import { reatomAsync, withAbort, withStatusesAtom, withErrorAtom, withDataAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { callErrorAction } from "@/entities/notification";
import { getScriptLength, sortScenes } from "@/entities/script";
import {
	convertShot,
	GenerateImage,
	GenerateImageParams,
	REGENERATE_STORYBOARD_IMAGES,
	toScenes
} from "@/entities/storyboard";
import { getHighlightTheShotsResource } from "@/shared/api/script";
import {
	GetShotParams,
	getStoryboardResource,
	getStoryboardShotResource,
	RegenerateImageParams,
	regenerateImageResource,
	UpdateSceneParams,
	updateStoryboardSceneResource,
	updateStoryboardShotResource
} from "@/shared/api/storyboard";
import { getProjectId } from "@/shared/methods/getProjectId.ts";

export const storyboardEstimatedTimeAtom = atom<null | string>(null);

export const getStoryboardAbortController = atom<AbortController>(new AbortController(), "getStoryboardAbortController");

export const generateImageAtom = atom<GenerateImage>({}, "generateImageAtom");
export const regenerateImageAtom = atom<GenerateImage>({}, "regenerateImageAtom");

export const updateGenerateImageAction = action((ctx, params: GenerateImageParams) => {
	const generateImage = ctx.get(generateImageAtom);

	generateImageAtom(ctx, {
		...generateImage,
		[params.shotId]: {
			pending: params.loading,
			image: params.image === null ? generateImage[params.shotId]?.image : params.image,
			error: params.error
		}
	});
});

export const updateRegenerateImageAction = action((ctx, params: GenerateImageParams) => {
	const regenerateImage = ctx.get(regenerateImageAtom);

	regenerateImageAtom(ctx, {
		...regenerateImage,
		[params.shotId]: {
			pending: params.loading,
			image: params.image === null ? regenerateImage[params.shotId]?.image : params.image,
			error: params.error
		}
	});
});

export const getShotAction = reatomAsync(
	(ctx, params: GetShotParams) => getStoryboardShotResource(params, ctx.controller)
).pipe(
	withAbort(),
	withStatusesAtom(),
	withDataAtom(null, (_ctx, res) => res.data),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

export const regenerateImageAction = reatomAsync(async (ctx, params: RegenerateImageParams) => {
	await regenerateImageResource(params, ctx.controller);

	return params;
}, {
	onFulfill: (ctx, data) => {
		getShotAction(ctx, { shotId: data.shotId, projectId: data.projectId });
		regeneratedImagesAtom(ctx, { ...ctx.get(regeneratedImagesAtom), [data.shotId]: true });
	}
}).pipe(
	withAbort(),
	withStatusesAtom(),
	withErrorAtom()
);

export const regeneratedImagesAtom = atom<{ [shotId: string]: boolean }>({}, "regeneratedImages").pipe(withLocalStorage(REGENERATE_STORYBOARD_IMAGES));

export const getStoryboardAction = reatomAsync((ctx, projectId: string) => {
	getHighlightTheShotsResource(projectId, new AbortController()).then(({ data }) => {
		const orders = data.scenes_order;
		const scenes = data.scenes_info;
		const sceneList = sortScenes(scenes, orders);
		const length = getScriptLength(sceneList);

		const maxTokens = Math.ceil(length / 1000);
		const time = maxTokens * 20;
		const minutes = Math.floor(time / 60);
		const seconds = time - minutes * 60;
		const timeString = `${minutes}m ${seconds}s`;

		storyboardEstimatedTimeAtom(ctx, timeString);
	});
	const controller = ctx.get(getStoryboardAbortController);
	return getStoryboardResource(projectId, controller);
}, "getStoryboardAction").pipe(
	withStatusesAtom(),
	withDataAtom([], (ctx, res) => {
		const regeneratedImages = ctx.get(regeneratedImagesAtom);

		const scenes = toScenes(
			res.data.scenes_info,
			res.data.scenes_order,
			regeneratedImages,
			res.data.city,
			res.data.country
		);

		return scenes;
	}),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

export const updateSceneLocationAction = action(async (ctx, params: UpdateSceneParams) => {
	try {
		const projectId = getProjectId();

		const sceneList = ctx.get(getStoryboardAction.dataAtom);
		const scene = sceneList.find((el) => el.id === params.scene_info.scene_id);
		const shots = scene?.shots.map((shot) =>
			convertShot(
				scene.id,
				params.scene_info.shots_order,
				{
					...shot,
					location: params.scene_info.selected_location
				}
			)
		);

		await updateStoryboardSceneResource(projectId, params);

		if (shots?.length) {
			await Promise.all(shots.map((shot) => updateStoryboardShotResource(projectId, shot)));
		}

		const newSceneList = sceneList.map((scene) => ({
			...scene,
			shots: scene.shots.map((shot) => ({
				...shot,
				location: params.scene_info.selected_location
			}))
		}));

		getStoryboardAction.dataAtom(ctx, newSceneList);
	} catch (e) {
		callErrorAction(ctx, e);
	}
});

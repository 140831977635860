import { Flex, Form, Input, Typography } from "antd";
import { AxiosError } from "axios";
import cn from "classnames";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BackgroundPreLogin, LoginWrapper, RecoverPasswordWrapper, RESET_PASSWORD_KEY } from "@/entities/viewer";
import { resetPasswordResource, TRegisterKey } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { REG_EXP } from "@/shared/const/reg-exp";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useLocalStorage } from "@/shared/hooks";
import { validatePassword } from "@/shared/validation";
import { Chip } from "./Chip";

type DataType = {
	password: string;
	confirmPassword: string;
}

export const CreateNewPassword: FC = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [resetPasswordKey] = useLocalStorage<TRegisterKey | null>(RESET_PASSWORD_KEY, null);
	const [error, setError] = useState<null | string>(null);

	const password = Form.useWatch("password", { form, preserve: true });

	const onFinish = async (data: DataType) => {
		try {
			if (resetPasswordKey) {
				await resetPasswordResource(data.password, resetPasswordKey?.key);
				navigate("/password-success-changed");
			}
		} catch (e) {
			const err = e as AxiosError;

			// @ts-ignore
			setError(err.response?.data?.message ?? "Something went wrong. Please try again");
		}
	};

	const validatedPassword = validatePassword(password);

	return (
		<BackgroundPreLogin>
			<LoginWrapper title={""} questionText={""} actionText={""} action={() => {}}>
				<Form form={form} onFinish={onFinish}>
					<RecoverPasswordWrapper
						title="Create a new password"
						description="Your new password must be different from the previous password you used"
						form={form}
						buttonText="Change password"
					>
						<Form.Item
							name="password"
							rules={[
								{ required: true, message: "Please input your password!" },
								{ pattern: REG_EXP.password, message: " " }
							]}
						>
							<Input.Password placeholder="Password" />
						</Form.Item>
						<Form.Item
							name="confirmPassword"
							dependencies={["password"]}
							rules={[
								{ required: true, message: "Please input your confirm password!" },
								{ pattern: REG_EXP.password, message: " " },
								({ getFieldValue }) => ({
									validator (_, value) {
										if (!value || getFieldValue("password") === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("The new password that you entered do not match!"));
									}
								})
							]}
						>
							<Input.Password placeholder="Confirm Password" />
						</Form.Item>
						<Flex wrap="wrap" className="gap-xxs">
							<Chip title="at least 8 characters" isSuccess={validatedPassword.eightCharacters} />
							<Chip title="1 number" isSuccess={validatedPassword.oneNumber} />
							<Chip title="1 lowercase letter" isSuccess={validatedPassword.lowerCaseLetter} />
							<Chip title="1 capital letter" isSuccess={validatedPassword.upperCaseLetter} />
							<Chip title="1 special symbol" isSuccess={validatedPassword.specialSymbol} />
						</Flex>
						{error && <Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.HeartRed400)}>{error}</Typography.Text>}
					</RecoverPasswordWrapper>
				</Form>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

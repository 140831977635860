import { FC, SVGProps } from "react";

export const TeamSVG: FC<SVGProps<unknown>> = ({ fill }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
		<g clipPath="url(#clip0_4044_3138)">
			<path d="M5 7.12122C5 8.18208 5.42143 9.1995 6.17157 9.94964C6.92172 10.6998 7.93913 11.1212 9 11.1212C10.0609 11.1212 11.0783 10.6998 11.8284 9.94964C12.5786 9.1995 13 8.18208 13 7.12122C13 6.06035 12.5786 5.04293 11.8284 4.29279C11.0783 3.54264 10.0609 3.12122 9 3.12122C7.93913 3.12122 6.92172 3.54264 6.17157 4.29279C5.42143 5.04293 5 6.06035 5 7.12122Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3 21.1212V19.1212C3 18.0603 3.42143 17.0429 4.17157 16.2928C4.92172 15.5426 5.93913 15.1212 7 15.1212H11C12.0609 15.1212 13.0783 15.5426 13.8284 16.2928C14.5786 17.0429 15 18.0603 15 19.1212V21.1212" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M16 3.25122C16.8604 3.47152 17.623 3.97192 18.1676 4.67353C18.7122 5.37514 19.0078 6.23805 19.0078 7.12622C19.0078 8.01439 18.7122 8.8773 18.1676 9.57891C17.623 10.2805 16.8604 10.7809 16 11.0012" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M21 21.1212V19.1212C20.9949 18.2384 20.6979 17.382 20.1553 16.6856C19.6126 15.9892 18.8548 15.4919 18 15.2712" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_4044_3138">
				<rect width="24" height="24" fill="white" transform="translate(0 0.121216)"/>
			</clipPath>
		</defs>
	</svg>
);

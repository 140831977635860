import { useAction } from "@reatom/npm-react";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { FC, MouseEvent, MutableRefObject, useEffect, useRef } from "react";
import { Group, Image } from "react-konva";
import { useParams } from "react-router-dom";
import {
	getPosition,
	getSize,
	toBorder
} from "@/features/full-moodboard";
import {
	addGroupButtonAction,
	changeMoodboardButtonPositionAction,
	toggleMoodboardButtonActiveAction,
	updateMoodboardImagesPositionAction
} from "@/entities/moodboard";
import { TMoodBoardImageCoordinate } from "@/shared/api/moodboard";
import { gcd } from "@/shared/methods";

type Props = {
	x: number;
	y: number;
	width: number;
	height: number;
	onSelect: (event: KonvaEventObject<MouseEvent>) => void;
	image: HTMLImageElement;
	transformerRef: MutableRefObject<null | Konva.Transformer>;
	parentWidth: number;
	parentHeight: number;
	id: string;
}

export const CustomKonvaImage: FC<Props> = ({ x, y, width, parentWidth, parentHeight, height, image, onSelect, transformerRef, id }) => {
	const params = useParams();

	const imageRef = useRef<null | Konva.Image>(null);
	const groupRef = useRef<null | Konva.Group>(null);

	const updateLayoutPosition = useAction(updateMoodboardImagesPositionAction);
	const changeButtonPosition = useAction(changeMoodboardButtonPositionAction);
	const toggleButtonActive = useAction(toggleMoodboardButtonActiveAction);
	const addGroup = useAction(addGroupButtonAction);

	const updateImageCoordinates = () => {
		const { width, height } = getSize(imageRef);
		const { x, y } = getPosition(transformerRef);
		const divisor = gcd(width, height);

		const imageCoordinates: TMoodBoardImageCoordinate = {
			x,
			y,
			w: width,
			h: height,
			minH: Math.round(height / divisor),
			minW: Math.round(width / divisor)
		};
		changeButtonPosition({ x, y, name: id, isLocked: false, isActive: false, group: groupRef.current, width });

		// @ts-ignore
		if (params.id) {
			updateLayoutPosition(params.id, imageCoordinates, id);
		}

		toggleButtonActive(id);
	};

	useEffect(() => {
		addGroup(id, groupRef.current);
	}, []);

	return (
		<Group
			ref={groupRef}
			height={height}
			width={width}
			x={x}
			y={y}
			name={id}
			draggable
			onDragMove={() => toBorder(transformerRef, parentWidth, parentHeight)}
			onDragStart={() => toggleButtonActive(id, false)}
			onDragEnd={updateImageCoordinates}
			onMouseEnter={() => toggleButtonActive(id)}
		>
			<Image
				onTransformEnd={updateImageCoordinates}
				onTransformStart={() => toggleButtonActive(id, false)}
				image={image}
				// @ts-ignore
				onMouseOver={onSelect}
				ref={imageRef}
				draggable={false}
				width={width}
				height={height}
				cornerRadius={8}
				zIndex={5}
			/>
		</Group>
	);
};

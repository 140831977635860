import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Input, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackgroundPreLogin, LoginWrapper, viewerRegisterAtom } from "@/entities/viewer";
import {
	applyPromoCodeResource,
	checkPromoCodeResource,
	getPaymentCredentialsResource
} from "@/shared/api/payment";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { PAYMENT_ERRORS_PLAN_REDIRECT } from "@/shared/const/payment.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

export const PromoCode: FC = () => {
	const navigate = useNavigate();
	const { isMobileL } = useMediaQueries();
	const { termType } = useParams();

	const [promoCode, setPromoCode] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const [viewer] = useAtom(viewerRegisterAtom);

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const { data: credentials } = await getPaymentCredentialsResource(viewer?.key ?? "");

				if (credentials === null || PAYMENT_ERRORS_PLAN_REDIRECT.includes(credentials?.message ?? "")) {
					navigate("/plan");
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};

		init();
	}, []);

	const onApply = async () => {
		try {
			setLoading(true);
			const { data: checked } = await checkPromoCodeResource(viewer?.key ?? "", promoCode, termType ?? "");

			if (checked.code) {
				await applyPromoCodeResource(viewer?.key ?? "", checked.code);

				if (checked.percent_off === 100) {
					navigate("/payment-success");
				} else {
					navigate("/payment");
				}
			} else {
				setError("The promo code you entered is invalid. Please check the code and try again.");
			}
		} catch (e) {
			setError("The promo code you entered is invalid. Please check the code and try again.");
		} finally {
			setLoading(false);
		}
	};

	const onSkip = () => {
		navigate("/payment");
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper isChildrenFullHeight title="" questionText="" actionText="" action={() => {}}>
				<Flex align="center" className="full-width full-height">
					<Flex vertical gap={16}>
						<Flex vertical className="full-width gap-xs">
							<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH4, {
								[TEXT_CLASSNAMES.XsSemibold]: isMobileL
							})}>
								Make sure to apply your promo code now!
							</Typography.Text>
							<Flex className="gap-xs">
								<Input
									onChange={(event) => setPromoCode(event.target.value)}
									placeholder="Enter code here"
									disabled={loading}
								/>
							</Flex>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.CrazyOrange500)}>{error}</Typography.Text>
						</Flex>
						<Flex className="full-width" gap={8}>
							<Button
								loading={loading}
								className="full-width"
								onClick={onApply}
								type="primary"
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Apply</Typography.Text>
							</Button>
							<Button
								loading={loading}
								className="full-width"
								onClick={onSkip}
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Skip</Typography.Text>
							</Button>
						</Flex>
					</Flex>
				</Flex>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";
import { TMoodBoardStatus } from "@/entities/moodboard";
import { callErrorAction } from "@/entities/notification";
import { getMoodboardsResource, TMoodboardImage } from "@/shared/api/moodboard";
import { getImgSize } from "@/shared/methods";

export const archiveBoardListAtom = atom<TMoodboardImage[]>([], "archiveBoardListAtom");

export const archiveBoardLoadingAtom = atom<boolean>(false, "archiveBoardLoadingAtom");

export const archiveBoardStatusAtom = atom<TMoodBoardStatus>((ctx) => {
	const list = ctx.spy(archiveBoardListAtom);

	return list.length > 0 ? "full" : "empty";
}, "archiveBoardStatusAtom");

export const getArchiveBoardAction = action(async (ctx, id) => {
	try {
		archiveBoardLoadingAtom(ctx, true);
		const { data } = await getMoodboardsResource(id);

		const imagesPromises: Promise<TMoodboardImage>[] = data.images.filter((img) => img.archived).map(async (image) => {
			const { width, height } = await getImgSize(image.url);

			if (image.coordinates.w === 0 || image.coordinates.h === 0) {
				image.coordinates.w = width;
				image.coordinates.h = height;
				image.coordinates.minW = width;
				image.coordinates.minH = height;
			}

			return image;
		});

		const images = await Promise.all(imagesPromises);

		archiveBoardListAtom(ctx, images);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError, true);
	} finally {
		archiveBoardLoadingAtom(ctx, false);
	}
});

export const removeFromArchiveBoardAction = action((ctx, id: string) => {
	archiveBoardListAtom(ctx, ctx.get(archiveBoardListAtom).filter((item) => item.id !== id));
});

export const addToArchiveBoardAction = action((ctx, image: TMoodboardImage) => {
	archiveBoardListAtom(ctx, [...ctx.get(archiveBoardListAtom), image]);
});

import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import comingSoon from "@/assets/team/coming-soon.svg";

export const ComingSoonTeam: FC = () => {
	return (
		<Flex align="center" justify="center" vertical className="gap-s full-width full-height">
			<img width={200} height={200} src={comingSoon} alt="coming soon" />
			<Flex vertical className="gap-xxs text-center">
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>Your Team is empty</Typography.Text>
				<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Your existing subscription plan <br/> does not allow collaborators.</Typography.Text>
			</Flex>
			<Button>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Coming  Soon</Typography.Text>
			</Button>
		</Flex>
	);
};

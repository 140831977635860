import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC } from "react";

import { initProjectsAction, YourStoryboard } from "@/entities/projects";

import "./FullProject.scss";

export const FullProject: FC = () => {
	const [projects] = useAtom(initProjectsAction.dataAtom);

	return (
		<Flex wrap="wrap" className="full-project gap-s">
			{projects?.map(({ name, date, img }) => (
				<YourStoryboard key={name} name={name} date={date} img={img} />
			))}
		</Flex>
	);
};

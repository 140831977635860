import { action, atom } from "@reatom/core";
import { callErrorAction } from "@/entities/notification";
import { checkPromoCodeResource, PromoSuccess } from "@/shared/api/payment";

export const promoCodeAtom = atom<null | PromoSuccess>(null, "promoCodeAtom");
export const promoErrorAtom = atom<null | string>(null, "promoErrorAtom");

export const getPromoCodeAction = action(async (ctx, token: string, code: string, subscriptionId: string) => {
	try {
		const { data } = await checkPromoCodeResource(token, code, subscriptionId);
		if (data.code) {
			promoErrorAtom(ctx, null);
			promoCodeAtom(ctx, data as PromoSuccess);
			return data;
		} else {
			promoErrorAtom(ctx, "The promo code you entered is invalid. Please check the code and try again.");
			promoCodeAtom(ctx, null);
		}
	} catch (e) {
		callErrorAction(ctx, e);
	}
});

import { useAction, useAtom } from "@reatom/npm-react";
import { useSize } from "ahooks";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PlanParams } from "@/pages/plan/lib";
import { getPossibleSubscriptionsAction } from "@/pages/plan/model";
import { ComingSoonPlanType } from "@/pages/plan/ui/ComingSoonPlanType.tsx";
import { EnterprisePlanType } from "@/pages/plan/ui/EnterprisePlanType.tsx";
import { SubPlans } from "@/pages/plan/ui/SubPlans.tsx";
import { callErrorAction } from "@/entities/notification";
import { promoCodeAtom, PromotionalCode } from "@/entities/plan";
import {
	BackgroundPreLogin,
	LoginWrapper,
	logoutAction,
	viewerRegisterAtom
} from "@/entities/viewer";
import { subscribeCustomerResource } from "@/shared/api/payment";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { Spinner } from "@/shared/ui";

import "./Plan.scss";

export const Plan = () => {
	const { isMobileL, isLaptopM } = useMediaQueries();
	const navigate = useNavigate();
	const size = useSize(() => document.querySelector(".login-wrapper"));
	const callError = useAction(callErrorAction);

	const logout = useAction(logoutAction);
	const getPossibleSubscriptions = useAction(getPossibleSubscriptionsAction);

	const [possibleSubscriptions] = useAtom(getPossibleSubscriptionsAction.statusesAtom);
	const [promoCode] = useAtom(promoCodeAtom);
	const [viewer] = useAtom(viewerRegisterAtom);

	const [planParams, setPlanParams] = useState<PlanParams | null>(null);
	const [loading, setLoading] = useState(false);

	const startUsing = async () => {
		try {
			setLoading(true);
			if (planParams) {
				const { data } = await subscribeCustomerResource({
					interval: planParams.interval,
					token: viewer?.key ?? "",
					promoCode: promoCode?.code ?? "",
					productId: Number(planParams.termType)
				});

				if (promoCode) {
					if (promoCode.percent_off === 100) {
						if (data?.message === "Already subscribed") {
							navigate(`/promo-code/${planParams.termType}`);
						} else {
							navigate("/payment-success");
						}
					} else {
						navigate("/payment");
					}
				} else {
					navigate(`/promo-code/${planParams.termType}`);
				}
			}
		} catch (e) {
			callError(e);
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	const onChange = (params: PlanParams) => {
		setPlanParams(params);
	};

	useEffect(() => {
		getPossibleSubscriptions();
	}, []);

	const padding = isMobileL ? 32 : 120;

	return (
		<BackgroundPreLogin>
			<LoginWrapper
				title=""
				questionText=""
				actionText=""
				action={() => {}}
				isChildrenFullHeight
			>
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", overflowY: "scroll", width: "100%", height: "100%", maxHeight: (size?.height ?? 0) - padding }}>
					<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH2, {
						[TEXT_CLASSNAMES.LPHeadlineH3]: isLaptopM,
						[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileL
					})}>Choose your plan</Typography.Text>
					<div style={{ height: 16 }} />
					<PromotionalCode subscriptionId={planParams?.termType} />
					<Flex style={{ marginTop: 24 }} vertical className="gap-xs">
						{possibleSubscriptions.isPending
							? <Spinner />
							: <SubPlans activePlanParams={planParams} onChange={onChange} />
						}
						<ComingSoonPlanType />
						<EnterprisePlanType />
					</Flex>
					<Flex vertical={isMobileL} className="gap-xs" style={{ marginTop: 24 }}>
						<Button
							className="full-width"
							disabled={!planParams}
							type="primary"
							onClick={startUsing}
							loading={loading}
						>
							Continue
						</Button>
						<Button
							className="full-width"
							onClick={logout}
							loading={loading}
						>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Sign out</Typography.Text>
						</Button>
					</Flex>
				</div>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC, useEffect } from "react";

import { AddNewProject } from "@/features/add-new-project";
import { EmptyProject } from "@/features/empty-project";
import { FullProject } from "@/features/full-project";
import { initProjectsAction } from "@/entities/projects";

import plus from "@/assets/shared/plus_square.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { Spinner } from "@/shared/ui";

import "./Projects.scss";

export const Projects: FC = () => {
	const getAllProjects = useAction(initProjectsAction);
	const [statuses] = useAtom(initProjectsAction.statusesAtom);
	const [projects] = useAtom(initProjectsAction.dataAtom);
	const [isOpen, toggleModal] = useOpenModal();

	const isProject = !!projects.length;

	useEffect(() => {
		getAllProjects();
	}, []);

	return (
		<Flex vertical className="projects gap-s full-height">
			<Flex align="center" justify="space-between" className="full-width">
				<Flex align="center" className="gap-4">
					{/* <ProjectsSVG className="projects__img" fill={COLOR_NAMES.TextPrimary} /> */}
					<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>
						Your projects
					</Typography.Text>
				</Flex>
				{isProject && (
					<Button
						onClick={() => toggleModal(true)}
						className="projects__button"
						icon={<img src={plus} alt="plus" />}
						type="primary"
					>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>New project</Typography.Text>
					</Button>
				)}
			</Flex>
			{statuses.isPending
				? (
					<Spinner isOverlay />
				)
				: (
					<>
						{isProject
							? <FullProject />
							: <EmptyProject />}
					</>
				)}
			<AddNewProject isOpen={isOpen} toggleModal={toggleModal} />
		</Flex>
	);
};

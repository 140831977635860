import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, PropsWithChildren, useEffect } from "react";

import { initViewerPermissionAction, viewerAtom } from "@/entities/viewer";
import { Spinner } from "@/shared/ui";

export const PermissionWrapper: FC<PropsWithChildren> = ({ children }) => {
	const initViewerPermission = useAction(initViewerPermissionAction);
	const [status] = useAtom(initViewerPermissionAction.statusesAtom);
	const [viewer] = useAtom(viewerAtom);

	useEffect(() => {
		const isAuth = viewer?.key;

		if (isAuth) {
			initViewerPermission();
		}
	}, [viewer]);

	if (status.isPending) {
		return (
			<Flex align="center" justify="center" className="full-width full-height">
				<Spinner isOverlay />
			</Flex>
		);
	}

	return (
		<>
			{children}
		</>
	);
};

import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useCallback, useEffect, useState } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { DotWindmill } from "@/shared/ui";

import "./TextLoader.scss";

type Props = {
	isOverlay?: boolean;
	time: string | null;
}

export const TextLoader: FC<Props> = ({ isOverlay, time }) => {
	const [counter, setCounter] = useState(Math.random());

	const randomText = useCallback(() => {
		const texts = [
			"we are contacting Leonardo to star in your film.",
			"we are inviting Samuel on set.",
			"we are calling Quentin to provide 50 gallons of fake blood.",
			"we are consulting with Christopher how to bend the laws of physics in your film.",
			"we are asking Steven to incorporate some dinosaurs of his choice.",
			"just a moment, we are currently trying to enter the void with Gaspar.",
			"our late taxi driver is delivering your script to Martin."
		];

		const random = texts[Math.floor(counter * texts.length)];

		return random;
	}, [counter]);

	useEffect(() => {
		const timeout = setInterval(() => {
			setCounter(Math.random());
		}, 5000);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<>
			<div className={cn("text-loader-overlay", {
				"text-loader-overlay__open": isOverlay
			})} />
			<Flex vertical justify="center" align="center" className="text-loader gap-xs">
				<DotWindmill />
				<Flex vertical className="text-center">
					<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>
						Storyboard generation may take a while. Estimated time: {time}
						<br/> In the meantime, {randomText()}
					</Typography.Text>
				</Flex>
			</Flex>
		</>
	);
};

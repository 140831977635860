import { useAction, useAtom } from "@reatom/npm-react";
import { useClickAway, useKeyPress, useTimeout } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createEditor, Descendant } from "slate";
import { HistoryEditor, withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";

import { editingShotAtom } from "@/features/highlight-script";
import {
	addShotToSceneAction,
	updateShotValueInSceneAction,
	scriptSignsLengthAtom
} from "@/entities/script";
import plus from "@/assets/shared/plus.svg";
import plusGray from "@/assets/shared/plus_gray.svg";
import { TShotColor } from "@/shared/api/script";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { ShotTitle } from "../shot-title";
import { LastShot } from "./LastShot";

import "./Shot.scss";

interface IShot {
	color: TShotColor;
	title: string;
	content: Descendant[];
	shotId: string;
	sceneId: string;
	shotIdx: number;
	isAddButtonDisabled: boolean;
	setIsAddButtonDisabled: Dispatch<SetStateAction<boolean>>;
	sceneName: string;
	shotsLength: number;
	projectLastShotId: string;
}

export const Shot: FC<IShot> = ({ color = "red", content, title, shotId, sceneId, shotIdx, isAddButtonDisabled, setIsAddButtonDisabled, sceneName, shotsLength, projectLastShotId }) => {
	const [editingShot, setEditingShot] = useAtom(editingShotAtom);
	const [isHover, setIsHover] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [localShotValue, setLocalShotValue] = useState<Descendant[]>(content);
	const [editor] = useState(() => withReact(withHistory(createEditor())));
	const descriptionRef = useRef<string | null>(null);
	const setDelay = useTimeout(() => {
		setIsAddButtonDisabled(false);
	}, 1000);

	const ref = useRef(null);
	const { id } = useParams();

	const [scriptLength, setScriptLength] = useAtom(scriptSignsLengthAtom);
	const scriptLengthRef = useRef(scriptLength);
	const localValueRef = useRef<Descendant[]>(localShotValue);
	const updateShot = useAction(updateShotValueInSceneAction);
	const addShot = useAction(addShotToSceneAction);

	const isLast = projectLastShotId === shotId;

	const onChange = useCallback((value: Descendant[]) => {
		const oldShotDescriptionLength = descriptionRef.current?.length ?? 0;
		const newShotDescriptionLength = editor.string([]).length;

		const diff = newShotDescriptionLength - oldShotDescriptionLength;

		setScriptLength(scriptLengthRef.current + diff);
		setLocalShotValue(value);
		setEditingShot(shotId);
		localValueRef.current = value;
	}, []);

	const onFocus = () => {
		setIsActive(true);
		scriptLengthRef.current = scriptLength;
	};

	const onBlur = () => {
		setIsActive(false);
		scriptLengthRef.current = scriptLength;
	};

	const toggleHover = () => {
		setIsHover((prev) => !prev);
	};

	const handleAddShot = () => {
		if (id && !isAddButtonDisabled) {
			setIsAddButtonDisabled(true);
			addShot(id, { sceneId, shotId });
			setDelay();
		}
	};

	const onDomBeforeInput = (e: Event) => {
		const event = e as InputEvent;
		const disabled = scriptLength >= 10000;

		if (event.inputType === "insertText" && disabled) {
			e.preventDefault();
		}

		if (event.inputType === "insertFromPaste" && disabled) {
			e.preventDefault();
		}

		if (event.inputType === "insertFromPaste") {
			const pasteText = event.dataTransfer?.getData("text") ?? "";
			const length = pasteText.length;

			if (length + scriptLength > 10000) {
				e.preventDefault();
			}
		}
	};

	useKeyPress(["ctrl.z", "meta.z"], () => {
		if (editingShot === shotId) {
			HistoryEditor.undo(editor);
		}
	}, { exactMatch: true });

	useKeyPress(["shift.ctrl.z", "meta.ctrl.z"], () => {
		if (editingShot === shotId) {
			HistoryEditor.redo(editor);
		}
	});

	useKeyPress(["esc"], () => {
		if (isActive && id) {
			const description = editor.string([]);
			setEditingShot("");
			updateShot(id, { shotId, sceneId, value: localShotValue, description });
		}
	});

	useClickAway(() => {
		const prevContent = JSON.stringify(content);
		const currentContent = JSON.stringify(localShotValue);

		if (id && prevContent !== currentContent) {
			const description = editor.string([]);

			setEditingShot("");
			updateShot(id, { shotId, sceneId, value: localShotValue, description });
			scriptLengthRef.current = scriptLength;
		}
	}, ref);

	useEffect(() => {
		descriptionRef.current = editor.string([]);
	}, []);

	const disabled = scriptLength >= 10000;

	const LIMIT_TEXT = {
		disabled: " You’ve reached the symbol limit: 10000/10000",
		active: `${scriptLength}/10000`
	};

	return (
		<>
			<Flex
				onMouseEnter={toggleHover}
				onMouseLeave={toggleHover}
				className={cn("shot", `shot__${color}`)}
			>
				<ShotTitle
					color={color}
					isHover={isHover}
					isActive={isActive}
					shotIdx={shotIdx}
					title={title}
					shotId={shotId}
					sceneId={sceneId}
					isContent={!!descriptionRef.current}
					sceneName={sceneName}
					projectLastShotId={projectLastShotId}
					shotsLength={shotsLength}
				/>
				<div
					onClick={() => setEditingShot(shotId)}
					ref={ref}
					style={{ position: "relative" }}
					className="full-width"
				>
					<Slate
						onChange={onChange}
						editor={editor}
						initialValue={localShotValue}
					>
						<Editable
							onFocus={onFocus}
							onBlur={onBlur}
							className={cn("shot__text full-width", `shot__text__${color}`)}
							onDOMBeforeInput={onDomBeforeInput}
						/>
					</Slate>
					{isActive && (
						<Typography.Text
							className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.CrazyOrange500)}
							style={{ position: "absolute", right: 15 }}
						>
							{LIMIT_TEXT[disabled ? "disabled" : "active"]}
						</Typography.Text>
					)}
				</div>
			</Flex>
			{isLast
				? (
					<LastShot shotId={shotId} sceneId={sceneId} />
				)
				: (
					<Flex
						onClick={handleAddShot}
						role="button"
						align="center"
						justify="center"
						className="shot__add-shot cubic-animation pointer"
					>
						<Flex className="pointer" align="center" justify="center">
							<img src={isAddButtonDisabled ? plusGray : plus} alt="plus" className="shot__img" />
						</Flex>
					</Flex>
				)}
		</>
	);
};

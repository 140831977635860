import axios, { AxiosError } from "axios";
import { logoutAction } from "@/entities/viewer";
import { getCredential } from "@/entities/viewer/lib/utils";
import { ctx } from "@/shared/ctx";
import { IAxiosError, IStockError } from "@/vite-env";

export const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL
});

api.interceptors.request.use((config) => {
	const accessToken = getCredential()?.data?.key;

	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}

	return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((config) => config, (error) => {
	try {
		if (error?.response?.status === 401) {
			logoutAction(ctx);
		}

		if (error.response?.status === 403 && error.response?.data?.message === "Access denied") {
			logoutAction(ctx);
		}

		if (error?.response?.status === 405 && error.response.data.message === "Incorrect Authorization header format") {
			logoutAction(ctx);
		}

		return Promise.reject(error);
	} catch (e) {
		console.error("axios interceptor error", e);
		logoutAction(ctx);
	}
});

export function axiosErrorHandler<T> (error: Error | AxiosError<T>, isStock?: boolean): IAxiosError<T> | IStockError<T> {
	if (isStock) {
		return {
			error,
			type: "stock-error"
		};
	}

	if (axios.isAxiosError(error)) {
		return {
			error,
			type: "axios-error"
		};
	} else {
		return {
			error,
			type: "stock-error"
		};
	}
}

import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC, useMemo } from "react";
import { newProjectAtom, stepAtom } from "@/features/add-new-project/model";
import { TNewProject } from "@/entities/projects";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { DisableSubmitButton } from "@/shared/ui";

type TFiled = Pick<TNewProject, "city" | "country">;

export const SecondStepForm: FC = () => {
	const [form] = Form.useForm();
	const [newProject, setNewProject] = useAtom(newProjectAtom);
	const [_step, setStep] = useAtom(stepAtom);

	const fields = useMemo(() => {
		const result = [];
		result[0] = { name: "country", value: newProject.country };
		result[1] = { name: "city", value: newProject.city };

		return result;
	}, []);

	const onFinish = (data: TFiled) => {
		setNewProject({
			...newProject,
			...data,
			city: data.city,
			country: data.country
		});
		setStep("3");
	};

	const onSkip = () => {
		setNewProject({ ...newProject, city: "", country: "" });
		setStep("3");
	};

	return (
		<Form fields={fields} onFinish={onFinish} form={form}>
			<Flex vertical className="gap-s">
				<Flex vertical>
					<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
						Enter location
					</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
						Choose the geographical region where the shoot will take place
					</Typography.Text>
				</Flex>
				<Flex vertical className="gap-xs">
					<Form.Item<TFiled> name="country" rules={[{ required: true, message: "Please input your country" }]}>
						<Input defaultValue={newProject.country ?? ""} placeholder="Italy" />
					</Form.Item>
					<Form.Item<TFiled> name="city" rules={[{ required: true, message: "Please input your country" }]}>
						<Input defaultValue={newProject.city ?? ""} placeholder="Rome" />
					</Form.Item>
				</Flex>
				<Flex className="gap-xs">
					<Button onClick={onSkip} className="full-width">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							Skip step
						</Typography.Text>
					</Button>
					<DisableSubmitButton
						type="primary"
						form={form}
						isFullWidth
					>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							Next
						</Typography.Text>
					</DisableSubmitButton>
				</Flex>
			</Flex>
		</Form>
	);
};

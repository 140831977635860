import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { DragEvent, FC } from "react";
import { useParams } from "react-router-dom";

import { AddBriefMoodboard } from "@/features/add-brief-moodboard";
import { AddImageMoodboard } from "@/features/add-image-moodboard";
import { DRAGGABLE_IMAGE_ID, isDroppableAtom } from "@/entities/inspiration-board";
import { dropImageFromSidebarsAction, moodboardLoadingAtom } from "@/entities/moodboard";
import addDescription from "@/assets/shared/add_description.svg";
import addImage from "@/assets/shared/add_image.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import emptyMoodboard from "@/assets/moodboard/empty-moodboard.svg";

import "./EmptyMoodboard.scss";

export const EmptyMoodboard: FC = () => {
	const [isOpenAddImageModal, toggleAddImageModal] = useOpenModal();
	const [isOpenAddBriefnModal, toggleAddBriefModal] = useOpenModal();
	const params = useParams();
	const dropImageFromSidebars = useAction(dropImageFromSidebarsAction);

	const [isDroppable, toggleDroppable] = useAtom(isDroppableAtom);
	const [_loading, setIsLoading] = useAtom(moodboardLoadingAtom);

	const handleDrop = async (event: DragEvent) => {
		event.stopPropagation();
		event.preventDefault();
		const id = event.dataTransfer.getData(DRAGGABLE_IMAGE_ID);
		setIsLoading(true);

		if (id && params.id) {
			await dropImageFromSidebars({ dropId: id, id: params.id });
		} else {
			const filesLength = event.dataTransfer.files.length;
			for (let i = 0; i < filesLength; i++) {
				const file = event.dataTransfer.files.item(i);
				const formData = new FormData();
				if (file) {
					formData.append("images", file);

					// const newId = await dropImage(params.id, formData, imageFromSearchBoard.coordinates);
					// addToMoodBoard({ ...imageFromInspirationBoard, id: newId ?? imageFromInspirationBoard.id });

					console.log("==========>file", file);
				}
			}
		}

		setIsLoading(false);
		toggleDroppable(false);
	};

	const enableDropping = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		toggleDroppable(true);
	};

	return (
		<Flex className="empty-moodboard" vertical align="center" justify="center">
			<Flex
				onDragOver={enableDropping}
				onDrop={handleDrop}
				vertical
				align="center"
				className={cn("gap-s", {
					"empty-moodboard__dropping": isDroppable
				})}>
				<img draggable={false} className="empty-moodboard__image" src={emptyMoodboard} alt="empty moodboard" />
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>Your Moodboard is empty</Typography.Text>
				<Flex className="empty-moodboard__content" vertical justify="center" align="center">
					<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>To fill it, drag pictures from the inspiration board</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>or</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Describe your film</Typography.Text>
				</Flex>
				<Flex justify="center" align="center" className="gap-xs">
					<Button
						className="empty-moodboard__button"
						type="primary"
						icon={<img src={addImage} alt="add image"/>}
						onClick={() => toggleAddImageModal()}
					>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add Image</Typography.Text>
					</Button>
					<Button
						className="empty-moodboard__button"
						type="primary"
						icon={<img src={addDescription} alt="Add Brief"/>}
						onClick={() => toggleAddBriefModal()}
					>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add Brief</Typography.Text>
					</Button>
				</Flex>
			</Flex>
			<AddBriefMoodboard isOpen={isOpenAddBriefnModal} toggleModal={toggleAddBriefModal} />
			<AddImageMoodboard isOpen={isOpenAddImageModal} toggleModal={toggleAddImageModal} />
		</Flex>
	);
};

import { Typography } from "antd";
import cn from "classnames";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { initResetPasswordResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

export const CountDownResendCode = () => {
	const { email } = useParams();
	const [timeLeft, setTimeLeft] = useState(60);

	useEffect(() => {
		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	const handleResendCode = async () => {
		if (email) {
			await initResetPasswordResource(email);
			setTimeLeft(60);
		}
	};

	if (timeLeft === 0) {
		return (
			<Typography.Text onClick={handleResendCode} className={cn(TEXT_CLASSNAMES.XsSemibold, "pointer")}>
				Resend code
			</Typography.Text>
		);
	}

	return (
		<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
			If you didn’t receive the code, click Resend code in {timeLeft}s.
		</Typography.Text>
	);
};

import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";

import arrow from "@/assets/shared/arrow_black_right.svg";

interface IDropdownContent extends HTMLAttributes<HTMLDivElement>{
	link: string;
	onClose: () => void;
	img: string;
	description: string;
}

export const DropdownContent: FC<IDropdownContent> = ({ link, onClose, description, ...props }) => {
	const navigate = useNavigate();
	const onRedirect = () => {
		navigate(link);
		onClose();
	};

	return (
		<Flex
			{...props}
			role="button"
			onClick={onRedirect}
			align="center"
			justify="space-between"
			className={cn("header-dropdown__block gap-s pointer", props.className)}
		>
			<Flex align="center" className="gap-xxs">
				<div style={{ width: 8, height: 8, border: "1px solid black", borderRadius: "50%" }} />
				<Typography.Text>{description}</Typography.Text>
			</Flex>
			<div role="button">
				<img src={arrow} alt="arrow"/>
			</div>
		</Flex>
	);
};

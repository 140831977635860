import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import check from "@/assets/shared/check.svg";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./Chip.scss";

interface IChip {
	title: string;
	isSuccess: boolean;
}

export const Chip: FC<IChip> = ({ title, isSuccess }) => (
	<Flex align="center" justify="center" className={cn("gap-xxs chip", {
		chip__success: isSuccess
	})}>
		{isSuccess && <img className="chip__img" src={check} alt="check" />}
		<Typography.Text className={cn({
			[COLOR_CLASSNAMES.MetalGray700]: !isSuccess,
			[COLOR_CLASSNAMES.Green700]: isSuccess,
			[TEXT_CLASSNAMES.XsRegular]: !isSuccess,
			[TEXT_CLASSNAMES.XsSemibold]: isSuccess
		})}>{title}</Typography.Text>
	</Flex>
);

import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { ComingSoon } from "@/shared/ui";
import { isShotListEmptyAtom } from "../model";

import emptyVersions from "@/assets/shot-selection/empty-veriosns.svg";

export const ShotVersions: FC = () => {
	const [isShotListEmpty] = useAtom(isShotListEmptyAtom);

	if (NOT_RELEASE) {
		if (isShotListEmpty) {
			return (
				<Flex vertical align="center" justify="flex-start" className="gap-s">
					<img src={emptyVersions} alt="empty versions" />
					<Typography.Text className={cn("text-center", TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
						Here we will store your versions of the images.
					</Typography.Text>
				</Flex>
			);
		}

		return (
			<></>
		);
	}

	return <ComingSoon />;
};

import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { Gallery } from "react-grid-gallery";
import { DRAGGABLE_IMAGE_ID } from "@/entities/inspiration-board";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { DraggableImage, DraggableOverlay, Spinner } from "@/shared/ui";
import {
	searchBoardListAtom,
	searchBoardLoadingAtom,
	searchBoardStatusAtom,
	searchBoardViewAtom
} from "../model";
import { SearchBoardAutocomplete } from "./SearchBoardAutocomplete";
import { SearchBoardImageDownload } from "./SearchBoardImageDownload";

import searchBoardEmptyImage from "@/assets/moodboard/empty-search-moodboard.svg";

import "./SearchBoard.scss";

export const SearchBoard: FC = () => {
	const [searchBoardView] = useAtom(searchBoardViewAtom);
	const [searchBoardList] = useAtom(searchBoardListAtom);
	const [searchBoardLoading] = useAtom(searchBoardLoadingAtom);
	const [searchBoardStatus] = useAtom(searchBoardStatusAtom);

	const images = searchBoardList.map((image) => ({
		src: image.url,
		width: image.coordinates.w,
		height: image.coordinates.h,
		alt: image.id,
		customOverlay: <DraggableOverlay />
	}));

	return (
		<Flex vertical className="search-board full-width gap-s">
			{searchBoardView === "autocomplete" && <SearchBoardAutocomplete />}
			{searchBoardView === "image" && <SearchBoardImageDownload />}
			{searchBoardLoading
				? (
					<Flex className="full-width full-height" align="center" justify="center">
						<Spinner />
					</Flex>
				)
				: (
					<>
						{searchBoardStatus === "empty" && (
							<Flex vertical align="center" className="gap-xs">
								<img src={searchBoardEmptyImage} alt="search board empty" />
								<Flex vertical align="center">
									<Typography.Text className={cn(TEXT_CLASSNAMES.SmSemibold, "archive-board__text")}>
										Looking for something?
									</Typography.Text>
									<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, "archive-board__text")}>
										Type in and we’ll help you find it
									</Typography.Text>
								</Flex>
							</Flex>
						)}
						{searchBoardStatus === "full" && (
							<Gallery
								margin={3}
								rowHeight={200}
								images={images}
								// @ts-ignore
								thumbnailImageComponent={(props) => <DraggableImage draggableImageId={DRAGGABLE_IMAGE_ID} {...props} />}
								enableImageSelection={false}
							/>
						)}
					</>
				)}
		</Flex>
	);
};

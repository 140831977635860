import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, ReactNode } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { LandingVerticalSwiper, VerticalSwiperItem } from "@/features/landing-vertical-swiper";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import "./UseCaseTemplate.scss";

type Props = {
	isComingSoon: boolean;
	items: VerticalSwiperItem[];
	description: string;
	italicHeaderText: string;
	headerText: string | ReactNode;
	lineImg: string;
	previewImg: string;
	lineClassName: string;
}

export const UseCaseTemplate: FC<Props> = ({ isComingSoon, items, description, italicHeaderText, headerText, lineImg, previewImg, lineClassName }) => {
	const { isTablet, isLaptopS, isMobileXL } = useMediaQueries();
	const navigate = useNavigate();
	const params = new URLSearchParams(window.location.search);
	const from = params.get("user_redirect_from");

	const login = () => {
		if (from) {
			navigate({
				pathname: "/login",
				search: createSearchParams({
					user_redirect_from: from ?? ""
				}).toString()
			});
		} else {
			navigate("/login");
		}
	};

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex vertical className="use-case-template">
			<Flex vertical className="use-case-template__title gap-xs text-center">
				<img
					src={lineImg}
					alt="line"
					className={cn("use-case-template__line", lineClassName)}
				/>
				{isComingSoon && (
					<Flex align="center" justify="center" style={{ width: 160, margin: "0 auto", textTransform: "none" }} className="use-case-template__coming-soon">
						<Typography.Text className="use-case-template__coming-soon-title">
							Coming soon
						</Typography.Text>
					</Flex>
				)}
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet,
					[TEXT_CLASSNAMES.HeadlineH3]: isMobileXL
				})}>
					{headerText} <span className="use-case-template__title--italic">{italicHeaderText}</span>
				</Typography.Text>
				<Typography.Text style={{ textTransform: "none" }} className={cn(TEXT_CLASSNAMES.LgRegular, {
					[TEXT_CLASSNAMES.MdRegular]: isLaptopS,
					[TEXT_CLASSNAMES.SmRegular]: isTablet,
					[TEXT_CLASSNAMES.XsRegular]: isMobileXL
				})}>
					{description}
				</Typography.Text>
				<Button
					type="primary"
					style={{
						width: isTablet ? 148 : 180,
						height: isTablet ? 40 : 54,
						borderRadius: 64,
						margin: "0 auto",
						marginTop: 16
					}}
					onClick={login}
				>
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
						[TEXT_CLASSNAMES.XsRegular]: isMobileXL
					})}>Try now</Typography.Text>
				</Button>
			</Flex>
			<img className="use-case-template__preview image-contain" src={previewImg} alt="script preview" />
			<div className="use-case-template__swiper">
				<LandingVerticalSwiper items={items} />
			</div>
		</Flex>
	);
};

import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import "./PlanType.scss";

export const ComingSoonPlanType: FC = () => {
	const { isMobileL } = useMediaQueries();

	return (
		<Flex gap={8} vertical className={cn("plan-type cubic-animation", {
			"plan-type--disabled": true
		})}>
			<Flex align={isMobileL ? "center" : "initial"}>
				<span className={cn("plan-type__radio cubic-animation")} />
				<Flex vertical className="plan-type__text">
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdSemibold, {
						[TEXT_CLASSNAMES.XsSemibold]: isMobileL
					})}>
						Business
					</Typography.Text>
					{!isMobileL && (
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
							20 users, Unlimited projects, Watermarks (option),Custom characters, Collaboration features, Movie pitch, Premium support
						</Typography.Text>
					)}
				</Flex>
				{isMobileL && <div style={{ flex: 1 }} />}
				<Flex vertical>
					<Flex className="gap-xxs" align="center">
						<Typography.Text style={{
							minWidth: "max-content",
							textDecoration: "none",
							cursor: "pointer",
							zIndex: 0
						}} className={cn(TEXT_CLASSNAMES.MdSemibold, {
							[TEXT_CLASSNAMES.SmSemibold]: isMobileL
						})}>
							Coming soon
						</Typography.Text>
					</Flex>
				</Flex>
			</Flex>
			{isMobileL && (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
					20 users, Unlimited projects, Watermarks (option),Custom characters, Collaboration features, Movie pitch, Premium support
				</Typography.Text>
			)}
		</Flex>
	);
};

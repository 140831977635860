import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { promoCodeAtom } from "@/entities/plan";
import { Currency, Interval } from "@/shared/api/payment";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { CURRENCY, INTERVAL, PlanParams } from "../lib";

import "./PlanType.scss";

type Props = {
	activePlanParams: PlanParams | null;
	name: string;
	termType: string;
	title: string;
	description: string;
	onChange: (params: PlanParams, value: string) => void;
	amount: number;
	interval: Interval;
	currency: Currency;
}

export const PlanType: FC<Props> = ({
	title,
	amount,
	activePlanParams,
	onChange,
	name,
	termType,
	interval,
	description,
	currency
}) => {
	const [promoCode] = useAtom(promoCodeAtom);
	const isDiscount = !!promoCode;
	const { isMobileL } = useMediaQueries();

	const priceText = `${CURRENCY[currency]}/${amount.toFixed(2)} ${INTERVAL[interval]}`;
	const discountPrice = isDiscount
		? (amount - (amount * (promoCode?.percent_off as number / 100))).toFixed(2)
		: "";

	const discountText = isDiscount
		? `${CURRENCY[currency]}/${discountPrice} ${INTERVAL[interval]}`
		: "";

	const changeValue = { interval, termType };

	const isActive = activePlanParams?.termType === termType && activePlanParams?.interval === interval;

	return (
		<Flex gap={8} vertical className={cn("plan-type cubic-animation", {
			"plan-type--active": isActive
		})}>
			<Flex align={isMobileL ? "center" : "initial"}>
				{isDiscount && (
					<Flex justify="center" align="center" className="plan-type__discount">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>-{promoCode?.percent_off}% discount promocode</Typography.Text>
					</Flex>
				)}
				<input
					id={`${name}-${termType}`}
					className="plan-type__input"
					type="radio"
					name={name}
					onChange={() => onChange(changeValue, termType)}
				/>
				<span className={cn("plan-type__radio cubic-animation", {
					"plan-type__radio--active": isActive
				})} />
				<Flex vertical className="plan-type__text">
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdSemibold, {
						[TEXT_CLASSNAMES.XsSemibold]: isMobileL
					})}>
						{title}
					</Typography.Text>
					{!isMobileL && (
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
							{description}
						</Typography.Text>
					)}
				</Flex>
				{isMobileL && <div style={{ flex: 1 }} />}
				<Flex vertical>
					<Flex className="gap-xxs" align="center">
						{isDiscount && (
							<Typography.Text
								className={TEXT_CLASSNAMES.MdSemibold}
								style={{
									minWidth: "max-content"
								}}
							>{discountText}</Typography.Text>
						)}
						<Typography.Text style={{
							minWidth: "max-content",
							textDecoration: isDiscount ? "line-through" : "none",
							cursor: "pointer",
							zIndex: 0
						}} className={cn(TEXT_CLASSNAMES.MdSemibold, {
							[COLOR_CLASSNAMES.TextSecondary]: isDiscount,
							[TEXT_CLASSNAMES.XsRegular]: isDiscount,
							[TEXT_CLASSNAMES.SmSemibold]: isMobileL
						})}>
							{priceText}
						</Typography.Text>
					</Flex>
				</Flex>
			</Flex>
			{isMobileL && (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
					{description}
				</Typography.Text>
			)}
		</Flex>
	);
};

import { AxiosResponse } from "axios";

import { api } from "@/shared/api";
import {
	TEmbeddingResponse,
	TMoodboardResult,
	UpdateMoodboardImage
} from "./types";

export const deleteMoodboardImages = (id: string, data: string[]): Promise<AxiosResponse<{ count: number }>> =>
	api({
		method: "DELETE",
		url: `/moodboards/images/${id}`,
		data
	});

export const addMoodboardImages = (id: string, formData: FormData): Promise<AxiosResponse<{ [id: string]: string }>> =>
	api.post(`/moodboards/images/${id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});

export const getMoodboardsResource = (id: string): Promise<AxiosResponse<TMoodboardResult>> =>
	api.get(`/moodboards/images/${id}/ids`);

export const addMoodboardsImagesCoordinates = (id: string, coordinates: UpdateMoodboardImage[]) =>
	api.patch(`/moodboards/images/${id}`, { images: coordinates });

export const getInspirationResource = (queries: string[], limit: number): Promise<AxiosResponse<TEmbeddingResponse>> =>
	api.get("/moodboards/embeddings", {
		params: {
			embeddings: queries,
			peek_limit: limit
		},
		paramsSerializer: {
			indexes: null
		}
	});

export const updateMoodboardTagsResource = (id: string, tags: string[]): Promise<AxiosResponse<unknown>> =>
	api.patch(`/moodboards/tags/${id}`, { tags });

export const getMoodboardTagsResource = (id: string): Promise<AxiosResponse<{ tags: (string[]) | null }>> =>
	api.get(`/moodboards/tags/${id}`);

export const getInspirationPromptsResource = (id: string): Promise<AxiosResponse<{ prompt: string }>> =>
	api.get(`/moodboards/inspiration_prompt/${id}`);

export const updateInspirationPromptsResource = (id: string, prompt: string): Promise<AxiosResponse<{ prompt: string }>> =>
	api.patch(`/moodboards/inspiration_prompt/${id}`, { inspiration_prompt: prompt });

import { Button, Checkbox, Flex, Form, Input, Modal, Select, Typography } from "antd";
import axios from "axios";
import cn from "classnames";
import { FC, useState } from "react";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { ROLES } from "@/shared/const/roles.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { IOpenModal, useOpenModal } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { trackGoogleAnalyticsEvent } from "@/shared/methods";

import accessDemo from "@/assets/landing/access_demo.svg";

interface IFeedbackForm {
	email: string;
	company: string;
	name: string;
	role: string;
}

export const RequestAccessModal: FC<IOpenModal> = ({ isOpen, toggleModal }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isEmailSendError, setIsEmailSendError] = useState(false);
	const [isSend, toggleSendModal] = useOpenModal();

	const { isLaptopS, isTablet, isMobileXL } = useMediaQueries();
	const [form] = Form.useForm();
	const role = Form.useWatch("role", { form, preserve: true });

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid!"
		}
	};
	/* eslint-enable no-template-curly-in-string */

	const onFinish = async (values: IFeedbackForm) => {
		try {
			setIsLoading(true);

			trackGoogleAnalyticsEvent("white_list", "contacts", location.pathname, {
				...values,
				email: values.email.replace("@", "_")
			});

			const data = {
				service_id: "service_fftdtow",
				template_id: "template_lae8dge",
				user_id: "AQK3iUSsogwlK2dMZ",
				template_params: {
					email: values.email,
					message: `My name is ${values.name}. I am from "${values.company}". Request access to Blooper.`
				}
			};

			if (values.email !== "grosh@blooper.ai") {
				await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
					...data
				}, {
					headers: {
						"Content-Type": "application/json"
					}
				});
			}

			toggleSendModal(true);
			toggleModal(false);
			setIsEmailSendError(false);
		} catch (e) {
			setIsEmailSendError(true);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Modal
				open={isOpen}
				onCancel={() => toggleModal(false)}
				footer={[]}
			>
				<Form
					onFinish={onFinish}
					validateMessages={validateMessages}
					layout="vertical"
					className="full-width join-waiting-list__form"
					style={{ padding: 0 }}
				>
					<Flex vertical className="gap-s text-center">
						<Flex vertical justify="center" className="gap-xxs">
							<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4, {
								[TEXT_CLASSNAMES.LPHeadlineH5]: isTablet,
								[TEXT_CLASSNAMES.HeadlineH1]: isMobileXL
							})}>Request access to Blooper </Typography.Text>
							<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextSecondary, {
								[TEXT_CLASSNAMES.SmRegular]: isLaptopS,
								[TEXT_CLASSNAMES.XsRegular]: isTablet
							})}>
								Complete the form to apply for early access.
								We’ll review your request and get in touch soon.
							</Typography.Text>
						</Flex>
						<Flex vertical gap="middle">
							<Form.Item id="name" name="name" noStyle rules={[{ required: true }]}>
								<Input placeholder="Name" />
							</Form.Item>
							<Form.Item id="email" name="email" noStyle rules={[{ type: "email", required: true }]}>
								<Input placeholder="Email" />
							</Form.Item>
							<Form.Item id="company" name="company" noStyle rules={[{ required: true }]}>
								<Input placeholder="Company" />
							</Form.Item>
							<Form.Item
								name="role"
								rules={[
									{ required: true, message: "Please choose your role!" }
								]}
								hasFeedback
								validateStatus={role ? "success" : ""}
							>
								<Select
									className="text-left"
									placeholder="Choose the role"
									style={{ height: 44 }}
									onChange={(value) => form.setFieldValue("role", value)}
									options={ROLES}
								/>
							</Form.Item>
							<Form.Item
								name="remember"
								valuePropName="checked"
							>
								<Flex className="text-left">
									<Checkbox>
										<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
											I want to get a news about Blooper by email
										</Typography.Text>
									</Checkbox>
								</Flex>
							</Form.Item>
						</Flex>
						{isEmailSendError && <Typography.Text className="error">Oops something went wrong</Typography.Text>}
						<Form.Item>
							<Button loading={isLoading} className="full-width" htmlType="submit" type="primary">
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
									Send
								</Typography.Text>
							</Button>
						</Form.Item>
					</Flex>
				</Form>
			</Modal>
			<Modal
				open={isSend}
				onCancel={() => toggleSendModal(false)}
				footer={[]}
			>
				<Flex align="center" vertical gap={24}>
					<img src={accessDemo} alt="access demo" />
					<Flex vertical justify="center" className="gap-xxs text-center">
						<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH4, {
							[TEXT_CLASSNAMES.LPHeadlineH5]: isTablet,
							[TEXT_CLASSNAMES.HeadlineH1]: isMobileXL
						})}>Access request received!</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextSecondary, {
							[TEXT_CLASSNAMES.SmRegular]: isLaptopS,
							[TEXT_CLASSNAMES.XsRegular]: isTablet
						})}>
							We’re reviewing your application, and you’ll hear from us soon. Keep an eye on your inbox for updates on your early access.
						</Typography.Text>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};

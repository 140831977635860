import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";

import { TMoodBoardStatus } from "@/entities/moodboard";
import { callErrorAction } from "@/entities/notification";
import { getInspirationResource, TMoodboardImage } from "@/shared/api/moodboard";
import { getImgSize } from "@/shared/methods";

export const searchBoardStatusAtom = atom<TMoodBoardStatus>((ctx) => {
	const list = ctx.spy(searchBoardListAtom);

	return list.length ? "full" : "empty";
}, "searchBoardStatusAtom");

export const searchBoardViewAtom = atom<"image" | "autocomplete">("autocomplete", "searchBoardViewAtom");

export const searchBoardListAtom = atom<TMoodboardImage[]>([], "searchBoardListAtom");
export const searchBoardLoadingAtom = atom<boolean>(false, "searchBoardLoadingAtom");
export const getEmbeddingsAction = action(async (ctx, query: string) => {
	try {
		searchBoardLoadingAtom(ctx, true);
		const { data } = await getInspirationResource([query], 10);

		const imagesPromises: Promise<TMoodboardImage>[] = data.images.map(async (image) => {
			const { width, height } = await getImgSize(image.compressed_image_url);

			const updatedImage: TMoodboardImage = {
				coordinates: { x: 0, y: 0, w: width, h: height, minW: width, minH: height },
				id: uuidv4(),
				archived: false,
				url: image.compressed_image_url
			};

			return updatedImage;
		});

		const images = await Promise.all(imagesPromises);

		searchBoardListAtom(ctx, images);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError, true);
	} finally {
		searchBoardLoadingAtom(ctx, false);
	}
}, "getEmbeddingsAction");

export const removeFromSearchBoardAction = action((ctx, id: string) => {
	searchBoardListAtom(ctx, ctx.get(searchBoardListAtom).filter((item) => item.id !== id));
});

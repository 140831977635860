import { Button, Flex, Modal, Typography } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { IOpenModal } from "@/shared/hooks";

type Props = {
	onSave: () => void;
} & IOpenModal;

export const UnsavedChangesModal: FC<Props> = ({ isOpen, toggleModal, onSave }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const handleCancel = () => {
		toggleModal(false);
	};

	const onLeave = () => {
		handleCancel();
		navigate(`/storyboard/${id}/character`);
	};

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			footer={[
				<Flex style={{
					marginTop: 24
				}} className="full-width gap-xs">
					<Button className="flex-1" key="back" onClick={onLeave}>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							Leave without saving
						</Typography.Text>
					</Button>
					<Button type="primary" className="flex-1" key="back" onClick={onSave}>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
							Save and leave
						</Typography.Text>
					</Button>
				</Flex>
			]}
		>
			<Flex vertical className="gap-s text-center">
				<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>
					Unsaved Changes
				</Typography.Text>
				<Flex vertical className="gap-xxs">
					<Typography.Text className={TEXT_CLASSNAMES.MdRegular}>
						You have unsaved changes. Do you want to save changes?
					</Typography.Text>
				</Flex>
			</Flex>
		</Modal>
	);
};

import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";
import { getTagsAction } from "@/features/add-brief-moodboard";
import { callErrorAction } from "@/entities/notification";
import {
	getInspirationPromptsResource,
	getInspirationResource,
	TMoodboardImage,
	updateInspirationPromptsResource
} from "@/shared/api/moodboard";
import { EMBENDINGS } from "@/shared/const/moodboard";
import { createUrlParam, getImgSize, getUrlParams } from "@/shared/methods";

export const isDroppableAtom = atom(false, "isDroppable");

export const inspirationBoardListAtom = atom<TMoodboardImage[]>([], "inspirationBoardList");
export const limitAtom = atom(20, "limit");

export const inspirationPromptAtom = atom<string>("", "inspirationPrompt");

inspirationPromptAtom.onChange((ctx, newPrompt) => {
	const brief = getUrlParams(newPrompt, EMBENDINGS);

	if (brief.length) {
		getInspirationAction(ctx, brief, 20);
	} else {
		inspirationBoardListAtom(ctx, []);
	}
});

limitAtom.onChange((ctx, newLimit) => {
	const brief = getUrlParams(ctx.get(inspirationPromptAtom), EMBENDINGS);

	if (brief.length) {
		getInspirationAction(ctx, brief, newLimit);
	}
});

export const removeFromInspirationBoardAction = action((ctx, id: string) => {
	inspirationBoardListAtom(ctx, ctx.get(inspirationBoardListAtom).filter((item) => item.id !== id));
});
export const inspirationBoardLoadingAtom = atom<boolean>(false, "inspirationBoardLoadingAtom");
export const inspirationPromptLoadingAtom = atom<boolean>(false, "inspirationPromptLoadingAtom");
export const getInspirationAction = action(async (ctx, query: string[], limit: number) => {
	try {
		inspirationBoardLoadingAtom(ctx, true);
		const { data } = await getInspirationResource(query, limit);

		const imagesPromises: Promise<TMoodboardImage>[] = data.images.map(async (image) => {
			const { width, height } = await getImgSize(image.compressed_image_url);

			const updatedImage: TMoodboardImage = {
				coordinates: { x: 0, y: 0, w: width, h: height, minW: width, minH: height },
				id: uuidv4(),
				archived: false,
				url: image.compressed_image_url
			};

			return updatedImage;
		});

		const images = await Promise.all(imagesPromises);

		inspirationBoardListAtom(ctx, images);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError, true);
	} finally {
		inspirationBoardLoadingAtom(ctx, false);
	}
}, "getEmbeddingsAction");

export const updateInspirationPromptAction = action(async (ctx, projectId: string, prompt: string) => {
	try {
		await updateInspirationPromptsResource(projectId, prompt);
		inspirationPromptAtom(ctx, prompt);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
}, "updateEmbeddingsAction");

export const getInspirationPromptAction = action(async (ctx, projectId: string) => {
	try {
		inspirationPromptLoadingAtom(ctx, true);
		const { data: inspiration } = await getInspirationPromptsResource(projectId);
		const { data: tags } = await getTagsAction(ctx, projectId);

		if (inspiration.prompt) {
			inspirationPromptAtom(ctx, inspiration.prompt);
		} else {
			if (tags.tags?.length) {
				const prompt = createUrlParam(tags.tags, EMBENDINGS);

				inspirationPromptAtom(ctx, prompt);
			} else {
				inspirationPromptAtom(ctx, "");
			}
		}

		inspirationPromptLoadingAtom(ctx, false);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

import { useAtom } from "@reatom/npm-react";
import cn from "classnames";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";

import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";

import { figureAtom, isBubbleAtom } from "@/entities/use-cases";
import { LandingLayoutContext } from "./LandingLayoutContext";

import "./LandingLayout.scss";

export const LandingLayout: FC = () => {
	const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
	const [bubble] = useAtom(isBubbleAtom);
	const [figure] = useAtom(figureAtom);

	const toggleOpen = () => {
		setIsBurgerMenuOpen((prev) => !prev);
	};

	return (
		<LandingLayoutContext.Provider value={{ isOpen: isBurgerMenuOpen, toggleOpen }}>
			<div
				id="layout"
				style={{
					position: "relative",
					overflowY: isBurgerMenuOpen ? "hidden" : "auto",
					overflowX: "hidden"
				}}
			>
				{bubble && (
					<>
						<div className="layout-gradient layout-gradient--1">
							<div className="layout-gradient__radial layout-gradient--1--one"></div>
							<div className="layout-gradient__radial layout-gradient--1--two"></div>
							<div className="layout-gradient__radial layout-gradient--1--three"></div>
						</div>
						<div className="layout-gradient layout-gradient--2">
							<div className="layout-gradient__radial layout-gradient--2--three"></div>
							<div className="layout-gradient__radial layout-gradient--2--two"></div>
							<div className="layout-gradient__radial layout-gradient--2--one"></div>
						</div>
					</>
				)}
				{!!figure && (
					<img
						src={figure.url}
						alt="figure"
						className={cn("layout-image", `layout-image--${figure.position}`)}
					/>
				)}
				<div className="bg">
					<Header/>
					<main>
						<Outlet/>
					</main>
					<Footer/>
				</div>
				{/* <div className="layout-gradient layout-gradient--3"> */}
				{/*	<div className="layout-gradient__radial layout-gradient--3--three"></div> */}
				{/*	<div className="layout-gradient__radial layout-gradient--3--two"></div> */}
				{/*	<div className="layout-gradient__radial layout-gradient--3--one"></div> */}
				{/* </div> */}

				{/* <img src={radial3} alt="radial" className="layout-radial layout-radial--3" /> */}
			</div>
		</LandingLayoutContext.Provider>
	);
};

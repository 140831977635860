import { Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";

export const SuccessEmail: FC = () => {
	const { email } = useParams();

	return (
		<BackgroundPreLogin>
			<LoginWrapper
				action={() => {}}
				title="Verification"
				questionText=""
				actionText=""
				style={{
					textAlign: "center"
				}}
			>
				<Typography.Text>We sent an verification link to {email}</Typography.Text>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

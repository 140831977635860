import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./CharacterActions.scss";

interface ISelect {
	type: "select";
	onSelect: () => void;
}

interface INext {
	type: "next";
	onNext: () => void;
}

interface IBack {
	type: "back";
	onNext: () => void;
}

type TCharacterActions = INext | ISelect | IBack;

export const CharacterActions: FC<TCharacterActions> = (props) => {
	const navigate = useNavigate();
	const params = useParams();
	const onCancel = () => {
		navigate(`/storyboard/${params.id}/character`);
	};

	return (
		<Flex className="character-actions full-width gap-xs" justify="flex-end" align="center">
			{props.type === "select" && (
				<Button onClick={onCancel}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
				</Button>
			)}
			<Button onClick={(props.type === "next" || props.type === "back") ? props.onNext : props.onSelect} type="primary" className="character-actions__button">
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					{props.type === "next" && "Next"}
					{props.type === "select" && "Select"}
					{props.type === "back" && "Back"}
				</Typography.Text>
			</Button>
		</Flex>
	);
};
